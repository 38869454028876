import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Aajsooraj() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/aaj-sooraj-jald-ghuroob-ho-gaya">
                آج سورج جلد غروب ہو گیا
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>نعیم احمد بلوچ</p>

                <p>
                  جب موسم بدل رہا ہو تو یوں لگتا ہے کہ دن کی روشنی جلد ختم ہو
                  گئی ہے۔ آج بھی یہی احساس ہو رہا تھا کہ ابھی کام توبہت سارے
                  باقی ہیں، مگرلگتا ہے کہ سورج غروب ہونے کو ہے۔ اسی احساس میں
                  تھا کہ موبائل فون کی گھنٹی نے مجھے گاڑی سڑک کے ایک طرف لگانے
                  پر مجبور کر دیا ۔ اور اسی لمحے مجھے ڈاکٹر صاحب کی شہادت کی خبر
                  ملی۔
                </p>

                <p>
                  آج واقعی سورج بہت جلد غروب ہو گیا تھا!
                  <br />
                  یہ خبر اگر چہ غیر متوقع نہیں تھی، لیکن اتنی اندوہناک تھی کہ دل
                  مان ہی نہیں رہا تھا ، انیس مفتی صاحب کو فون کیا ، وہی لاہور
                  میں ان کے میزبان ہوتے تھے ، انھوں نے تصدیق کی اور تفصیل بھی
                  بتائی۔
                  <br />
                  اسی لمحے میری آنکھوں کے سامنے ڈاکٹر صاحب سے آخری ملاقات کا
                  منظر گھوم گیا۔ یہ ایک خصوصی ملاقات تھی،جو انیس مفتی صاحب کے
                  گھر پر ہوئی تھی۔ پہلے تو انھوں نے پیار بھرگلا کیا کہ میں نے جس
                  موضوع پر تم سے بات کرنی ہے ،اس پر ’’المورد ‘‘میں ایک خصوصی
                  لیکچر کا اہتمام کیا گیا تھا اور تمھیں بھی بلایا تھا ،مگر تمھیں
                  نہ پاکر حیرت ہوئی تھی، کیونکہ موضوع بچوں کے لیے اسلامیات کا
                  نصاب تھا اور اس میں تمھاری دلچسپی کا مجھے اچھی طرح علم تھا۔
                  <br />
                  یہ جنوری یا فروری کی بات تھی جب میں دنیا ٹی وی چینل میں ملازم
                  تھا۔میں نے معذرت کی کہ مجھے بلال صاحب کے ذریعے سے یہ بات پہنچی
                  تھی کہ آپ قرآن مجید کے ترجمے کے حوالے سے گفتگو کریں گے اور میں
                  شرکت کرنا چاہتا تھا، لیکن انھوں نے مجھے لکچر میں جانے کی اجازت
                  نہ دی۔ تب ڈاکٹر صاحب نے مجھے تفصیل سے بتایا کہ وہ خیبر پختون
                  خوا ہ کی حکومت کے ساتھ مل کر بچوں کی دینی تعلیم کے ایک منصوبے
                  پر کام کر رہے ہیں ۔ میرے پوچھنے پر انھوں بتایا کہ یہ کام بھی
                  شدت پسندوں کو ناپسند ہو گا ،لیکن اسے کرنے کے سوا کوئی چارہ
                  نہیں۔ پھر انھوں نے مجھے اپنی مرتب کردہ کتب دیں۔ یہ دس کتب تھیں
                  جو قرآنی آیات ، احادیث اور بنیادی عقائد و ارکان اسلام پر مشتمل
                  تھیں۔ انھوں فرمایاکہ میں چاہتا ہوں کہ ان نصابی کتب کے ساتھ
                  اسلامی تاریخ کے حوالے سے کہانیوں اوربنیادی عقائدوفرائض کی
                  تمثیلی انداز میں وضاحت پر مبنی مضامین کی کتابیں بھی ہوں تاکہ
                  بچے دلچسپی سے اس نصاب کو پڑھیں۔ انھوں نے ہمیشہ کی طرح بڑی قدر
                  افزائی کرتے ہوئے کہا کہ یہ چونکہ تمھارا خاص فیلڈ ہے ،اس لیے
                  اضافی کتب لکھنے کا یہ کام تم ہی کرو گے اور یہ ہم دونوں کا
                  مشترکہ پراجیکٹ ہو گا۔
                  <br />
                  اس ملاقات کے بعد ان سے ٹیلی فون کے ذریعے سے مسلسل رابطہ رہا ۔
                  انھیں جو کتابیں مرتب کرکے دیں ،ان پر انھوں نے بہت زیادہ
                  پسندیدگی اور اطمینان کا اظہار کیا۔ اس ٹیلی فونک رابطے پر گفتگو
                  کے دوران میں ان سے ایک سے زائد مرتبہ اپنے بارے میں محتاط رہنے
                  کی گزارش کی اور انھوں نے ہر دفعہ اس عزم کا اظہار کیا کہ احتیا
                  ط کا دامن تھامے ہوئے ہیں ،لیکن وہ اپنے مورچے کو چھوڑنا کبھی
                  پسند نہیں کریں گے۔ اس کے ساتھ ساتھ وہ یہ بھی کہتے کہ وہ اپنے
                  ان کلمہ گو بھائیوں کی اصلاح بھی چاہتے ہیں ۔ وہ اس اطمینان کا
                  اظہار بھی کرتے کہ ان لوگوں کے تمام سرکردہ لوگ مجھے ذاتی طور پر
                  جانتے ہیں اور انھیں یہ احساس ضرور ہو گا کہ میں صرف دلیل کی بات
                  کرنا جانتا ہوں۔ وہ میرے بارے میں حسن ظن رکھتے ہیں۔ لیکن وقت نے
                  ثابت کیا یہ حسن ظن صرف انھی کی جانب سے تھا۔
                  <br />
                  وہ جب بھی یہ بات کہتے تو میں سوچتا کہ یہی بات تو ان کے لیے
                  خطرناک ہے ۔ وہ دلیل ہی کے تو دشمن ہیں ۔ ان کے نزدیک تو صرف
                  بندوق ہی حرف آخر ہے۔ ان کا شمار دنیا کے ان مخالفین میں ہو تا
                  ہے جن کی کوئی اخلاقیات نہیں۔ وہ اپنے ہر مخالف کو ناقابل فہم
                  اور غیر متوقع طریقے سے ختم کرنے کی انتہائی صلاحیت رکھتے ہیں ۔
                  لیکن اس ساری تلخ حقیقتوں کو جاننے اور ماننے کے باوجود ان کا
                  ایک ہی موقف تھا کہ وہ اپنا مورچہ نہیں چھوڑیں گے۔
                  <br />
                  وہ واقعی خان تھے!!
                  <br />
                  ڈاکٹر صاحب انتہائی فعال انسان تھے ۔ وہ ایک وقت میں کئی محاذوں
                  پر لڑنے والے مجاہد تھے۔ بے رحم قاتلوں نے ان کو صرف اسی لیے
                  خاموش کیا کہ وہ عملی طور پر ان سے شکست کھا چکے تھے۔ ان کو شہید
                  کرکے ان نا عاقبت اندیشوں نے گویایہ مان لیا کہ نظریاتی جنگ میں
                  اصل اور فیصلہ کن ہتھیار کی حیثیت بندوق کونہیں قلم اور زبان کو
                  حاصل ہے۔ سوال یہ ہے کہ کیا دور جدید کے یہ خارجین ان کے لکھے
                  ہزاروں صفحات اور بولے کروڑوں الفاظ کو بھی ختم کر سکتے ہیں؟
                  <br />
                  کاش عقل و خرد کے دشمن یہ جان سکیں کہ یہ زمانہ سر نہیں، دماغ
                  فتح کرنے کا ہے، مگر وہ تو محض ناپختہ دماغوں کو ماؤف کر کے اسے
                  اپنے مذموم مقاصد کے لیے استعمال کرنے کے قائل ہیں !<br />
                  جب بھی ڈاکٹر صاحب جیسے مصلحین غارت گروں کا نشانہ بنتے ہیں تو
                  میرے ذہن میں ابن سعد کا روایت کردہ وہ تاریخی واقعہ یاد آجاتا
                  ہے جب حضرت علی رضی اللہ عنہ کے قاتل ملعون’’ عبدالرحمان ابن
                  ملجم‘‘ کو حد جاری کرنے کے لیے پیش کیا گیا تھا۔ امیر المومنین
                  سیدنا حسن رضی اللہ عنہ نے اس سے آخری خواہش کے بارے میں پو چھا
                  ۔ اپنے وقت کے اس بدترین دہشت گرد نے کہا تھا کہ اسے دو رکعت
                  نماز پڑھنے کی اجازت دی جائے۔ اس موقع پر بھی انتقام اور غصے کے
                  باوجود متعدد لوگوں کی مخالفت کو خاطر میں نہ لاتے ہوئے برداشت
                  اور رواداری ہی کا مظاہرہ کیا گیا۔ امیرالمومنین نے اس کی اجازت
                  دے دی۔اس کے بعد بھی ابن ملجم کی زہرناک گمراہی کو چین نہ آیا۔
                  اس نے قرآن مجید کی تلاوت شروع کر دی اور کہا کہ اس کی موت تلاوت
                  کرتے ہوئے آنی چاہیے۔ ظاہرہے اس خواہش کا پورا کرنا کسی کے بس
                  میں نہ تھا۔ چنانچہ اسے خاموش کرانا پڑا۔
                  <br />
                  نہ جانے آج بھی کتنے ابن ملجم ظلم کے اندھے وار کرنے کے باوجود
                  اسی قسم کی سعادت کے خواب دیکھتے ہوں گے۔ لیکن عادل مطلق کا بھی
                  یہی فیصلہ ہے کہ اس کی عدالت میں کھوٹے سکوں کی کوئی وقعت نہیں ۔
                  <br />
                  ڈاکٹرفاروق خاں اپنی ثابت قدمی ثابت کرتے ہوئے سرخرو ہو چکے ہیں
                  ۔ لیکن ان کے ادھورے کام ابھی باقی ہیں ۔<br />
                  کاش انھیں بر وقت احساس ہو جاتا کہ موسم بدل گیا ہے ، کام ا بھی
                  بہت سارے باقی ہیں اور سورج جلد غروب ہو گا!
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="آج سورج جلد غروب ہو گیا"
              />

              <div className="content">
                <h2>آج سورج جلد غروب ہو گیا</h2>
                جب موسم بدل رہا ہو تو یوں لگتا ہے کہ دن کی روشنی جلد ختم ہو گئی
                ہے۔ آج بھی یہی احساس ہو رہا تھا کہ ابھی کام توبہت سارے باقی ہیں،
                مگرلگتا ہے کہ سورج غروب ہونے کو ہے۔ اسی احساس میں تھا کہ موبائل
                فون کی گھنٹی نے مجھے گاڑی سڑک کے ایک طرف لگانے پر مجبور کر دیا ۔
                اور اسی لمحے مجھے ڈاکٹر صاحب کی شہادت کی خبر ملی۔ آج واقعی سورج
                بہت جلد غروب ہو گیا تھا!
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
