import "./App.css";
import Navv from "./components/Navv";
import Footer from "./components/Footer";
import Frontpage from "./components/Pages/Frontpage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Aajsooraj from "./components/Pages/Articles/Aajsooraj";
import Abiskeshehr from "./components/Pages/Articles/Abiskeshehr";
import Dfkikitab from "./components/Pages/Articles/Dfkikitab";
import Dfkkishahadat from "./components/Pages/Articles/Dfkkishahadat";
import Ghamidisbkiguftagu from "./components/Pages/Articles/Ghamidisbkiguftagu";
import Drfarooqkhanshaheed from "./components/Pages/Articles/Drfarooqkhanshaheed";
import Mardemujahid from "./components/Pages/Articles/Mardemujahid";
import Dfkiklamhaefikria from "./components/Pages/Articles/Dfkiklamhaefikria";
import Kuchyadenkuchbaten from "./components/Pages/Articles/Kuchyadenkuchbaten";
import Eenaahejigersozay from "./components/Pages/Articles/Eenaahejigersozay";
import Eikmujahidkishahadat from "./components/Pages/Articles/Eikmujahidkishahadat";
import Eikshaheed from "./components/Pages/Articles/Eikshaheed";
import Dfkabdizindagi from "./components/Pages/Articles/Dfkabdizindagi";
import Fughaneneem1 from "./components/Pages/Articles/Fughaneneem1";
import Fughaneneem2 from "./components/Pages/Articles/Fughaneneem2";
import Mymission from "./components/Pages/Articles/Mymission";
import Rahewafa from "./components/Pages/Articles/Rahewafa";
import Tasuraat from "./components/Pages/Articles/Tasuraat";
import Thewarrior from "./components/Pages/Articles/Thewarrior";
import Contactus from "./components/Pages/Contactus";
function App() {
  return (
    <div className="App">
      <Navv />
      <BrowserRouter>
        <div style={{ marginTop: "75px", marginBottom: "30px" }}>
          <Routes>
            <Route exact path="/" element={<Frontpage />} />
            <Route
              exact
              path="aaj-sooraj-jald-ghuroob-ho-gaya"
              title="Dr Farooq aaj-sooraj-jald-ghuroob-ho-gaya"
              element={<Aajsooraj />}
            />
            <Route
              exact
              path="ab-is-ke-shehr-main-thehrain-ke"
              element={<Abiskeshehr />}
            />
            <Route
              exact
              path="dr-farooq-khan-aur-un-ki-kitab-ikisvin-sadi-aur-pakistan"
              element={<Dfkikitab />}
            />
            <Route
              exact
              path="dr-farooq-khan-ki-shahadat"
              element={<Dfkkishahadat />}
            />
            <Route
              exact
              path="dr-farooq-khan-ki-shahadat-par-ghamidi-sb-ki-guftagu"
              element={<Ghamidisbkiguftagu />}
            />
            <Route
              exact
              path="dr-farooq-khan-shaheed"
              element={<Drfarooqkhanshaheed />}
            />
            <Route
              exact
              path="dr-muhammad-farooq-khan-eik-mard-e-mujahid"
              element={<Mardemujahid />}
            />
            <Route
              exact
              path="dr-muhammad-farooq-khan-ki-shahadat-eik-lamha-e-fikria"
              element={<Dfkiklamhaefikria />}
            />
            <Route
              exact
              path="dr-muhammad-farooq-kuch-yaden-kuch-baten"
              element={<Kuchyadenkuchbaten />}
            />
            <Route
              exact
              path="een-aahe-jiger-sozay"
              element={<Eenaahejigersozay />}
            />
            <Route
              exact
              path="eik-mujahid-ki-shahadat"
              element={<Eikmujahidkishahadat />}
            />
            <Route exact path="eik-shaheed" element={<Eikshaheed />} />
            <Route
              exact
              path="farooq-ki-taveel-awr-abdi-zindagi"
              element={<Dfkabdizindagi />}
            />
            <Route
              exact
              path="fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-1"
              element={<Fughaneneem1 />}
            />
            <Route
              exact
              path="fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-2"
              element={<Fughaneneem2 />}
            />
            <Route exact path="my-mission" element={<Mymission />} />
            <Route
              exact
              path="rah-e-wafa-per-chalne-wale"
              element={<Rahewafa />}
            />
            <Route exact path="Tasuraat" element={<Tasuraat />} />
            <Route
              exact
              path="the-warrior-who-became-a-martyr"
              element={<Thewarrior />}
            />
            <Route exact path="contact_us" element={<Contactus />} />
            <Route path="*" element={<Frontpage />} />
          </Routes>
        </div>
      </BrowserRouter>
      <br />
      <Footer />
    </div>
  );
}

export default App;
