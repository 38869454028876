import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Dfkiklamhaefikria() {
  return (
    <div>
      {" "}
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>
      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-muhammad-farooq-khan-ki-shahadat-eik-lamha-e-fikria">
                ڈاکٹر محمد فاروق کی شہادت — ایک لمحۂ فکریہ
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>ڈاکٹر محمد خالد مسعود*</p>

                <p>
                  جو شخص پہلی ہی ملاقات میں دل میں گھر کر جائے اورجو محبت بانٹتا
                  ہو،وہ نفرت کا نشانہ کیسے بن گیا؟ کیا نفرتوں کی شدت سے ہمارے دل
                  اتنے پتھر ہو چکے ہیں کہ ہم دوست اور دشمن میں تمیز کھو بیٹھے
                  ہیں؟ کیا ہم اپنی سنگ دلی میں اتنے دور جا چکے ہیں کہ انسان
                  دوستی، دردمندی، خیر خواہی، وسعت نظری، نرم کلامی اور سلاست فکری
                  کو واجب القتل قرار دینے لگے ہیں؟ہم کیسے خوف کا شکار ہیں کہ
                  اپنے سوا کسی دوسرے کی بات سننے سے ڈرتے ہیں۔ ہم اپنی آوازکو اس
                  قدر پوجتے ہیں کہ دوسری آوازوں کو خاموش کرنا فرض سمجھنے لگے
                  ہیں۔ ہم اتنے خود پسند اور اتنے خود پرست کیوں ہیں کہ یہ سوچنا
                  بھی گوارا نہیں کہ کوئی اور بھی ہماری بھلائی کی بات کر سکتا ہے؟
                  ہمارے ذہنوں میں یہ شدت اور سختی کہاں سے آگئی ہے کہ ہم حکمت و
                  موعظت کا جواب گولی سے دینے لگے ہیں؟ یہ تکبر اور غرور کیسا ہے
                  جو نصیحت سے سیخ پا ہو کر قتل و غارت پر اتر آتا ہے؟ ڈاکڑ محمد
                  فاروق کی شہادت کی خبر سنتے ہی اس طرح کے سوالوں نے اس طرح گھیرا
                  ہوا ہے کہ ان کی شہادت میرے لیے ایک لمحۂ فکریہ بن گئی ہے۔
                  <br />
                  ڈاکٹر صاحب سے ایک اجلاس میں اسلام آباد میں ملاقات ہوئی ۔ اسلام
                  میں خواتین کے حقوق زیرِ بحث تھے۔ مقررین کی گفتگو زیادہ تر منفی
                  رخ پر تھی۔ ڈاکٹر صاحب نے نہایت متوازن اور مدلل گفتگو کرتے ہوئے
                  خواتین کے حقوق کے بارے میں اسلامی تعلیمات پر روشنی ڈالی۔ ان کے
                  تحمل ، دھیمے لہجے، لیکن دو ٹوک اور واضح موقف نے بحث کا رخ موڑ
                  دیا۔ ان کی گفتگو طوالتِ کلام، زورِ بیان اور لفظی گھن گرج کی
                  بجائے استدلال کی قوت،مطالعے کی کثرت اور نقد وتجزیہ کی صلاحیت
                  پر مبنی تھی۔ چنانچہ اجلاس میں نہ صرف موضوع بحث کے حوالے سے
                  اسلام کے بارے میں ایک مثبت رجحان پیدا ہوا، بلکہ خواتین کے حقوق
                  کے بارے میں ایک معتدل نقطۂ نظر سامنے آیا۔
                  <br />
                  اس کے بعد ڈاکٹر صاحب سے اکثر ملنا ہوتا اور ہر ملاقات میں ان کی
                  صاف گوئی اور سلاستِ کلام کا قائل ہو نا پڑتا۔ وہ اسلامی تعلیمات
                  اور نصوص کو محض نقل نہیں کرتے تھے، بلکہ ان کے بیان کا حق ادا
                  کرتے تھے۔ان احکام کا سیاق و سباق واضح کرنے کے لیے وہ کسی ایک
                  دلیل پر اکتفا نہ کرتے، بلکہ اس موضوع پر مختلف شواہد کو اس طرح
                  پیش کرتے کہ نہ صرف حکم واضح ہو جاتا، بلکہ اس کا مقصد اور سبب
                  بھی سامنے آجاتا۔وہ قدیم اور جدید میں اس خوبی کے ساتھ ربط پیدا
                  کرتے کہ درمیان میں خلاتلاش کر نا مشکل ہو جاتا۔
                  <br />
                  ڈاکٹر صاحب نے کم و بیش ان تمام موضوعات پر قلم اٹھایا جن پر
                  ہمارے ہاں عموماً اختلاف رائے پایا جاتا ہے۔ اسلام میں خواتین کا
                  مقام و مرتبہ ہو یا دور جدید میں ان کے حقوق اور آزادی کا، جرم و
                  سزا کے پیچیدہ نظریات ہوں یا ان کی اصلاح کی ضرورت کا سوال، جہاد
                  اور قتال کے بارے میں قدیم اور جدید موقف میں ٹکراؤ ہو یا مغرب
                  سے مکالمہ، اسلامی معاشروں میں غیر مسلموں کے حقوق کی بات ہو یا
                  نوجوان نسل کے اسلام کے بارے میں شبہات اور سوالات،ان سب مو
                  ضوعات پر ڈاکٹر صاحب نے تفصیل اور وضاحت کے ساتھ لکھا۔ میرے خیال
                  میں ان کی سوچ میں نقاوت ، ان کی گفتگو میں فصاحت اور ان کے قلم
                  میں نظافت ، ان کی طب و جراحت میں تعلیم اور نفسیات کے علم اور
                  تجربے سے آئی تھی۔ آج سوچتا ہوں کہ ان کے جانے سے ہم نے بیک وقت
                  کتنے علوم، تجربوں اور مہارتوں کو کھو دیا۔ ہم نے اس نظافت و
                  طہارت اور فکری پاکیزگی کو قتل کر دیا جس کی آج ہمیں سب سے زیادہ
                  ضرورت ہے۔ ڈاکٹر فاروق نے تو شہادت پائی۔ وہ تو نفس مطمئنہ تھے
                  جو پورے یقین و ایمان سے اسلام کی خدمت کرتے رہے۔ ان کے اہل خانہ
                  کو بھی ان کی شہادت پر فخر ہو گا، لیکن ان کے دیگر پس ماندگان کو
                  اس خلا کا شدت سے احساس ہے جو ان کی جوان موت سے پیدا ہوا ہے۔
                  <br />
                  میں اس سوال کا جواب تلاش نہیں کر سکاکہ ڈاکٹر فاروق جیسے انسان
                  دوست اور محبتیں بانٹنے والے شخص اتنی بے رحمی سے کیسے قتل
                  ہوجاتے ہیں۔ ہم اپنے محسنوں اور خیر خواہوں کا خون کب تک بہاتے
                  رہیں گے۔ آخر نفرتوں کی یہ آگ کب ٹھنڈی ہو گی؟
                </p>

                <p>* سابق چیئرمین، اسلامی نظریاتی کونسل۔</p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر محمد فاروق کی شہادت — ایک لمحۂ فکریہ"
              />

              <div className="content">
                <h2>ڈاکٹر محمد فاروق کی شہادت — ایک لمحۂ فکریہ</h2>
                جو شخص پہلی ہی ملاقات میں دل میں گھر کر جائے اورجو محبت بانٹتا
                ہو،وہ نفرت کا نشانہ کیسے بن گیا؟ کیا نفرتوں کی شدت سے ہمارے دل
                اتنے پتھر ہو چکے ہیں کہ ہم دوست اور دشمن میں تمیز کھو بیٹھے ہیں؟
                کیا ہم اپنی سنگ دلی میں اتنے دور جا چکے ہیں کہ انسان دوستی،
                دردمندی، خیر خواہی، وسعت نظری، نرم کلامی اور سلاست فکری کو واجب
                القتل قرار دینے لگے ہیں؟ہم کیسے خوف کا شکار ہیں کہ اپنے سوا کسی
                دوسرے کی بات سننے سے ڈرتے ہیں۔ ہم اپنی آوازکو اس قدر پوجتے ہیں
                کہ دوسری آوازوں کو خاموش کرنا فرض سمجھنے لگے ہیں۔ ہم اتنے خود
                پسند اور اتنے خود پرست کیوں ہیں کہ یہ سوچنا بھی گوارا نہیں کہ
                کوئی اور بھی ہماری بھلائی کی بات کر سکتا ہے؟ ہمارے ذہنوں میں یہ
                شدت اور سختی کہاں سے آگئی ہے کہ ہم حکمت و موعظت کا جواب گولی سے
                دینے لگے ہیں؟ یہ تکبر اور غرور کیسا ہے جو نصیحت سے سیخ پا ہو کر
                قتل و غارت پر اتر آتا ہے؟ ڈاکڑ محمد فاروق کی شہادت کی خبر سنتے
                ہی اس طرح کے سوالوں نے اس طرح گھیرا ہوا ہے کہ ان کی شہادت میرے
                لیے ایک لمحۂ فکریہ بن گئی ہے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
