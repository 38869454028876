import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Mymission() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row">
          <div className="col-md-4" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="My Mission"
              />

              <div className="content">
                <h2>My Mission</h2>
                To exalt the dignity of Pakistan, to uphold the cause of the
                Muslim Ummah and to strive for the well being of the whole of
                the humanity are my whole efforts and my sole mission. This is
                my opinion that Muslim Ummah as well as Pakistan are in a
                degraded and helpless position because we, the Muslims of
                Pakistan as well as the members of the whole Muslim Ummah, do
                not care for the essential and basic collective moral values. We
                are extremely backward in fulfilling and abiding all these basic
                values. It is binding upon us to fulfill and abide by the
                following at least ten basic moral values. Without honoring
                these values, we will not be able to survive in the world as a
                prestigious and independent nation.
              </div>
            </div>
            <AsideLink />{" "}
          </div>
          <div className="col-md-8">
            <h1 className="title">My Mission</h1>
            <hr />
            <div
              className="fb-like"
              data-href="/my-mission"
              data-width="300"
              data-action="like"
              data-show-faces="true"
              data-share="true"
            ></div>
            <hr />
            <div className="row">
              <div className="col-md-11">
                <p>
                  To exalt the dignity of Pakistan, to uphold the cause of the
                  Muslim Ummah and to strive for the well being of the whole of
                  the humanity are my whole efforts and my sole mission. This is
                  my opinion that Muslim Ummah as well as Pakistan are in a
                  degraded and helpless position because we, the Muslims of
                  Pakistan as well as the members of the whole Muslim Ummah, do
                  not care for the essential and basic collective moral values.
                  We are extremely backward in fulfilling and abiding all these
                  basic values. It is binding upon us to fulfill and abide by
                  the following at least ten basic moral values. Without
                  honoring these values, we will not be able to survive in the
                  world as a prestigious and independent nation.
                </p>

                <p>These are the essential and fundamental values:</p>
                <ul>
                  <li>Human equality and democratic culture</li>
                  <li>Justice</li>
                  <li>Honesty</li>
                  <li>Merit</li>
                  <li>Hardwork and thorough struggle</li>
                  <li>Law abiding</li>
                  <li>Strategy and patience</li>
                  <li>Education</li>
                  <li>Health</li>
                  <li>
                    Strong and well-coordinated welfare and service institutions
                    for the depressed classes
                  </li>
                </ul>

                <p>
                  Development and dignity of Pakistan and the Ummah is based on
                  the positive efforts to achieve these ideals and standards. It
                  is very clear that a thorough struggle is needed in this
                  regard. This struggle should be totally free and clear from
                  any kind of violence, sloganism, sentimentalism and provoking
                  ideas.
                </p>

                <p>
                  I always strictly follow the teachings of Quran, Sunnah,
                  Hikmat in Quranic perspective on all the collective issues and
                  problems facing us. I have reached to the conclusion that on
                  some important issues, we need for a widespread struggle of
                  reformation and coordinated efforts.
                </p>

                <p>
                  Following are some basic issues relevant to Ummah and
                  Pakistan:
                </p>
                <ol>
                  <li>To bring democratic culture in Muslim states.</li>
                  <li>
                    To dissolve all the disputes and conflicts through peaceful
                    negotiations
                  </li>
                  <li>
                    To dominate and inculcate the above mentioned ten basic
                    collective values in all the
                  </li>
                  <li>
                    To make a serious and conscious advance in the field of
                    science and technology
                  </li>
                </ol>

                <p>
                  I have written extensively on the above issues.
                  <b>
                    This is my vision and the struggle for this purpose is the
                    mission of my life.
                  </b>
                </p>
              </div>
            </div>
            <div className="meta">
              <div className="time">
                <span
                  className="glyphicon glyphicon-time"
                  aria-hidden="true"
                ></span>
                July 25, 2015 9:31pm
              </div>
            </div>
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
