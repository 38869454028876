import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Tasuraat() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/Tasuraat">تاثرات</a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>مرتب: خورشید احمد ندیم</p>

                <blockquote>
                  <p>
                    [ڈاکٹر فاروق خان کی شہادت پر دنیا بھر کے علمی حلقوں اور
                    شخصیات نے اپنے دکھ کا اظہار
                    <br />
                    کیا ہے۔ ای میل سے موصول ہو نے والے بعض تعزیتی پیغام یہاں نقل
                    کیے جا رہے ہیں۔]
                  </p>
                </blockquote>

                <h4>پروفیسر فرید اسحاق</h4>

                <p>
                  <b>
                    استاد الٰہیات، یونیورسٹی آف جو ہنس برگ،آکلینڈ، ساؤتھ افریقا
                  </b>
                  <br />
                  میں ایک عظیم شخصیت کی موت پر شدید دکھ کی کیفیت میں ہوں۔میں
                  پاکستان اور اپنے اس نقصان پر ماتم کناں ہوں۔ان کے جانے سے ہمارا
                  علمی وفکری افلاس بہت بڑھ گیا ہے۔
                  <br />
                  ڈاکٹر صاحب !آپ ہماری ان کاوشوں کی صورت میں زندہ رہیں گے جو اس
                  دہشت ووحشت کے خلاف جاری ہیں۔ آپ ہماری اس تمنا کی شکل میں زندہ
                  ہیں جو ہم ایک نئی دنیا کی تخلیق کے لیے رکھتے ہیں۔
                </p>

                <h4>کلاس سپرونک (Klass Spronk)</h4>

                <p>
                  <b>
                    پروفیسر عہد نامہ عتیق،پروٹیسٹنٹ تھیالوجی یونیورسٹی، کمپن،
                    ہالینڈ
                  </b>
                  <br />
                  اپنے عزیزدوست ڈاکٹر فاروق خان کی ناگہانی موت نے مجھے شدید دکھ
                  میں مبتلا کر دیا ہے۔پچھلے سال ایک کانفرنس میں ان سے ملاقات
                  ہوئی۔اس کے بعد میں نے ہالینڈ میں بہت سے لوگوں کو بتا یا کہ کس
                  طرح وہ قرآن مجید کے تصورِ امن کو عام کر رہے ہیں۔ یہ بات ہمارے
                  لیے باعثِ افتخار ہے کہ ہم اس کا نفرنس کی روداد میں ان کے
                  خیالات بھی شائع کر رہے ہیں۔ان کے یہ خیالات ان کا وشوں میں
                  معاون ثابت ہوں گے جو دنیا میں تشدد کی حوصلہ شکنی اور امن کے
                  لیے ہو رہی ہیں۔
                </p>

                <h4>لئیق حسن</h4>

                <p>
                  میں ڈاکٹر فاروق خان کی پر درد موت پر شدید صدمے کی کیفیت میں
                  ہوں۔میں جب فیس بک پر اپنے ساتھ ان کی تصاویر دیکھتا ہوں تو مجھے
                  اس خبر پر یقین نہیں آتا۔ان کی شخصیت اتنی پر کشش تھی کہ ہم
                  تادیر ان کے ساتھ رہنا چا ہتے تھے۔یہ امتِ مسلمہ اور با لخصوص
                  پاکستان کے لیے ایک بڑا نقصان ہے۔پختون قوم کے لیے بھی یہ بڑا
                  صدمہ ہے۔
                </p>

                <p>
                  <b>
                    اکبر ناصر خان،ایم پی پی، امیدوار، HKS، ہارورڈ یو نیورسٹی،
                    امریکا
                  </b>
                </p>

                <p>
                  ہم نے ایک ایسے شخص کو کھو دیا جو پورے اعتماد اور ایمانی قوت کے
                  ساتھ غلط کو غلط کہنے کا حو صلہ رکھتا تھا۔ انھوں نے اپنے
                  استدلال سے بہت سے دلوں کو تبدیل کیا۔ انھوں نے اسکالرز اور طالب
                  علموں کی ایک پو ری نسل کو مثاثر کیا۔
                </p>

                <h4>محمد عمر قذافی</h4>

                <p>
                  <b>
                    ریسرچ ایسو سی ایٹ، اقبال انٹرنیشنل انسٹی ٹیوٹ، بین الاقوامی
                    اسلامی یو نیورسٹی، اسلام آباد
                  </b>
                </p>

                <p>
                  ڈاکٹر فاروق خان کی شہادت ایک ایسے مضطرب معاشرے کا نا قابلِ
                  تلافی نقصان ہے جو توازن کی تلاش میں ہے۔اس دکھ کو علمی اور غیر
                  علمی، دونوں حلقوں میں محسوس کیا جا ئے گا۔وہ جرأت کے ساتھ اپنا
                  نقطۂ نظر پیش کرنے والے ایک بے مثل اسکالر تھے۔وہ اقبال کے اس
                  شعر کی مجسم تصویر تھے:
                </p>

                <blockquote>
                  <p>
                    <b>
                      ہو حلقۂ یاراں تو بریشم کی طرح نرم
                      <br />
                      رزمِ حق و باطل ہو تو فولاد ہے مومن
                    </b>
                  </p>
                </blockquote>

                <h4>زیبا میر حسینی</h4>

                <p>
                  <b> SOAS، یونیورسٹی آف لندن</b>
                </p>

                <p>
                  اس ظلم پر میں شدید صدمے کی کیفیت میں ہوں۔میں جان سکتی ہوں کہ
                  یہ کتنا بڑا نقصان ہے۔مجھے یقین ہے کہ یہ قربانی ضائع نہیں ہو گی
                  اور پاکستانی قوم میں بیداری کا پیغام بنے گی۔
                </p>

                <h4>زینا انور</h4>

                <p>
                  <b> ڈائریکٹر، مساوات، سسٹرز ان اسلام، سلنگر، ملائشیا</b>
                </p>

                <p>
                  یہ حادثہ ہمارے سامنے یہ افسوس ناک منظر پیش کر تا ہے کہ کس طرح
                  اللہ تعالیٰ اور اسلام کے نام پر انتہا پسندی کا عمل جاری ہے۔میں
                  ڈاکٹر صاحب سے شخصی طور پر واقف نہیں تھی، لیکن مجھے اندازہ ہے
                  کہ ایسے لوگ کتنے کم یاب اور خاص ہو تے ہیں جو بھری مجلس میں
                  کلمۂ حق کہنے کی جرأت اور سلیقہ رکھتے ہیں۔ہمیں یہ عزم کرنا ہے
                  کہ ان کی جد وجہد جاری رہے گی۔ اللہ تعالیٰ ان کی مغفرت فرمائے۔
                </p>
              </div>

              <hr />

              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img className="img-responsive" src={ProfileImage} alt="تاثرات" />

              <div className="content">
                <h2>تاثرات</h2>
                پروفیسر فرید اسحاق استاد الٰہیات، یونیورسٹی آف جو ہنس
                برگ،آکلینڈ، ساؤتھ افریقا میں ایک عظیم شخصیت کی موت پر شدید دکھ
                کی کیفیت میں ہوں۔ میں پاکستان اور اپنے اس نقصان پر ماتم کناں
                ہوں۔ان کے جانے سے ہمارا علمی وفکری افلاس بہت بڑھ گیا ہے۔ ڈاکٹر
                صاحب !آپ ہماری ان کاوشوں کی صورت میں زندہ رہیں گے جو اس دہشت
                ووحشت کے خلاف جاری ہیں۔ آپ ہماری اس تمنا کی شکل میں زندہ ہیں جو
                ہم ایک نئی دنیا کی تخلیق کے لیے رکھتے ہیں۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
