import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Eikmujahidkishahadat() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/eik-mujahid-ki-shahadat">ایک مجاہد کی شہادت</a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>ڈاکٹر شہزاد سلیم</p>

                <p>
                  ڈاکٹر محمد فاروق خان نے ایک نڈر مجاہد کی زندگی گزاری اور شہادت
                  کی موت سے ہم کنار ہوئے۔پچھے کافی عرصہ سے وہ موت سے آنکھیں چار
                  کیے ہوئے تھے ، کیونکہ وہ برابر دہشت گردی پر کھلی تنقید کررہے
                  تھے۔اس مجاہد کا تمام تر اسلحہ قرآن و سنت کے دلائل پر مبنی
                  تھا۔موت کی دھمکیاں ان کو ان کے مؤقف سے دست بردار نہ
                  کرسکیں۔احتیاط کرنے کے مشوروں کو انھوں نے کوئی خاص اہمیت نہ دی
                  ۔ بالآخر وہی ہوا جو متوقع تھا۔۲اکتوبر کو دہشت گردوں نے ان کو
                  ان کے کلینک میں شہید کردیا۔وہ شخص جو زندگی میں ہمیشہ مسکراتا
                  ہوا نظر آتا تھا، موت کی حالت میں بھی مسکراہٹ اس کے چہرے پر
                  رونق افروز تھی۔
                </p>

                <p>
                  ڈاکٹر فاروق ایک ہمہ گیر شخصیت تھے ۔ وہ بے پناہ قوت کار کے مالک
                  تھے۔اپنے نقطۂ نظر پر ہمیشہ پامردی سے ڈٹے رہتے اور صرف دلیل ہی
                  ان کو اپنے نقطۂ نظر میں تبدیلی کرنے کا باعث بنتی۔وہ بیک وقت
                  ایک مذہبی اسکالر، دانش ور اور سیاسی لیڈر تھے۔وہ بہت سے مذہبی
                  ٹی وی پروگراموں کے میزبان رہے اور متعدد ٹی وی پروگراموں میں
                  مہمان مقرر کے طور پر شریک ہوئے۔وہ ایک مؤثرخطیب بھی تھے اور
                  تقریباً ایک درجن کتابوں کے مصنف تھے۔ مگر سب سے بڑھ کر وہ
                  انسانیت کا اعلیٰ نمونہ تھے اور ایک بہت متواضع شخصیت کے مالک
                  تھے۔وہ بہت سے بے سہاروں کا سہارا اور کئی یتیموں کے سرپرست
                  تھے۔بہت سے غریب طلبا کی تعلیم کا خرچ اٹھائے ہوئے تھے۔ ڈاکٹری
                  کے پیشے کی بھی انھوں نے بے لوث خدمت کی۔
                  <br />
                  ڈاکٹر محمد فاروق خان ۱۹۵۴ء میں خیبر پختون خواہ کے ایک قصبہ میں
                  پیدا ہوئے۔ ۱۹۷۹ ء میں انھوں نے خیبر میڈیل کالج پشاور سے MBBS
                  کا امتحان پاس کیا اور ۱۹۸۴ء میں سائکاٹری میں University of
                  Vienna سے ڈپلومہ حاصل کیا۔ وہ جماعت اسلامی کے سرگرم رکن بھی
                  رہے۔ ۱۹۹۱میں وہ معروف مذہبی اسکالر جاوید احمد غامدی کی فکر سے
                  منسلک ہوئے اور پھر مرتے دم تک ان کے فکرکی تبلیغ و اشاعت میں
                  بھرپور طریقہ سے سرگرم رہے۔ المورد ، ادارۂ علم و تحقیق کی مجلس
                  منتظمہ کے بھی وہ ایک عرصہ تک رکن رہے۔ وہ آج کل سوات کی اسلامی
                  یونیورٍسٹی کے وائس چانسلر تھے۔
                  <br />
                  ان کی نمایاں تصنیفات میں ’’آسان ترین ترجمہ و تفسیر قرآن مجید‘‘
                  ، ’’اسلام کیاہے؟‘‘ ، ’’اسلام اور عورت‘‘، ’’جہاد و قتال‘‘،
                  ’’حدود و تعزیرات‘‘، ’’جدید ایشوزاور اسلام‘‘، ’’پاکستان اور
                  اکیسویں صدی‘‘، ’’امت مسلمہ‘‘، ’’کامیابی کا راستہ‘‘ اور ’’مسئلہ
                  کشمیر: ماضی، حال اور مستقبل‘‘ شامل ہیں۔ ان کی ویب سائٹ
                  www.drfarooqkhan.com پر ان کی شخصیت اور کام کی تفصیل دیکھی
                  جاسکتی ہے۔
                  <br />
                  ڈاکٹر فاروق بحث و دلیل کے آدمی تھے۔ جب ان کے مخالفین دہشت گردی
                  اور خودکش حملوں پر ان کی تنقید کا جواب نہ دے سکے تو انھوں نے
                  ان کو موت کی دھمکیاں دینی شروع کردیں۔اور جب موت کی دھمکیاں بھی
                  ان کو خاموش نہ کرسکیں تو ظالموں نے بندوق کی گولیوں سے ان کو
                  خاموش کردیا۔ راہ عدم کا یہ مسافر اب اپنی منزل پر فائزالمرام
                  ہوکر پہنچ چکا ہے۔ مجھے کامل یقین ہے کہ اس نے موت کے فرشتہ کا
                  مسکراتے ہوئے استقبال کیا ہوگااور شاید اس وقت اس کے کانوں میں
                  حضرت علی رضی اللہ عنہ کے یہ الفاظ گونج رہے ہوں گے:{" "}
                  <b>فزت برب الکعبہ</b>: ( رب کعبہ کی قسم، میں کامیاب ہو گیا)۔
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ایک مجاہد کی شہادت"
              />

              <div className="content">
                <h2>ایک مجاہد کی شہادت</h2>
                ڈاکٹر محمد فاروق خان نے ایک نڈر مجاہد کی زندگی گزاری اور شہادت
                کی موت سے ہم کنار ہوئے۔پچھے کافی عرصہ سے وہ موت سے آنکھیں چار
                کیے ہوئے تھے، کیونکہ وہ برابر دہشت گردی پر کھلی تنقید کررہے تھے۔
                اس مجاہد کا تمام تر اسلحہ قرآن و سنت کے دلائل پر مبنی تھا۔موت کی
                دھمکیاں ان کو ان کے مؤقف سے دست بردار نہ کرسکیں۔احتیاط کرنے کے
                مشوروں کو انھوں نے کوئی خاص اہمیت نہ دی ۔ بالآخر وہی ہوا جو
                متوقع تھا۔۲اکتوبر کو دہشت گردوں نے ان کو ان کے کلینک میں شہید
                کردیا۔وہ شخص جو زندگی میں ہمیشہ مسکراتا ہوا نظر آتا تھا، موت کی
                حالت میں بھی مسکراہٹ اس کے چہرے پر رونق افروز تھی۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
