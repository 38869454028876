import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Kuchyadenkuchbaten() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-muhammad-farooq-kuch-yaden-kuch-baten">
                ڈاکٹر محمد فاروق — کچھ یادیں، کچھ باتیں
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>کوکب شہزاد</p>

                <p>
                  ڈاکٹر محمد فاروق خان ایک ہمہ جہت شخصیت تھے، مقرر،مصنّف ،مفسّر،
                  معالج، سوشل ورکر اور ٹی وی پرفورمر۔ ۲۰۰۹ء میں جب میں کسی
                  سیمینار کے سلسلے میں مصر گئی تو وہاں ’’Reproductive Health
                  ‘‘کے موضوع پران کی کتاب دیکھ کر مجھے بڑی حیرت ہوئی۔ ایک سال
                  پہلے جب انھوں نے اپنے علاقے میں دہشت گر دوں کے خلاف آپریشن سے
                  متاثر ہونے والے علاقوں کے دو سو متاثر خاندانوں کو اپنے علاقے
                  میں ٹھہر ا رکھا تھا اور وہ اور ان کی اہلیہ ان لوگوں کے کھانے،
                  پینے اور ریایش کے انتظام میں سرگرم تھے تو میں نے ان سے درخواست
                  کی کہ وہ میری کلاس میں آکر لیکچر دیں، اس موقع پر انھوں نے مجھے
                  جو جواب دیا، اس سے میرے دل میں ان کی عزّت بہت بڑھ گئی۔ انھوں
                  نے کہا: ’’ میں مردان سے باہر زیادہ وقت نہیں گزارتا کیونکہ مجھے
                  اندیشہ رہتا ہے کہ اگر رات کو بارش یا طوفان آئے تو ان لوگوں کو
                  مشکلات کا سامنا کرناپڑے گا۔‘‘
                </p>

                <p>
                  کچھ سال پہلے ہم لوگ ان کے گھر کچھ دنو ں کے لیے مردان میں
                  ٹھہرے، انھوں نے اور ان کی اہلیہ نے زبردست میزبانی کی۔ اس دوران
                  میں ایک بات پر بڑی حیرت ہوتی کہ میز پر کھانا چننے کے بعد وہ
                  دونوں میاں بیوی کھانے والے کمرے سے باہر چلے جاتے، جبکہ ہمارے
                  پنجاب میں تو مہمان کے ساتھ بیٹھ کر کھانا کھایا جاتا ہے۔ بہت
                  دیر بعد میری سمجھ میں یہ بات آئی کہ شاید وہ اس لیے باہر چلے
                  جاتے ہیں تاکہ ہم بے تکلّفی سے کھانا کھا سکیں۔
                  <br />
                  ان کی شخصیت کی کتنی پرتیں تھیں، کھلے ہوئے گلاب کی پتیوں کی طرح
                  ان کی شخصیت کے رنگ تھے ۔اسی سال جنوری میں اسلا م آباد میں ،میں
                  اور وہ ایک سیمینارمیں اکٹھے تھے اور نوجوانوں کے مسائل سن رہے
                  تھے اور ان کے سوالوں کے جواب دے رہے تھے۔ اس دوران میں انھوں نے
                  بتایا کہ میں نے اپنے بچّوں سے کہہ رکھا تھا کہ تم جب بھی کہو گے
                  ،میں تمھاری شادی کردوں گا، لیکن تم زندگی میں کبھی کوئی غلط کام
                  نہ کرنا۔
                  <br />
                  ان کا تعلّق ایک ایسے علاقے سے تھا جہاں ابھی تک عورت کو سات
                  پردوں میں رکھنے کو پسند کرتے ہیں، لیکن اس کے باوجود عورتوں کی
                  ترّقی اور حقوق کے وہ کس قدر علم بردار تھے، اس کی سب سے بڑی
                  مثال خود ان کی زوجہ محترمہ ڈاکٹر رضوانہ فاروق صاحبہ ہیں جن کو
                  وہ ہمیشہ اپنے ساتھ لے کر چلے۔ ڈاکٹر صاحب اپنے ہر لیکچر اور
                  گفتگو میں کسی نہ کسی حوالے سے ان کا ذکر کرتے۔ میں نے اکثر یہ
                  سن رکھا ہے کہ ہر کامیاب مرد کے پیچھے ایک عورت کا ہاتھ ہوتا ہے،
                  لیکن بچشم خود دیکھا ہے کہ وہ اپنی بیوی کے لیے کتنا بڑا سہارا
                  تھے ۔<br />
                  وہ اپنے مریضوں کے ڈاکٹر ہی نہیں، دوست بھی تھے۔ وہ انھیں ملاقات
                  کا وقت بھی زیادہ دیتے اور فون پر بھی ان کی شکایتیں انتہائی
                  تحمّل مزاجی سے سنتے اور آدھی تکلیف تو ان کی تسلّی ہی سے دور
                  ہوجاتی ۔بہت کچھ کہا جاسکتا ہے، مگر اتنا ہی کہوں گی کہ اللہ ان
                  کی شہادت کو قبول فرمائے اور ان کی مغفرت فرمائے۔
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر محمد فاروق — کچھ یادیں، کچھ باتیں"
              />

              <div className="content">
                <h2>ڈاکٹر محمد فاروق — کچھ یادیں، کچھ باتیں</h2>
                ڈاکٹر محمد فاروق خان ایک ہمہ جہت شخصیت تھے، مقرر،مصنّف ،مفسّر،
                معالج، سوشل ورکر اور ٹی وی پرفورمر۔ ۲۰۰۹ء میں جب میں کسی سیمینار
                کے سلسلے میں مصر گئی تو وہاں ’’Reproductive Health‘‘ کے موضوع
                پران کی کتاب دیکھ کر مجھے بڑی حیرت ہوئی۔ ایک سال پہلے جب انھوں
                نے اپنے علاقے میں دہشت گر دوں کے خلاف آپریشن سے متاثر ہونے والے
                علاقوں کے دو سو متاثر خاندانوں کو اپنے علاقے میں ٹھہر ا رکھا تھا
                اور وہ اور ان کی اہلیہ ان لوگوں کے کھانے، پینے اور ریایش کے
                انتظام میں سرگرم تھے تو میں نے ان سے درخواست کی کہ وہ میری کلاس
                میں آکر لیکچر دیں، اس موقع پر انھوں نے مجھے جو جواب دیا، اس سے
                میرے دل میں ان کی عزّت بہت بڑھ گئی۔ انھوں نے کہا: ’’ میں مردان
                سے باہر زیادہ وقت نہیں گزارتا کیونکہ مجھے اندیشہ رہتا ہے کہ اگر
                رات کو بارش یا طوفان آئے تو ان لوگوں کو مشکلات کا سامنا کرناپڑے
                گا۔‘‘
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
