import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Mardemujahid() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box" ng-controller="mainController">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-muhammad-farooq-khan-eik-mard-e-mujahid">
                ڈاکٹر محمد فاروق خان — ایک مرد مجاہد
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>محمد راشد</p>

                <p>
                  ڈاکٹر محمد فاروق خان — ایک مرد مجاہد، جس نے اپنی زندگی جہالت
                  اور ناخواندگی کے خلاف جدوجہد کے لیے وقف کر رکھی تھی، اس مجاہد
                  کو شہید کر دیا گیا۔ — ایک بہادر انسان، جو ظالموں کے سامنے کلمۂ
                  حق کہتا رہا،انھیں انسانیت کا اورعدل و انصاف کا درس دیتا رہا، ،
                  ننگی تلواروں کے درمیان دلیل کا پرچم لہراتا رہا، اس بہادرسے اس
                  کی قوم کو محروم کر دیا گیا۔ — ایک چراغ راہ، جو بڑھتے ہوئے
                  اندھیروں میں روشنی پھیلانے کی کوشش کرتا رہا، دین کی روشنی، علم
                  کی روشنی، اخلاق کی روشنی، اس چراغ کو ہمیشہ کے لیے بجھا دیا
                  گیا۔ — ایک مہربان سرپرست، جس نے یتیم بچوں کی باپ بن کر کفالت
                  کی، ہزاروں دکھی اور بے سہارا انسانوں کو گلے سے لگایا، ان کا غم
                  اپنایا، ان کو سہارا دیا، اس مہربان کو ظلم کا نشانہ بنا دیا
                  گیا۔
                </p>

                <p>
                  ڈاکٹر محمد فاروق خان نے اپنی ساری زندگی ہنستے مسکراتے ہوئے
                  گزاری۔ ہمیشہ لوگو ں میں مسکراہٹیں تقسیم کیں۔وہ کسی بڑی شخصیت
                  سے مخاطب ہوتے یا عام ملازم سے،ایک تبسم ان کے چہرے پر کھلا
                  رہتاجو مخاطب کوان کی محبت ، شفقت اور انسانیت کا بھر پور احساس
                  دلاتا۔ان کے ماتھے پر کبھی بل نہ آتا تھا۔یہ وصف وہ آخری سانس تک
                  نہ بھولے ، اپنے ظالم قاتل کو بھی مسکرا کر دیکھتے رہے۔ یہی
                  مسکراہٹ موت کے بعد بھی ان کے چہرے پر سجی ہوئی تھی۔
                  <br />
                  ڈاکٹر محمد فاروق خان انسانیت کا ایک اعلیٰ نمونہ تھے۔ وہ جا نتے
                  تھے کہ یہ زندگی چند روزہ ہے ۔ انھوں نے زندگے کی نعمت کو بھرپور
                  استعمال کیا۔ اللہ کے دین اور انسانوں کی خدمت کو انھوں نے اپنا
                  مشن بنا رکھا تھا۔وہ اس مشن کے لیے دن رات کام کرتے تھکتے نہ
                  تھے۔ وہ آخر وقت تک اسی مشن کے لیے سرگرم رہے۔ ان کا جانا ایک
                  بڑا حادثہ، ایک بڑا نقصان ہے، لیکن اس کے ساتھ ساتھ ہم یہ بھی
                  جانتے ہیں کہ کسی بڑی سے بڑی شخصیت کے دنیا سے رخصت ہو جانے سے
                  بھی دنیا کے کام رک نہیں جاتے۔اللہ کی مشیت کے مطابق ڈاکٹر فاروق
                  خان کا کام پورا ہوا اور اس نے انھیں واپس بلا لیا، وہ جب چاہے
                  گا،ان جیسے اور لوگ پیدا کر دے گا جو اندھیروں میں شمع جلاتے
                  رہیں گے، جو گردنیں کٹاتے رہیں گے،جو حق کا پرچم لہراتے رہیں گے،
                  جو اس مشن کو آگے بڑھاتے رہیں گے جس کا عزم ڈاکٹر محمد فاروق خان
                  نے کر رکھا تھا۔
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر محمد فاروق خان — ایک مرد مجاہد"
              />

              <div className="content">
                <h2>ڈاکٹر محمد فاروق خان — ایک مرد مجاہد</h2>
                ڈاکٹر محمد فاروق خان — ایک مرد مجاہد، جس نے اپنی زندگی جہالت اور
                ناخواندگی کے خلاف جدوجہد کے لیے وقف کر رکھی تھی، اس مجاہد کو
                شہید کر دیا گیا۔ — ایک بہادر انسان، جو ظالموں کے سامنے کلمۂ حق
                کہتا رہا،انھیں انسانیت کا اورعدل و انصاف کا درس دیتا رہا، ، ننگی
                تلواروں کے درمیان دلیل کا پرچم لہراتا رہا، اس بہادرسے اس کی قوم
                کو محروم کر دیا گیا۔ — ایک چراغ راہ، جو بڑھتے ہوئے اندھیروں میں
                روشنی پھیلانے کی کوشش کرتا رہا، دین کی روشنی، علم کی روشنی،
                اخلاق کی روشنی، اس چراغ کو ہمیشہ کے لیے بجھا دیا گیا۔ — ایک
                مہربان سرپرست، جس نے یتیم بچوں کی باپ بن کر کفالت کی، ہزاروں
                دکھی اور بے سہارا انسانوں کو گلے سے لگایا، ان کا غم اپنایا، ان
                کو سہارا دیا، اس مہربان کو ظلم کا نشانہ بنا دیا گیا۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
      </div>
    </div>
  );
}
