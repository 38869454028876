import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Eeenaahejigersozay() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/een-aahe-jiger-sozay">
                ایں آہِ جگر سوزے در خلوت صحرا بہ
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <div className="urdu article-body">
                  <p>(تحریر: عمار خان ناصر)</p>

                  <p>
                    ڈاکٹر محمد فاروق خان بھی شہادت کے اس مقام پر فائز ہو گئے جو
                    اس دنیا میں ایک مرد مجاہد کی سب سے بڑی تمنا ہو سکتی ہے۔
                    پچھلے تیس سال سے ہماری مذہبی اور عسکری قیادت ا س خطے میں جو
                    کھیل کھیلتی چلی آرہی ہے، اس کے نتیجے میں مذہبی انتہا پسندی
                    ’فتنۃ لا تصیبن الذین ظلموا منکم خاصۃ‘ کی صورت میں اپنے منحوس
                    سایے پوری قوم اور پورے ملک پر پھیلا چکی ہے۔ ڈاکٹر صاحب اس کی
                    بھینٹ چڑھنے والے پہلے فرد نہیں، لیکن انھوں نے ایک مذہبی دانش
                    ور کی حیثیت سے جس جرأت واستقامت اور بے خوفی کے ساتھ اس کے
                    خلاف کلمہ حق مسلسل بلند کیے رکھا، اس کی کوئی دوسری مثال شاید
                    موجود نہیں۔
                    <br />
                    میری ان سے پہلی ملاقات چند سال قبل غالباً اس وقت ہوئی جب وہ
                    والد گرامی مولانا زاہد الراشدی کی ملاقات کے لیے گوجرانوالہ
                    ہمارے ہاں تشریف لائے۔ ۲۰۰۱ء میں علما کے سیاست میں حصہ لینے،
                    نجی جہاد اور اسلامی ریاست میں زکوٰۃ کے علاوہ ٹیکس کے جواز
                    وعدم جواز کے حوالے سے استاذ گرامی جناب جاوید احمد غامدی کے
                    نقطۂ نظر پر والد گرامی مولانا زاہد الراشدی نے سنجیدہ لہجے
                    میں ایک علمی تنقید لکھی تو ’’المورد‘‘ کے حلقۂ فکر کی طرف سے
                    اس کا خیر مقدم کیا گیا اور جناب معز امجد اور جناب خورشید
                    احمد ندیم نے اس لب ولہجے پر والد گرامی کا شکریہ ادا کیا۔ اس
                    مباحثے میں ڈاکٹر محمد فاروق خان بھی شریک ہوئے اور اسی نے ان
                    کے دل میں والد گرامی کی ملاقات کی تحریک پیدا کی۔ چنانچہ وہ
                    چند دوستوں کے ہمراہ گوجرانوالہ تشریف لائے اور اس سلسلے میں
                    خوشی اور مسرت کے جذبات کا اظہار کیا۔
                    <br />
                    وسعت نظر اور کھلا پن ان کے شخصی مزاج اور ان کے فکری پس منظر،
                    دونوں کا حصہ تھا۔ ان کی ابتدائی فکری تربیت جماعت اسلامی کے
                    زیر اثر ہوئی، لیکن مذہبی سیاست کے رخ اور خاص طو رپر دور جدید
                    کے سماجی وسیاسی مسائل کے حوالے سے اہل مذہب کی تعبیرات نے ان
                    کے ذہن میں کئی سوالات پیدا کیے جن کا شافی جواب انھیں روایتی
                    مذہبی فکر میں نہیں مل سکا۔ انھوں نے تلاش حق کے جذبے سے ان
                    تمام نقطہ ہاے نظر اور مکاتب فکر کا مطالعہ کیا جو دور جدید
                    میں کسی بھی حوالے سے مذہب کی نمائندگی کا دعویٰ کرتے ہیں۔ اس
                    فکری سفر کے نتیجے میں انھیں مذہب کی تفہیم وتشریح کے علمی
                    منہج کے حوالے سے دبستان فراہی اور دور جدید کے زندہ مسائل کے
                    حوالے سے اسلامی قانون کی تعبیر نو کے ضمن میں جناب جاوید احمد
                    غامدی کے نتائج فکر نے مطمئن کیا۔ یہ اطمینان حاصل ہونے کے بعد
                    ان کی تمام دینی و دعوتی اور سیاسی وسماجی سرگرمیوں کا محور
                    ومرکز اسی فکر کا ابلاغ قرار پایا اور وہ گویا اس کی ترجمانی
                    اور ترویج واشاعت کے لیے وقف ہو کر رہ گئے۔ تاہم، ان کا یہ
                    اطمینان ایک اصولی اور عمومی نوعیت ہی کا تھا اور اس میں اندھی
                    تقلید کا رنگ موجود نہیں تھا۔ ایک سوچنے سمجھنے والے آدمی کی
                    طرح وہ خود بھی ان نتائج فکر پر غور کرتے رہتے تھے اور مختلف
                    آرا سے خود اختلاف کرنے کے علاوہ اس ضمن میں کسی بھی جانب سے
                    مثبت اور تعمیری تنقید کو بے حد قدر کی نگاہ سے دیکھتے تھے۔
                    <br />
                    ڈاکٹر محمد فاروق خان نے سماج اور سیاست کے زندہ مسائل کے بارے
                    میں دینی نقطۂ نظر سے اپنی دعوت قوم تک پہنچانے کے لیے بھرپور
                    جدوجہد کی اور یقیناًایک بڑے وسیع حلقے تک روشنی کا پیغام
                    پہنچانے میں کامیاب رہے۔ ان کے فکری سفر نے ان پر واضح کر دیا
                    تھا کہ اس وقت مسلم امہ کے تمام بنیادی مسائل کی جڑ اس پر طاری
                    فکری جمود میں پیوست ہے اور اس کا واحد حل یہ ہے کہ آزادانہ
                    غور وفکر، مثبت تنقید اور مباحثہ ومکالمہ کی بنیاد پر ایک کھلا
                    ماحول پیدا کیا جائے تاکہ لوگوں کے لیے اپنے اپنے تعصبات سے
                    آزاد ہو کر حالات اور مسائل کو ان کے درست تناظر میں دیکھنے کا
                    موقع ملے اور سب لوگ کھلے ذہن کے ساتھ ایک دوسرے کے زاویۂ نظر
                    سے استفادہ کریں۔ ماہنامہ ’’الشریعہ‘‘ میں ہم نے گزشتہ کچھ
                    عرصے سے اپنی بساط کی حد تک یہی مزاج اور فضا پیدا کرنے کی
                    کوشش کی ہے اور ڈاکٹر صاحب اس پر ہماری مسلسل حوصلہ افزائی
                    کرتے رہتے تھے، بلکہ بعض اوقات متفکر ہو کر پوچھتے تھے کہ
                    ’’الشریعہ‘‘ نے جو طرز اختیار کیا ہے، وہ ہمارے روایتی مذہبی
                    حلقے کے فکر ومزاج اور ذہنی سانچے کے لیے بالکل اجنبی ہے، اس
                    لیے آپ حضرات اس کو کب تک نبھا سکیں گے؟
                    <br />
                    ڈاکٹر صاحب مذہبی انتہا پسندی اور خاص طو رپر جہادی تنظیموں کے
                    طرز عمل کے سخت ناقد تھے، تاہم یہ اختلاف ہمدردی اور خیر خواہی
                    کا اختلاف تھا اور وہ نظم ریاست کے خلا ف برسر پیکار عناصر کو
                    ہر حال میں کچل دینے کے بجائے حکمت اور دانش کے ساتھ انھیں راہ
                    راست پر لانے کی خواہش رکھتے تھے۔ سوات کی تحریک طالبان کی
                    اعلیٰ ترین قیادت کے ساتھ ان کے ذاتی روابط تھے اور وہ ان
                    تعلقات کو حکومت اور طالبان کے مابین اعتماد کی فضا پیدا کرنے
                    اور دونوں فریقوں کو گفتگو کی میز پر لانے کے لیے استعمال کرنے
                    کی بھی کوشش کرتے رہے، چنانچہ کچھ عرصہ پہلے سرحد حکومت اور
                    تحریک نفاذ شریعت محمدی کے مابین سوات میں امن وامان کے قیام
                    اور نفاذ شریعت کے حوالے سے جو معاہدہ ہوا، ا س میں طالبان کی
                    طرف سے سوات میں ایک اسلامی یونیورسٹی کے قیام کی شرط بھی رکھی
                    گئی تھی جس کے وائس چانسلر کے طور پر فریقین کے اتفاق سے ڈاکٹر
                    محمد فاروق خان کا نام تجویز کیا گیا۔ یہ ان کے خلوص اور
                    صلاحیت پر فریقین کے اعتماد کا ایک اظہار تھا۔ ڈاکٹر صاحب اس
                    تعلیمی منصوبے کی ذمہ داری ملنے پر بے حد خوش تھے اور انھیں
                    توقع تھی کہ اس خلوص، محنت اور حکمت کے ساتھ اس منصوبے پر کام
                    کیا جائے تو نہ صرف اس علاقے کے شدت پسند مذہبی عناصر کو
                    اعتدال اور حکمت کے راستے پر لایا جا سکتا ہے، بلکہ یہ ادارہ
                    دینی علو م کے ایسے ماہرین بھی معاشرے کو فراہم کر سکتا ہے جو
                    دور جدید کے تقاضوں کے مطابق اسلام کی نمائندگی کر سکیں۔ اس
                    توقع اور امکان نے ڈاکٹر صاحب کو بہت پرجوش بنا دیا تھا اور وہ
                    وائس چانسلر کے طور پر اپنے تقرر کے فوراً بعد اس کے تعلیمی
                    پروگرام اور نصاب وغیرہ کی ترتیب وتدوین کے سلسلے میں متحرک ہو
                    گئے تھے۔
                    <br />
                    ڈاکٹر صاحب نے اس سلسلے میں بعض دیگر ہم خیال وہم مزاج ساتھیوں
                    کے علاوہ شخصی طورپر مجھ سے بھی رابطہ کیا اور کہا کہ دینی
                    علوم کے شعبے کو صحیح نہج پر استوار کرنے کے لیے وہ مجھے اپنے
                    ساتھ اس کام میں شریک کرنا چاہتے ہیں، اس لیے مجھے اپنی تمام
                    دوسری مصروفیات اور سرگرمیوں کو جیسے بھی اور جس قیمت پر بھی
                    سمیٹنا پڑے، سمیٹ کر سوات منتقل ہو جانا چاہیے۔ میں نے ان سے
                    گزارش کی کہ سوات میں اسلامی یونی ورسٹی کے قیام کی یہ تجویز
                    کسی باقاعدہ غور و فکر کے نتیجے میں نہیں، بلکہ ایک ہنگامی قسم
                    کے سیاسی معاہدے کے تحت سامنے آئی ہے اور سردست اس کا مستقبل
                    سرتاسر سیاسی صورت حال پر منحصر ہے، اس لیے اس پہلو کو نظر
                    انداز کر کے جلد بازی میں کوئی بھی فیصلہ نہیں کیا جا سکتا۔
                    تاہم، اس نوعیت کے خدشات سے ڈاکٹر صاحب کے جوش اور لگن میں
                    کوئی کمی واقع نہ ہوئی اور یہ منصوبہ آخر دم تک ان کی توجہ اور
                    مساعی کا مرکز رہا۔ بہرحال بعد میں یہ معاہدہ برقرار نہ رہ سکا
                    اور معاملہ اس حد تک جا پہنچا کہ حکومتی رٹ قائم کرنے کے لیے
                    سوات میں ایک بڑا فوجی آپریشن کرنا پڑا۔ آپریشن کی کامیابی اور
                    تحریک طالبان سوات کی طاقت ٹوٹ جانے کے بعد وہی ہوا جو ہونا
                    تھا۔ حکومتی حلقوں میں سوات کی مجوزہ یونیورسٹی کے حوالے سے یہ
                    بحث پیدا ہو گئی کہ اس کی صورت گری ایک عام یونیورسٹی کی طرز
                    پر کی جائے یا اسلامی یونیورسٹی کی نہج پر۔ اس کے علاوہ دوسری
                    بہت سی بیوروکریٹک پیچیدگیوں نے اس معاملے کو الجھائے رکھا تا
                    آنکہ ڈاکٹر فاروق خان کا وقت مقرر آ پہنچا اور وہ یہ حسرت دل
                    میں لیے دنیا سے رخصت ہو گئے۔ یقیناًوہ آخری آدمی تھے جو اس
                    یونیورسٹی کو اصل منصوبے کے مطابق اعلیٰ دینی تعلیم کی ایک
                    معیاری درس گاہ بنانا چاہتے تھے۔ ان کے بعد حکومت یا بیورو
                    کریسی کے ذمہ داران سے اس کی توقع کیونکر کی جا سکتی ہے!
                    <br />
                    ڈاکٹر صاحب انتہا پسند عناصر کی پالیسیوں پر بلا خوف لومۃ لائم
                    اپنے ناقدانہ خیالات کا اظہار ہر فورم پر کرتے رہے۔ انتہا پسند
                    عناصر کے ذہنی سانچے میں درمیان کے آدمی کے لیے کوئی جگہ نہیں
                    ہوتی۔ ان کے نزدیک کوئی شخص یا تو ان کے ساتھ ہوتا ہے یا ان کا
                    دشمن، اس لیے ان سے اختلاف کرنے والا چاہے کتنی ہی ہمدردی اور
                    خیر خواہی کے ساتھ ایسا کرتا ہو، دشمن کے کیمپ کا آدمی ہی
                    سمجھا جاتا ہے۔ ڈاکٹر صاحب کا معاملہ بھی یہی تھا، چنانچہ
                    انتہا پسندوں کی طرف سے ہدف کے طو رپر ان کے نام کا اعلان کئی
                    سال پہلے مولانا حسن جان شہید کے نام کے ساتھ کیا جا چکا تھا
                    اور ڈاکٹر صاحب اس کے بعد سے گویا ہتھیلی پر جان رکھ کر اپنا
                    کام جاری رکھے ہوئے تھے۔ سوات کے آپریشن کے بعد خود کش حملوں
                    کے لیے تیار کیے جانے والے جو بہت سے نوجوان پکڑے گئے، سرحد
                    حکومت نے ان کے ذہنوں سے انتہا پسندی کے اثرات زائل کرنے اور
                    درست رخ پر ان کی فکری تربیت کا سلسلہ شروع کیا تھا جس میں
                    ڈاکٹر صاحب بھرپور اورمتحرک کردار ادا کر رہے تھے۔ غالباً ان
                    کے اسی کردار سے مخالفین کا پیمانہ صبر لبریز ہو گیا اور ان کی
                    آواز کو ہمیشہ کے لیے خاموش کر دینے کا فیصلہ کر لیا گیا۔ ان
                    کی شہادت جرأت وعزیمت، استقامت او رقربانی کا ایک قابل رشک
                    نمونہ پیش کرتی ہے۔ انھوں نے شدید خطرے کے ماحول میں بھی نہ تو
                    اپنے ضمیر کے مطابق کلمۂ حق کہنا چھوڑا، نہ اظہار رائے میں
                    کوئی مداہنت اختیار کی اور نہ اپنی سماجی ودعوتی سرگرمیوں ہی
                    کسی قسم کی کوئی کمی کی۔ ان کی شہادت صرف المورد کے حلقہ فکر
                    کے لیے نہیں بلکہ حقیقت میں ملک وقوم، معاشرے اور دینی جدوجہد
                    کے لیے ایک ناقابل تلافی نقصان ہے۔
                    <br />
                    اللہ تعالیٰ ان کی حسنات اور خدمات کو بہتر سے بہتر اجر کا
                    مستحق بنائیں، ان کی انسانی لغزشوں اور کوتاہیوں سے درگذر
                    فرمائیں اور ان کے پس ماندگان کو مصیبت کے اس وقت میں صبر
                    وحوصلہ اور ایمان واستقامت کی نعمت سے بہرہ ور فرمائیں۔
                    اللّٰہم اغفر لہ وارحمہ واکرم نزلہ ووسع مدخلہ. اللّٰہم اوجرنا
                    فی مصیبتنا واخلفنا خیرا منہ. اللّٰہم لا تحرمنا اجرہ ولا
                    تفتنا بعدہ۔ آمین
                  </p>
                </div>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ایں آہِ جگر سوزے در خلوت صحرا بہ"
              />

              <div className="content">
                <h2>ایں آہِ جگر سوزے در خلوت صحرا بہ</h2>
                ڈاکٹر محمد فاروق خان بھی شہادت کے اس مقام پر فائز ہو گئے جو اس
                دنیا میں ایک مرد مجاہد کی سب سے بڑی تمنا ہو سکتی ہے۔ پچھلے تیس
                سال سے ہماری مذہبی اور عسکری قیادت ا س خطے میں جو کھیل کھیلتی
                چلی آرہی ہے، اس کے نتیجے میں مذہبی انتہا پسندی ’فتنۃ لا تصیبن
                الذین ظلموا منکم خاصۃ‘ کی صورت میں اپنے منحوس سایے پوری قوم اور
                پورے ملک پر پھیلا چکی ہے۔ ڈاکٹر صاحب اس کی بھینٹ چڑھنے والے پہلے
                فرد نہیں، لیکن انھوں نے ایک مذہبی دانش ور کی حیثیت سے جس جرأت
                واستقامت اور بے خوفی کے ساتھ اس کے خلاف کلمہ حق مسلسل بلند کیے
                رکھا، اس کی کوئی دوسری مثال شاید موجود نہیں۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
