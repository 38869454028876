import React from "react";

export default function AsideLink() {
  return (
    <div>
      {" "}
      <div className="aside">
        <h3>
          OTHER ARTICLES
          <span
            className="glyphicon glyphicon-th"
            aria-hidden="true"
            style={{ color: "#ddd", float: "right" }}
          ></span>
        </h3>
        <ul className="">
          <li className="urdu sidelinks">
            <a href="/fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-2">
              فغانِ نیم شبی بے نوائے راز نہیں (حصہ دوم)
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-1">
              فغانِ نیم شبی بے نوائے راز نہیں (حصہ اول)
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-farooq-khan-aur-un-ki-kitab-ikisvin-sadi-aur-pakistan">
              ڈاکٹر فاروق خان اور ان کی کتاب ’’اکیسویں صدی اور پاکستان‘‘
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/Tasuraat">تاثرات</a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-muhammad-farooq-khan-eik-mard-e-mujahid">
              ڈاکٹر محمد فاروق خان — ایک مرد مجاہد
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-muhammad-farooq-kuch-yaden-kuch-baten">
              ڈاکٹر محمد فاروق — کچھ یادیں، کچھ باتیں
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/eik-mujahid-ki-shahadat">ایک مجاہد کی شہادت</a>
          </li>

          <li className="urdu sidelinks">
            <a href="/farooq-ki-taveel-awr-abdi-zindagi">
              ’’فاروق‘‘ کی طویل اور ابدی زندگی
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/aaj-sooraj-jald-ghuroob-ho-gaya">
              آج سورج جلد غروب ہو گیا
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/rah-e-wafa-per-chalne-wale">راہِ وفا پر چلنے والے</a>
          </li>

          <li className="urdu sidelinks">
            <a href="/eik-shaheed">ایک شہید</a>
          </li>

          <li className="urdu sidelinks">
            <a href="/ab-is-ke-shehr-main-thehrain-ke">
              اب اس کے شہر میں ٹھہریں کہ
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-farooq-khan-shaheed">ڈاکٹر فاروق خان شہید</a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-muhammad-farooq-khan-ki-shahadat-eik-lamha-e-fikria">
              ڈاکٹر محمد فاروق کی شہادت — ایک لمحۂ فکریہ
            </a>
          </li>

          <li className="english sidelinks">
            <a href="/the-warrior-who-became-a-martyr">
              The Warrior who became a Martyr
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/een-aahe-jiger-sozay">ایں آہِ جگر سوزے در خلوت صحرا بہ</a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-farooq-khan-ki-shahadat-par-ghamidi-sb-ki-guftagu">
              ڈاکٹر فاروق خان کی شہادت پرغامدی صاحب کی گفتگو
            </a>
          </li>

          <li className="urdu sidelinks">
            <a href="/dr-farooq-khan-ki-shahadat">ڈاکٹر فاروق خان کی شہادت</a>
          </li>

          <li className="english sidelinks">
            <a href="/my-mission">My Mission</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
