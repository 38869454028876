import React from "react";
import PIC1 from "../../images/article_images/fikr-e-imroz.jpg";
import PIC2 from "../../images/article_images/fikr-e-imroz.jpg";
import PIC3 from "../../images/article_images/fikr-e-imroz.jpg";
import PIC4 from "../../images/article_images/fikr-e-imroz.jpg";
import PIC5 from "../../images/article_images/dr-farooq-khan-3.png";
import PIC6 from "../../images/article_images/ummat-e-muslimah-kamyabi-ka-rasta-2.jpg";
import PIC7 from "../../images/article_images/ummat-e-muslimah-kamyabi-ka-rasta-1.jpg";
import PIC8 from "../../images/article_images/jihad-o-qitaal-chand-ahem-mabahis.jpg";
import PIC9 from "../../images/article_images/islam-aur-aurat.jpg";
import PIC10 from "../../images/article_images/family-planning-aur-islam.jpg";
import PIC11 from "../../images/article_images/islam-and-the-modern-world.jpg";
import PIC12 from "../../images/article_images/america-and-the-muslim-world.jpg";
import PIC13 from "../../images/article_images/page_1_thumb_large.jpg";
import PIC14 from "../../images/article_images/jihad-versus-terrorism.jpg";
import PIC15 from "../../images/article_images/islam-and-women.jpg";

export default function Frontpage() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <br />
          <br />
          <p className="lead">
            &ldquo;He lived like an undaunted warrior and died the death of a
            martyr&rdquo;
          </p>
          <br />
        </div>
      </div>

      <div className="container white-box" ng-controller="mainController">
        <article className="row">
          <div className="col-md-8">
            <h2>Dr. Muhammad Farooq Khan</h2>
            <hr />
            <p className="lead englishjustify">
              He lived like an undaunted warrior and died the death of a martyr.
              For the past few years, he gallantly stared death in its eyes as
              he continued to vehemently condemn terrorism and militancy. His
              armory was nothing but arguments drawn from the sources of Islam.
              Death threats could not swerve him from his stance and he would
              seldom pay heed to voices beckoning him to exercise care and
              caution. And then, the inevitable happened. He was shot at when he
              was busy seeing his patients at his clinic in Mardan on October
              the 2<sup>nd</sup> and could not survive the bullet wounds. His
              face still wore that typical beam – his vintage hallmark familiar
              to all those who knew him – when he lay enshrouded before his
              burial.
            </p>
            <a
              className="btn btn-outline-primary btn-sm float-left"
              href="/my-mission"
            >
              Read more
            </a>
            <p></p>
            <div className="row">
              <h2>Featured videos</h2>

              <div className="col-md-3 col-sm-6 pad-in">
                <a
                  href="https://javedahmedghamidi.org/#!/video/5aabdac1b951cb6c099ea333"
                  target="blank"
                >
                  <img
                    className="img-responsive vidpic"
                    src={PIC1}
                    alt="کیا ہر قسم کی موسیقی حرام ہے ؟"
                  />
                </a>
              </div>

              <div className="col-md-3 col-sm-6 col-xs-12 pad-in">
                <a
                  href="https://javedahmedghamidi.org/#!/video/5aabdc5bb951cb6c099eaf47"
                  target="blank"
                >
                  <img
                    className="img-responsive vidpic"
                    src={PIC2}
                    alt="فکر امروز ۔ قسط 9"
                  />
                </a>
              </div>

              <div className="col-md-3 col-sm-6 col-xs-12 pad-in">
                <a
                  href="https://javedahmedghamidi.org/#!/video/61a8699db3903b1a2622e4b6"
                  target="blank"
                >
                  <img
                    className="img-responsive vidpic"
                    src={PIC3}
                    alt="فکر امروز ۔ قسط 8"
                  />
                </a>
              </div>

              <div className="col-md-3 col-sm-6 col-xs-12 pad-in">
                <a
                  href="https://javedahmedghamidi.org/#!/video/5aabdc5ab951cb6c099eaf3f"
                  target="blank"
                >
                  <img
                    className="img-responsive vidpic"
                    src={PIC4}
                    alt="فکر امروز ۔ قسط 7"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 sidebar" style={{ marginTop: "50px" }}>
            <img
              className="img-responsive vidpic"
              src={PIC5}
              alt="My Mission"
            />
            <br />
            <br />
            <ul className="list-en clearfix" style={{ listStyle: "none" }}>
              <li className="english">
                <a href="/the-warrior-who-became-a-martyr">
                  The Warrior who became a Martyr
                </a>
              </li>

              <li className="urdu">
                <a href="/een-aahe-jiger-sozay">
                  ایں آہِ جگر سوزے در خلوت صحرا بہ
                </a>
              </li>
              <li className="urdu">
                <a href="/dr-farooq-khan-ki-shahadat-par-ghamidi-sb-ki-guftagu">
                  ڈاکٹر فاروق خان کی شہادت پرغامدی صاحب کی گفتگو
                </a>
              </li>
              <li className="urdu">
                <a href="/dr-farooq-khan-ki-shahadat">
                  ڈاکٹر فاروق خان کی شہادت
                </a>
              </li>
              <li className="english">
                <a href="/my-mission">My Mission</a>
              </li>
              <br />
            </ul>
          </div>
        </article>
        <hr />
        <article className="row">
          <div className="col-md-12">
            <h2>Books</h2>
          </div>
          <div className="BookShowcase">
            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5ad19e4b25ecaaf83421f01a"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC6}
                  alt="امت مسلمہ ۔ کامیابی کا راستہ (حصہ دوم)"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa6719d5e891e8f44a44352"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC7}
                  alt="امت مسلمہ ۔ کامیابی کا راستہ (حصہ اول)"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa671b15e891e8f44a444ad"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC8}
                  alt="جہاد و قتال ۔ چند اہم مباحث"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa671b75e891e8f44a44509"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC9}
                  alt="اسلام اور عورت"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa671bb5e891e8f44a44565"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC10}
                  alt="فیملی پلاننگ اور اسلام"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa671c85e891e8f44a446b2"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC11}
                  alt="Islam and the Modern World"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa671bf5e891e8f44a445c3"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC12}
                  alt="America and the Muslim World"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa95576b6705dbd7ccbf8b5"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC13}
                  alt="Family Planning"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa95575b6705dbd7ccbf8a9"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC14}
                  alt="Jihad versus Terrorism"
                />
              </a>
            </div>

            <div className="book-thumb">
              <a
                href="https://javedahmedghamidi.org/#!/books/5aa671be5e891e8f44a4458a"
                target="blank"
              >
                <img
                  className="img-responsive"
                  src={PIC15}
                  alt="Islam and Women"
                />
              </a>
            </div>
          </div>
        </article>

        <hr />
      </div>
    </div>
  );
}
