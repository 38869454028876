import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Dfkkishahadat() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-farooq-khan-ki-shahadat">ڈاکٹر فاروق خان کی شہادت</a>
            </h1>
            <div className="row">
              <div className="col-md-12 pull-right">
                <div className="article_body lang-ur">
                  <p>(تحریر: خورشید ندیم)</p>

                  <p>
                    اپنی سدا بہار مسکراہٹ کے ساتھ ڈاکٹر فاروق خان لحد میں اتر
                    گئے۔پسِ مرگ اس لازوال تبسم کے گردآسودگی اور طمانیت کاہالہ
                    تھا۔جیسے مو جوں سے الجھتے الجھتے کسی کو کنارہ مل جا ئے۔جیسے
                    تاریک رات اور گھنے جنگلوں میں را ستہ ڈھونڈتے ڈھونڈتے کسی کا
                    قدم اچانک ایک کشادہ شاہراہ پرآپڑے۔ایسے لمحوں میں جو اطمینان
                    ایک راہ رو کو نصیب ہو تا ہے،وہی اطمینان ڈاکٹر فاروق خان کے
                    چہرے کو اپنی گود میں لیے ہو ئے تھا۔
                    <br />
                    ایک مسلمان کی زندگی کیا ہے اللہ کے آخری رسول صلی اللہ علیہ
                    وسلم نے بتایا،جیسے پسِ دیوارِ زنداں ایک قیدی۔ وہ قید سے
                    نکلتا ہے توآسودہ ہو جاتا ہے۔قرآن مجید نے اعلان کیا کہ وہ جب
                    اس دنیامیں خیر کی علامت بن کے جیتا اور پھر رخصت ہو تا ہے
                    تواسے نفسِ مطمئنہ کے نام سے آوازدی جاتی ہے۔پروردگار کی رضا
                    اس کی منتظر ہوتی ہے اور اللہ کی جنت اس کا استقبال کر تی ہے
                    جو اُس جیسے آ سودہ حالوں کا دائمی مسکن ہے۔ میری بیس اکیس
                    سالوں پر محیط یادوں کی گواہی یہ ہے کہ وہ وقت کی موجوں سے
                    الجھتے اور دنیا کے جنگل میں گم راہ کرنے والی تاریکیوں سے
                    دامن بچاتے سرخ رو ہو کر اپنے رب کے حضور میں حاضر ہو گئے
                    ہیں۔ان کے چہرے پر رقص کرتا اطمینان اظہارہے کہ وہ حالتِ 8
                    ایمان میں اور دینِ حق کے ایک سچے خادم اور داعی کی زندگی
                    گزارکر لحد میں اترے ہیں۔ایسے ہی لوگ ہیں جو نفسِ مطمئنہ کے
                    منصب پر فائز ہو تے ہیں:
                  </p>

                  <center>
                    <blockquote>
                      <p>
                        <b>
                          اے غائب از نظر کہ شدی ہم نشینِ دل
                          <br />
                          می گویمت دعا و ثنا می فرستمت
                        </b>
                      </p>
                    </blockquote>

                    <p></p>
                  </center>
                  <p>
                    ڈاکٹرصاحب کے ساتھ تعلق اور رفاقت کے ان برسوں کو اگر میں ایک
                    جملے میں سمیٹنے کی سعی کروں تو کہہ سکتا ہوں کہ ان کے ساتھ
                    صرف تعلقِ خاطر رکھا جا سکتاتھا۔وہ سب سے محبت کا تعلق رکھتے
                    تھے اور کسی کے لیے یہ ممکن نہیں تھاکہ وہ ان سے محبت کا رشتہ
                    قائم نہ کرے، اِلاّ یہ کہ دل سخت اور انسانی احساسات کی تمیز
                    سے بے نیاز ہو جائے۔وہ اختلاف کو بھی ایسے اسلوب میں بیان کرتے
                    کہ ان کے مؤقف کو قبول نہ کرنے والوں کے لیے یہ ممکن نہ ہوتا
                    کہ وہ ان کی حلاوتِ طبع کو رد کر سکے۔
                    <br />
                    ہمارے اس شہید بھائی کو اﷲ تعالیٰ نے متنوع صلاحیتوں سے نوازا
                    تھا۔ وہ علمی ذوق رکھتے تھے اور اس پر قادر تھے کہ اپنے مدعا
                    کو مخاطب کے دل و دماغ میں اتار دیں۔ وہ دعوت کا مزاج رکھتے
                    تھے اور ابلاغ کا سلیقہ بھی۔دعوت میں موعظتِ حسنہ کیا ہوتی ہے
                    اور احسن مجادلہ کیسے ہوتاہے، کوئی جاننا چاہتا تو ان کی رفاقت
                    میں کچھ وقت گزار کر جان سکتا تھا۔وہ سیاست کے نشیب و فراز سے
                    بھی آگاہ تھے۔انتخابات میں ’’اسلامک فرنٹ‘‘ کے امیدوار بنے تو
                    بائیس ہزار ووٹ لیے جب بڑے بڑے راہنما دس ہزار سے زیادہ ووٹ نہ
                    لے سکے۔وہ صاحبِ قلم بھی تھے۔ مستقل تصانیف کے ساتھ ساتھ انھوں
                    نے ایک اخبار کے لیے کالم بھی لکھااور لوگ اس کا ذکرکیا کرتے
                    تھے۔زندگی کے آخری دور میں وہ ایک ماہرِ تعلیم کے طور پر سامنے
                    آئے۔ تنہا ایک یونیورسٹی کو تصور سے واقعہ بنا دیا۔ ان سب صفات
                    کے پس منظر میں موجزن ان کا بے پناہ تحرک، ہم سوچتے اور حیران
                    ہوتے تھے۔
                    <br />
                    ان متنوع صفات کی موجودگی میں، ان کے لیے ہمیشہ یہ طے کرنا
                    مشکل رہا کہ وہ اپنے لیے کس میدانِ عمل کا انتخاب کریں۔یوں
                    انھوں نے ہر دشت کی سیاحی کی۔بیس برس پہلے وہ اس اصول کے قائل
                    ہو چکے تھے کہ اس محدود حیاتِ مستعار میں، کسی فردِ واحد کے
                    لیے یہ ممکن نہیں کہ وہ بیک وقت ہر میدان میں مردِمیدان ثابت
                    ہو۔ اسے بہرحال کسی ایک شعبے ہی کو اپنی سعی و کاوش کا مرکز
                    بنانا ہے۔اس نظری یک سوئی کے باوصف،انھیں یہ طے کرنے میں وقت
                    لگا کہ سیاستِ دوراں، ان جیسوں کا میدان نہیں۔زندگی کے آخری
                    دور میں وہ اس پر یک سو ہو چکے تھے کہ اس وقت ہمارا سب سے بڑا
                    مسئلہ تعلیم و تربیت ہے۔جب تک ہم اپنے سماج کو درست فکری
                    اساسات فراہم نہیں کریں گے، یہ زندگی کی دوڑ میں سرخرو نہیں
                    ہوگا۔اِس کے لیے لازم ہے کہ عامۃالناس کی تعلیم و تربیت کا
                    انتظام ہو۔ ایک طرف معاشرے میں رائج مذہبی و سماجی تصورات کی
                    اصلاح ہو اور دوسری طرف رواجی اور رسمی تعلیم کو بھی صحیح
                    بنیادیں فراہم کی جائیں تاکہ نئی نسل ان علوم، فنون اور تربیت
                    سے پوری طرح بہرہ ور ہوجو ہمارے اجتماعی وجود کی ناگزیر ضرورت
                    ہے۔
                    <br />
                    ڈاکٹر صاحب گذشتہ کئی برسوں سے، ہمہ تن اسی میں مصروف تھے۔ ایک
                    طرف اپنی تصانیف، خطبات اور روابط کے ذریعے سے مذہب اور سماج
                    سے متعلق اُن سوالات کو اپنا موضوع بنائے ہوئے تھے جن کا جواب
                    فراہم کیے بغیر ایک جدید مسلمان معاشرے کے خدوخال واضح نہیں ہو
                    سکتے اور دوسری طرف سوات یونیورسٹی ان کی پہلی ترجیح بن چکی
                    تھی،بلکہ یوں کہنا چاہیے کہ ان کارومانس تھا۔اس میں شاید
                    مبالغہ نہ ہو کہ اس چاہت پر انھوں نے اپنی زندگی بھی نچھاور کر
                    دی۔
                    <br />
                    ڈاکٹر فاروق خان کی شخصیت کا یہ پہلو بھی ایک مستقل تحقیق کا
                    موضوع ہے کہ پختون معاشرت میں ،وہ فکری اعتبارسے منفرد اور
                    اجنبی تھے۔یہ کہنا غلط نہیں ہے کہ وہ عمومی روش کے برخلاف ایک
                    مقدمہ قائم کیے ہوئے تھے۔ وہ دریا کے بہتے دھارے کی مخالف سمت
                    میں تیررہے تھے۔ اپنے رہن سہن اور طرزِ معاشرت میں،وہ روایتی
                    پختون تھے،۔ تاہم اپنے اندازِ فکر میں سب سے مختلف تھے۔اگر ہم
                    معاصر پختون سماج کا نظریاتی مطالعہ کریں تو ہمیں دو رحجانات
                    دکھائی دیتے ہیں۔ایک طرف وہ طبقہ ہے جس کی فکری تشکیل دو
                    اصولوں پر ہوئی ہے۔تصورِ قومیت اور سیکولرزم۔اے این پی کو ہم
                    اس کا نمائندہ قرار دے سکتے ہیں۔ دوسرا طبقہ وہ ہے جسے ہم
                    روایتی مذہبی گروہ کہتے ہیں۔ اس وقت طالبان بھی اسی کے نمائندہ
                    ہیں۔ اس رحجان کی بھی دونظری بنیادیں ہیں۔ تصورِ جہاد کا فہمِ
                    نو اور فقہ حنفی۔ ان کے علاوہ شاید ہم اس وقت کی پختون معاشرت
                    میں کوئی تیسرا زاویۂ نظرتلاش نہ کر سکیں۔ڈاکٹر صاحب ان دونوں
                    سے مختلف تھے۔ اقبال کے الفاط میں وہ اَبلۂ مسجد تھے نہ تہذہب
                    کے فرزند۔یہ معلوم ہے کہ وہ روایتی مذہبی فکر کے ناقد تھے۔
                    بایں ہمہ ،وہ سیکولرزم کے بھی خلاف تھے۔استادِ محترم جاوید
                    احمدصاحب غامدی سے ملاقات کے بعد، انھوں نے اس فہمِ دین کو
                    اپنا لیاتھا، جس نے امام حمید الدین فراہی کے نہاں خانۂ دل میں
                    جنم لیا،مولانا امین احسن اصلاحی نے جسے ایک دیدہ زیب عمارت
                    میں بدل دیا اور تیسرے مرحلے میں جو اﷲ کے فضل و کرم سے ایک
                    دبستان بن چکا ہے۔یہ خدا نخواستہ دینی روایت میں کوئی نئی بات
                    ہے نہ ہماری روایت سے متصادم ہے۔یہ تجدید واحیائے دین کے مسلسل
                    عمل کا اگلا مر حلہ ہے۔یہ دراصل امتِ مسلمہ کو فکری طور پراس
                    صدراوّل سے وابستہ کرنے کی ایک شعوری کوشش ہے جب فکر و نظر
                    پرقرآنِ مجید کی حاکمیت قائم تھی اور رسالت مآب صلی اللہ علیہ
                    وسلم کی سنت اور تشریح و وضاحت ہی فہمِ دین میں مستقل بالذات
                    ماخذ تھے۔ڈاکٹر صاحب اس روایت سے وابستہ ہوئے تو پھر یہیں کے
                    ہو رہے۔ ان کے مضطرب دل و جان کو یہیں قرار آ گیا:
                  </p>

                  <p>
                    <center></center>
                  </p>
                  <p>
                    <b></b>
                  </p>

                  <blockquote>
                    <p>
                      <b>
                        حزیں از پائے رہ پیمابسے سر گشتگی دیدم
                        <br />
                        سر شوریدہ بر بالین آسائش رسید ایں جا
                      </b>
                    </p>

                    <p>
                      <b> </b>
                    </p>
                  </blockquote>

                  <p></p>
                  <p>
                    پختون معاشرت میں یہ ایک منفرد آواز تھی۔تاہم وقت گزرنے کے
                    ساتھ ساتھ اس کی اجنبیت میں کمی آ رہی تھی۔یونیورسٹیوں، کالجوں
                    اور نئی نسل میں ان کے رازداں پیدا ہو چکے تھے اور ڈاکٹر صاحب
                    اس اطمینان کے ساتھ اس دنیا سے رخصت ہوئے ہیں کہ انھوں نے اپنے
                    سماج میں ایک تیسرے زاویۂ نظر کی بنیاد رکھ دی تھی۔
                    <br />
                    جو لوگ اس نظامِ فکر سے واقف ہیں، وہ جانتے ہیں کہ یہاں آخرت
                    کو مقصود و محور کی حیثیت حاصل ہے۔اس کے سوا کوئی دنیاوی
                    کامیابی ایسی نہیں جو مقصود بالذات ہو۔یہاں کامیابی یہ ہے کہ
                    آدمی اس احساس کو کبھی مرنے نہ دے کہ وہ اﷲ کا بندہ ہے اور اسے
                    اپنے پروردگار کے حضور پیش ہونا ہے۔ڈاکٹر صاحب کبھی اس سے غافل
                    نہیں رہے اور یہی ان کی کامیابی ہے۔اس حوالے سے ان کے ترجمۂ
                    قرآن کا ذکر اہم ہے۔ان کے پیش نظر ایک ایسا ترجمۂ قرآن تھا جس
                    میں اردو کے رائج تراجم کی خوبیوں اس طرح جمع ہو جا ئیں کہ ایک
                    عام پڑھا لکھا آدمی قرآن مجید کے ساتھ آسانی سے وابستہ ہو
                    جائے۔تاہم ،ان کا اصل مقصد کچھ اور تھا اور یہ اللہ تعالیٰ سے
                    مغفرت کی طلب تھی۔پانچ برس کی یہ محنت ۲۰۰۶ء کے رمضان المبارک
                    میں تمام ہو ئی تو اس کا اختتام انھوں نے ان الفاظ پر کیا:
                  </p>

                  <blockquote>
                    <p>
                      ’’اللہ کا لاکھ لاکھ شکر ہے کہ اس نے اپنے اس حقیر اور
                      عاجزبندے کوآسان ترین ترجمہ وتفسیرِقرآن مکمل کرنے کی تو فیق
                      عطا فرمائی۔اس کام پراس عاجز کے پانچ برس لگے۔یہ سراسر اللہ
                      تعالیٰ کا فضل و احسان ہے۔اس ترجمہ و تفسیر میں جو کچھ صحیح
                      ہے،وہ اللہ کی رہنمائی کی بدولت ہے اور جہاں کہیں مجھ سے
                      غلطی ہو ئی ہے،وہ میرے اپنے علم و فہم کاقصور ہے۔اللہ گواہ
                      ہے کہ میں نے یہ کام صرف اپنی مغفرت کے لیے کیا ہے۔ اس لیے
                      اللہ کی بے پایاں رحمت سے یہ امید رکھتا ہوں کہ اگر اس کام
                      میں مجھ سے کو ئی غلطی ہوئی ہے تو وہ اسے معاف کر دے گا۔‘‘
                    </p>
                  </blockquote>

                  <p>
                    ڈاکٹر فاروق صاحب کی شخصیت کو ہم نے ایک اور پہلو سے بھی
                    دیکھا۔سچ یہ ہے کہ انسانوں کو پرکھنے کا اصل معیار یہی ہے۔یہ
                    ہے انسانی اقدار سے وابستگی۔ فکر و نظر کی دنیا میں غلطی کا
                    امکان ہوتا ہے۔ دین کے فہم میں، یہ ممکن ہے کہ ہمارے نتائجِ
                    فکر میں صحت کا فقدان ہو۔ یہی وجہ ہے کہ ہمارے اسلاف اور رسوخ
                    فی العلم رکھنے والے کبھی اپنی بات کوواحد حق کے طور پر پیش
                    نہیں کرتے۔وہ اس کو اپنی رائے اور تفہیم کی حیثیت سے دنیا کے
                    سامنے رکھتے ہیں۔ لہٰذا ہر آدمی کے فہم میں صحت اور عدم صحت،
                    دونوں کا امکان ہمیشہ موجود دہوتا ہے۔تاہم انسانی اخلاقیات وہ
                    معاملہ ہے جس کی بنیاد مسلّمات پرہے۔دوسروں کے جان و مال اور
                    عزت و آبرو کی حرمت کے بارے میں دنیا کے مہذب معاشروں میں کبھی
                    دو آرا نہیں رہیں۔اسی طرح مسلمانوں کے مسالک میں بھی اخلاقیات
                    کا شمار متفق علیہ امور میں ہوتا ہے۔یہ وہ پیمانہ ہے جو سماج
                    میں کسی فرد کے کردار کا تعین کرتا ہے۔ ڈاکٹر صاحب ایسی شخصیت
                    تھے کہ جب ہم انھیں اس معیار پر پرکھتے ہیں تو معلوم ہوتا ہے
                    کہ وہ ایک بے مثال آدمی تھے۔سیدنا عمر فاروق رضی اللہ عنہ نے
                    معاملہ اور سفر کو رائے قائم کرنے کے لیے معیار بتایا ہے۔میں
                    نے ان کے ساتھ بارہا سفر کیا اور کئی طرح سے معاملہ بھی کیا۔
                    اس میں کوئی شک نہیں کہ وہ ایک کھرے اور صاف ستھرے آدمی
                    تھے۔اعلیٰ اخلاقی معیارات ان کی نظروں سے کبھی اوجھل نہیں ہوئے
                    ۔ان سے نظری اختلاف رکھنے والے بھی جانتے تھے کہ ان سے کبھی
                    ایسے جواب کی توقع نہیں کی جا سکتی تھی جو مسلّمہ اخلاقیات کے
                    خلاف ہو۔داعیانِ مذہب کے لیے اﷲ تعالیٰ کے پیغمبروں نے یہی سنت
                    قائم کی ہے کہ اس وادی میں قدم رکھنے والا یہ جان لے کہ اگر
                    اخلاق اور کردار کا اثاثہ اس کے پاس نہیں ہے تو وہ اس میدان کا
                    انتخاب نہ کرے۔ واقعہ یہ ہے کہ ڈاکٹر صاحب نے دین کی دعوت اور
                    معاشرے کی اصلاح کو اپنے لیے بطورمیدانِ عمل انتخاب کیا تو
                    انھوں نے اس کے تقاضے بھی نبھائے۔وہ اگر کسی دینی یا مذہبی
                    جماعت یا ادارے کی مالی معاونت کرتے تھے تو اس سلسلے کو اس کے
                    باوجود جاری رکھا کہ اُن جماعتوں اور اداروں کی طرف سے ان کے
                    بارے میں کبھی کلمۂ خیر نہیں کہا گیا۔ اپنے علم کی حد تک میں
                    اپنے معاصر معاشرے میں اس طرح کی کوئی دوسری مثال تلاش نہیں کر
                    پایا۔ یہ احسان کا درجہ ہے جس کی وہ لوگ تمنا کرتے ہیں جو اﷲ
                    کی رضا کے طالب ہوتے ہیں۔
                    <br />
                    واقعہ یہ ہے کہ ڈاکٹر صاحب کا وجود سراپا خیر تھا۔ شاید ان
                    طالب علموں کی تعداد سیکڑوں میں ہو، جن کی کفالت وہ بہت خاموشی
                    کے ساتھ کر رہے تھے۔کتنے مریض تھے، وہ جن کی نفسیاتی وجسمانی
                    امراض ہی کے معالج نہیں تھے، افلاس جیسی بیماری کا بھی علاج
                    تھے۔نیکی کے کتنے کام تھے جو ان کی مدد سے جاری تھے۔ سیلاب،
                    سوات کے آپریشن، کتنے مواقع ہیں جب وہ انفاق فی سبیل اﷲ کی
                    مجسم تصویر بن جاتے تھے۔ہم شاید یہ اندازہ نہ کرسکیں کہ ان کے
                    جانے سے،اس معاشرے سے کتنا بڑا خیر رخصت ہو گیا ہے۔کون ہے جسے
                    موت سے مفر ہے۔اللہ نے اپنے آخری رسول سے فرمایا: انک میت
                    وانھم میتون۔ آپ کو دنیا سے رخصت ہو نا ہے اور باقی آپ کے
                    مخاطبین کو بھی نہیں رہنا۔ صدق اللّٰہ العظیم ۔ سچ فرمایا اللہ
                    بزرگ وبرتر نے۔اگر عالم کا پر وردگار اپنے رسول سے یہ کہہ رہا
                    ہے تو ہما شما کس شمار میں۔ ان کا جانا کوئی غیر معمولی واقعہ
                    نہیں، لیکن کیا اس سماج کو خبر ہے کہ اس کا دامن کن نعمتوں سے
                    خالی ہو گیا؟یہ ایک فرد کی موت نہیں ہے، ایک علامت کا سماج سے
                    اٹھ جانا ہے۔انفاق فی سبیل اللہ، تحمل، بردباری، برداشت، محبت،
                    زندگی، امن،خیر خواہی جیسی ان گنت صفات اس ایک وجود میں سمٹ
                    آئی تھیں۔یہ اس خواہش کی موت ہے کہ انسانوں کی جان لینا صرف
                    اللہ کا حق ہے ۔اُس کے اذن کے بغیرلوگ نہ اپنی جان دیں نہ
                    دوسروں کی جان لیں۔
                    <br />
                    خسارے میں وہ نہیں، ہم ہیں، یہ معاشرہ ہے جو ایک بڑے خیر سے
                    محروم ہو گیا۔ڈاکٹر صاحب کو تو جانا ہی تھا اور اس سے بڑی خوش
                    بختی کیا ہے کہ وہ مجاہد فی سبیل اﷲ کی حیثیت سے رخصت ہوئے۔
                    کاش، ان کی موت کے آرزومندوں کو یہ سمجھایا جاسکتا کہ تمھیں جس
                    بات سے اختلاف تھا،وہ تو زندہ ہے، ان کی کتابوں میں ،خطبات میں
                    اور ان سے متاثر خلقِ خدا میں۔ اس بات کو موت صرف اس وقت آ
                    سکتی ہے جب کوئی اس سے بہتر بات لوگوں کے سامنے رکھے گا۔
                    بصورتِ دیگر ڈاکٹر فاروق خان کی موت ایک ایسا خواب ہے جس کی
                    کوئی تعبیر نہیں:
                  </p>

                  <p>
                    <b></b>
                  </p>

                  <p>
                    <center></center>
                  </p>
                  <blockquote>
                    <p>
                      <b>
                        فرشتہ موت کا چھوتا ہے گو بدن تیرا
                        <br />
                        تیرے وجود کے مرکز سے دور رہتا ہوں
                      </b>
                    </p>

                    <p>
                      <b>
                        <br />{" "}
                      </b>
                    </p>

                    <p>
                      <b>
                        <br />{" "}
                      </b>
                    </p>

                    <p>
                      <b> </b>
                    </p>
                  </blockquote>

                  <p></p>
                </div>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر فاروق خان کی شہادت"
              />

              <div className="content">
                <h2>ڈاکٹر فاروق خان کی شہادت</h2>
                اپنی سدا بہار مسکراہٹ کے ساتھ ڈاکٹر فاروق خان لحد میں اتر
                گئے۔پسِ مرگ اس لازوال تبسم کے گردآسودگی اور طمانیت کاہالہ
                تھا۔جیسے مو جوں سے الجھتے الجھتے کسی کو کنارہ مل جا ئے۔جیسے
                تاریک رات اور گھنے جنگلوں میں را ستہ ڈھونڈتے ڈھونڈتے کسی کا قدم
                اچانک ایک کشادہ شاہراہ پرآپڑے۔ایسے لمحوں میں جو اطمینان ایک راہ
                رو کو نصیب ہو تا ہے،وہی اطمینان ڈاکٹر فاروق خان کے چہرے کو اپنی
                گود میں لیے ہو ئے تھا۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
