import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const Navv = () => {
  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/" style={{ fontSize: "34px" }}>
          Dr. Farooq Khan
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="About" id="collasible-nav-dropdown">
              <NavDropdown.Item className="English" href="my-mission">
                My Mission{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="English"
                href="the-warrior-who-became-a-martyr"
              >
                The Warrior who became a Martyr{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="dr-farooq-khan-ki-shahadat"
              >
                ڈاکٹر فاروق خان کی شہادت{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="dr-farooq-khan-ki-shahadat-par-ghamidi-sb-ki-guftagu"
              >
                ڈاکٹر فاروق خان کی شہادت پرغامدی صاحب کی گفتگو{" "}
              </NavDropdown.Item>
              <NavDropdown.Item className="Urdu" href="een-aahe-jiger-sozay">
                ایں آہِ جگر سوزے در خلوت صحرا بہ{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="dr-muhammad-farooq-khan-ki-shahadat-eik-lamha-e-fikria"
              >
                ڈاکٹر محمد فاروق کی شہادت — ایک لمحۂ فکریہ{" "}
              </NavDropdown.Item>
              <NavDropdown.Item className="Urdu" href="dr-farooq-khan-shaheed">
                ڈاکٹر فاروق خان شہید{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="ab-is-ke-shehr-main-thehrain-ke"
              >
                اب اس کے شہر میں ٹھہریں کہ{" "}
              </NavDropdown.Item>
              <NavDropdown.Item className="Urdu" href="eik-shaheed">
                ایک شہید{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="rah-e-wafa-per-chalne-wale"
              >
                راہِ وفا پر چلنے والے{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="aaj-sooraj-jald-ghuroob-ho-gaya"
              >
                آج سورج جلد غروب ہو گیا؛{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="farooq-ki-taveel-awr-abdi-zindagi"
              >
                ’’فاروق‘‘ کی طویل اور ابدی زندگی{" "}
              </NavDropdown.Item>
              <NavDropdown.Item className="Urdu" href="eik-mujahid-ki-shahadat">
                ایک مجاہد کی شہادت{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="dr-muhammad-farooq-kuch-yaden-kuch-baten"
              >
                ڈاکٹر محمد فاروق — کچھ یادیں، کچھ باتیں{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="dr-muhammad-farooq-khan-eik-mard-e-mujahid"
              >
                ڈاکٹر محمد فاروق خان — ایک مرد مجاہد{" "}
              </NavDropdown.Item>
              <NavDropdown.Item className="Urdu" href="Tasuraat">
                تاثرات{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="dr-farooq-khan-aur-un-ki-kitab-ikisvin-sadi-aur-pakistan"
              >
                ڈاکٹر فاروق خان اور ان کی کتاب ’’اکیسویں صدی اور پاکستان‘‘{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-1"
              >
                فغانِ نیم شبی بے نوائے راز نہیں (حصہ اول){" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="Urdu"
                href="fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-2"
              >
                فغانِ نیم شبی بے نوائے راز نہیں (حصہ دوم){" "}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="https://javedahmedghamidi.org/#!/books?book=5a3584ab05ad589800bf7d3c"
              target="blank"
            >
              Books
            </Nav.Link>
            <Nav.Link
              href="https://javedahmedghamidi.org/#!/video?vidauth=5a3584ab05ad589800bf7d3c"
              target="blank"
            >
              Videos
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/contact_us">Contact us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navv;
