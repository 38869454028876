import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Abiskeshehr() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/ab-is-ke-shehr-main-thehrain-ke">
                اب اس کے شہر میں ٹھہریں کہ
              </a>
            </h1>
            <div className="row">
              <div className="col-md-12 pull-right">
                <p>سلیم صافی*</p>

                <p>
                  پچھلے دنوں بھائیوں جیسے دوست ڈاکٹر محمد فاروق خان کی جدائی نے
                  قلب کو اداس اور فکر کو تلپٹ کر کے رکھ دیا۔ لکھنے کی بہت کوشش
                  کی لیکن ذہن ساتھ دے رہا تھا نہ قلم۔ جس معاشرے میں دوست اور
                  دشمن، کھوٹے اور کھرے کی تمیز اٹھ جائے، وہاں کوئی لکھے تو کیا
                  لکھے اور بولے تو کیا بولے؟ وقاص خان اور اسامہ خان، جن کے باپ
                  ہزاروں یتیموں کا سہارا تھے، وہ خودیتیم کر دیے گئے۔ وہ شخص جس
                  نے کبھی دشمن سے بھی نفرت نہیں کی، نفرتوں کا نشانہ بن گیا۔ وہ
                  جو ہمہ وقت مسکراہٹیں بکھیرتا تھا، ہزاروں کے چہروں سے مسکراہٹ
                  کو غائب کرنے کا سبب بن گیا۔ وہ جو لاکھوں مجنونوں کی شفا کا
                  وسیلہ تھا، خود جنونیت کا شکار ہو گیا۔
                  <br />
                  بیس سال پہلے عبداللہ عزام کی کتاب ’’جنت تلواروں کے سائے میں‘‘
                  انھوں نے مجھے پڑھنے کو دی، لیکن آج ان کے قتل کی ذمہ داری
                  ’’عزام برگیڈ‘‘ نے قبول کر لی۔ شاید بن لادن کی محبت میں انھوں
                  نے اپنے بیٹے کا نام اسامہ خان رکھا، لیکن انھیں مجاہدین کا
                  مخالف مشہور کر کے مار دیا گیا۔ میں اسلام، پاکستان اور انسانیت
                  سے ان کی والہانہ محبت کا گرویدہ تھا، اکثر لوگ ان کی علمیت کے
                  اور میں ان کی انسانیت کا مداح تھا۔ بعض ان کی سیاست سے متفق
                  تھے، لیکن میں ان کی شرافت کا دیوانہ تھا۔ یہ طالب علم ہو، میجر
                  (ر) محمد عامر ہوں، ڈاکٹر حسن الامین ہوں، ڈاکٹر عامر عبداللہ ہو
                  یا فضل اللہ، سب کو ان کے ہر سیاسی اور عملی فیصلے سے شدید
                  اختلاف تھا۔ سب ہمہ وقت تنقید کرتے، طعنے دیتے تھے، بلکہ ڈانٹتے
                  تک تھے، لیکن سب سے ان کی محبت دیدنی تھی۔ ان کی زندگی انتقام کے
                  جذبے سے خالی تھی۔ حلقے کے ایک دوست ان کے بارے میں کہا کرتے تھے
                  کہ ڈاکٹر صاحب دو سو فی صد مسلمان ہیں، لیکن کاش دس فی صد پختون
                  بھی بن جائیں۔ رحلت سے چند روز قبل کی بات ہے۔ میرے ٹی وی
                  پروگرام ’’جرگہ‘‘ میں قاضی حسین احمد نے تشریف لانے کا وعدہ کیا
                  تھا، لیکن جب انھیں علم ہوا کہ شرکا میں ڈاکٹر فاروق خان بھی
                  شامل ہیں (ان کے جنازے کے لیے جمع ہونے والوں سے پہلے خطاب کا
                  اعزاز قاضی صاحب نے حاصل کیا) تو انھوں نے آنے سے معذرت کر دی،
                  لیکن جواب میں ڈاکٹر صاحب کا رویہ یہ تھا کہ جب سیلاب سے قاضی
                  حسین احمد کے گھر کے متاثر ہونے کا علم ہوا تویوں بے چین تھے کہ
                  جیسے سگے باپ کا گھر ڈوب گیا ہو۔ جماعت اسلامی کے اندرونی
                  اختلافات اور قاضی صاحب کے خلاف ایک معاملے میں جماعت کی نئی
                  قیادت کی انکوائری کی خبر ملی تو بھری محفل میں کہنے لگے کہ قاضی
                  حسین احمد جیسے دیانت دار شخص کے بارے میں اس طرح کا شک زیادتی
                  ہے۔ متحدہ مجلس عمل کی حکومت میں مولانا صوفی محمد جیل میں تھے۔
                  ذاتی تعلق، بڑھاپے اور علالت کی وجہ سے یہ طالب علم ان کی رہائی
                  کا مطالبہ کرتا رہا۔ اس کوشش پر بیش تر لوگ مجھ پر لعن طعن کرتے
                  رہے، لیکن پاکستان کے جس ایک فرد نے میرا ساتھ دیا، وہ ڈاکٹر
                  محمد فاروق خان تھے۔ سوات کے طالبان کے سربراہ مولانا فضل اللہ
                  کی والدہ اور بچوں کی رہائی کے لیے انھوں نے اتنی کوششیں کیں کہ
                  ان پر طالبان کا حامی ہونے کا شک کیا جانے لگا۔ ان کی تدفین سے
                  واپسی پر میری اہلیہ حیرت سے کہہ رہی تھی کہ ان کی اہلیہ اور
                  بچوں کا حوصلہ تو بحال تھا، لیکن سیکڑوں کی تعداد میں غریب اور
                  عمر رسیدہ خواتین ان کی لاش پر بے حال ہو رہی تھیں۔ جواباً عرض
                  کیا کہ ڈاکٹر وقاص یتیم ہوئے اور نہ انجنیئر اسامہ۔ عظیم باپ کے
                  زیرتربیت وہ کب کے اپنے پاؤں پر کھڑے ہو چکے ہیں۔ یتیم تو ان
                  سیکڑوں بیواؤں کے بچے بن گئے، جنھیں باپ بن کر ڈاکٹر فاروق خان
                  کھلا اور پڑھا رہے تھے۔ تبھی ڈاکٹر رضوانہ فاروق کی جگہ وہ ماتم
                  کر رہی تھیں۔
                  <br />
                  ان کی رحلت کا غم اس قدر تھا کہ زندگی کی کشش ہی ختم ہو کر رہ
                  گئی۔ ۴اکتوبر کو کابل میں پاک افغان ڈائیلاگ میں شرکت کا ارادہ
                  ہی ترک کر دیا، لیکن ان کے بھائی اور دیگر دوستوں کے اصرار پر
                  رسم قل کے بعد کابل روانہ ہوا، لیکن غم کابل میں بھی ساتھ نہیں
                  چھوڑ رہا تھا۔ کانفرنس کے دوران میں بھی ان کا تذکرہ رہا اور
                  وکیل احمد متوکل اور مولوی عبدالسلام ضعیف جیسے دوستوں کے ساتھ
                  ملاقاتوں میں بھی ان کی مغفرت کے لیے ہاتھ اٹھتے رہے۔ کابل کی
                  ایک محفل میں اے این پی کے صوبائی صدر افراسیاب خٹک نے جنرل (ر)
                  اسد درانی اور آفتاب شیر پاؤ کی موجودگی میں کہا کہ ہم ڈاکٹر
                  نجیب اللہ اور گلبدین حکمت یار کی صلح کرانے میں کامیاب ہو گئے
                  تھے۔ اس وقت کے افغان انٹیلی جنس کے سربراہ اور اس وقت کے ڈی جی
                  آئی ایس آئی کی کامیاب ملاقاتیں بھی ہو چکی تھیں، لیکن ایران اور
                  روس نے آخری وقت پر امن کی اس کوشش کو سبوتاژ کیا۔ حیرت انگیز
                  طور پر افراسیاب خٹک صاحب، حکمت یار کو اچھے الفاظ سے یاد کر رہے
                  تھے، جبکہ حزب اسلامی کے جس بھی بندے سے میں نے مذکور واقعے کا
                  ذکر کیا تو وہ کف افسوس ملتے ہوئے کم وبیش اسی رائے کااظہار کرتا
                  رہا۔ کابل ہی میں محمود خان اچکزئی، افغان حکومت کے عہدیدار اور
                  اس طالب علم نے کئی مرتبہ ملا محمد عمر کے سابق دست راست
                  عبدالسلام ضعیف کی اقتدا میں نمازیں ادا کیں۔ آخری روز باغ بابر
                  میں دیے گئے عشائیہ کے میزبان حامد کرزئی کے ہم قبیلہ حکمت کرزئی
                  تھے اور ان کے دائیں بائیں ملا عبدالسلام ضعیف اور مولوی وکیل
                  احمد متوکل بیٹھے ہوئے تھے۔ کابل میں یہ سب کچھ دیکھ کر میں
                  سوچتا رہا کہ نہ جانے ہم کیوں نہیں سمجھ پاتے کہ یہ نظریاتی اور
                  سیاسی اختلافات عارضی ہوا کرتے ہیں۔ ہم کیوں بھول جاتے ہیں کہ
                  نیتوں کا حال اللہ جانتا ہے اور ہو سکتا ہے کہ ہم جس کے موقف اور
                  حکمت عملی سے اختلاف کر رہے ہوں، وہ دیانت داری کے ساتھ اجتہادی
                  غلطی کا شکار ہو کر اپنے منتخب کردہ راستے کو اسلام، پاکستان اور
                  افغانستان کی بھلائی کا ذریعہ سمجھ رہا ہو۔ فقہی، مسلکی ، سیاسی
                  اور نظریاتی اختلافات کی بنا پر ہم اپنے ہم کلمہ بھائیوں کو قتل
                  کرنے والے یہ کیوں نہیں سوچتے کہ جس طرح آج حزب اسلامی کے لوگ
                  ڈاکٹر نجیب اللہ کو یاد کر رہے ہیں اور جس طرح ڈاکٹر نجیب اللہ
                  کے دست راست افراسیاب خٹک، آج گلبدین حکمت یار کی صلاحیتوں کا
                  اعتراف کر رہے ہیں، ممکن ہے کل اسی طرح کسی روز مولانا فضل اللہ،
                  ڈاکٹر محمد فاروق خان کو یاد کرتے رہیں اور اسفند یار ولی خان،
                  ملا محمد عمر کے خلوص کا اعتراف کرنے لگ جائیں۔ اگر آج حکمت
                  کرزئی اور مولوی وکیل احمد متوکل ایک میز پر بیٹھ سکتے ہیں تو کل
                  حکیم اللہ محسود اور اویس احمد غنی کیونکر ہم سفر بن جانے پر
                  مجبور نہیں ہو سکتے۔ نہ جانے ہم اکابر دیوبند، سید ابوالاعلیٰ
                  مودودی اور ڈاکٹر محمد فاروق خان کے نقش قدم پر چل کر شائستگی کے
                  ساتھ اختلاف کا اظہار کیوں نہیں کر سکتے؟ کیا یہ اچھا نہ ہو گا
                  کہ دلیل کی بنیاد پر بات کرنے والوں کا جواب فتوے یا گولی سے
                  دینے کے بجائے، دلیل سے دیا جائے۔ یاد رکھیے وہ جو جسموں سے بم
                  باندھ کر اپنی اور دوسروں کی زندگیوں کا خاتمہ کرتے ہیں اور وہ
                  جس نے ڈاکٹر محمد فاروق خان اور ان کے معاون پر گولی چلائی، اصل
                  قصور وار نہیں۔ وہ تو خطے میں جاری گیم کی نزاکتوں سے نابلد لوگ
                  ہیں۔ ان کے ذہنوں میں تو یہ بٹھا دیا گیا ہے کہ جس کو وہ مار رہے
                  ہیں، وہ اسلام کے دشمن ہیں۔ حقیقی قاتل وہ لوگ ہیں جو سیاسی اور
                  نظریاتی اختلافات کی بنیاد پر اپنے ہم کلمہ بھائیوں کے ایمان و
                  اخلاص کے بارے میں شکوک پھیلا کر انھیں کبھی امریکہ کا، کبھی
                  ہندوستان کا اور کبھی آئی ایس آئی کا ایجنٹ مشہور کراتے ہیں:
                </p>

                <blockquote>
                  <p>
                    <b>
                      اب اس کے شہر میں ٹھہریں کہ کوچ کر جائیں
                      <br />
                      فراز آؤ ستارے سفر کے دیکھتے ہیں
                    </b>
                  </p>
                </blockquote>

                <p>* کالم نگار روزنامہ ’’جنگ‘‘۔</p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="اب اس کے شہر میں ٹھہریں کہ"
              />

              <div className="content">
                <h2>اب اس کے شہر میں ٹھہریں کہ</h2>
                پچھلے دنوں بھائیوں جیسے دوست ڈاکٹر محمد فاروق خان کی جدائی نے
                قلب کو اداس اور فکر کو تلپٹ کر کے رکھ دیا۔ لکھنے کی بہت کوشش کی
                لیکن ذہن ساتھ دے رہا تھا نہ قلم۔ جس معاشرے میں دوست اور دشمن،
                کھوٹے اور کھرے کی تمیز اٹھ جائے، وہاں کوئی لکھے تو کیا لکھے اور
                بولے تو کیا بولے؟ وقاص خان اور اسامہ خان، جن کے باپ ہزاروں
                یتیموں کا سہارا تھے، وہ خودیتیم کر دیے گئے۔ وہ شخص جس نے کبھی
                دشمن سے بھی نفرت نہیں کی، نفرتوں کا نشانہ بن گیا۔ وہ جو ہمہ وقت
                مسکراہٹیں بکھیرتا تھا، ہزاروں کے چہروں سے مسکراہٹ کو غائب کرنے
                کا سبب بن گیا۔ وہ جو لاکھوں مجنونوں کی شفا کا وسیلہ تھا، خود
                جنونیت کا شکار ہو گیا۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
