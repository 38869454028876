import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Eikshaheed() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/eik-shaheed">ایک شہید</a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>طالب محسن</p>

                <p>
                  موت ایک اٹل حقیقت ہے ۔ یہ جملہ بہت با معنی ہے ۔ یہ زندگی کی سب
                  سے بڑی حقیقت ہے ۔ کوئی بڑی حقیقت جب الفاظ کا روپ دھار لیتی ہے
                  اور یہ الفاظ جب زبان پر رسمی جملے کی صورت میں جاری ہو جاتے ہیں
                  تو اپنی تاثیر سے محروم ہو جاتے ہیں ۔ اس جملے کے ساتھ بھی یہی
                  ہوا ہے ۔ لیکن بعض لوگوں کی زندگی سے معلوم ہوتا ہے کہ انھوں نے
                  اس بات کو سنا، دیکھا،سمجھا، مانا، اپنایا اور پوری زندگی کا
                  نقشہ اس حقیقت کے مطابق مرتب کر ڈالا۔
                  <br />
                  دین سے تعلق کی دو جہتیں ہیں ۔ ایک اس پر عمل اور ایک اس کی نصرت
                  ۔ یہ دونوں جہتیں جس کی زندگی میں جمع ہو جاتی ہیں، اس کے بارے
                  میں ہم پورے اعتماد سے کہہ سکتے ہیں کہ اس نے دین کو ماننے اور
                  اپنانے کا حق ادا کر دیا ہے۔ اس کا مطلب یہ ہے، اس نے نہ صرف
                  زندگی کے روز وشب دین کی تعلیمات کے مطابق کرلیے ہیں، بلکہ اپنی
                  مساعی کا ہدف بھی دین کی حفاظت اور فروغ کو بنا لیا ہے۔ ایسے لوگ
                  ہمیشہ ہی کم ہوتے ہیں اور ایسے لوگ اور بھی کم ہوتے ہیں جن کی
                  زندگی کا عنوان ہی دین سے یہ تعلق بن جائے ۔<br />
                  ڈاکٹر فاروق احمد خان ان خوش نصیبوں میں سے تھے جن پر یہ عنوان
                  ماتھے کے تاج کی طرح چمکتا تھا۔ دنیوی زندگی کی کامرانیاں قدم
                  چومتی تھیں، لیکن انھوں نے ان کے ہاتھ میں اپنا دامن دینے کے
                  بجائے اپنی زندگی کی باگ آخرت کی طرف موڑ رکھی تھی۔اس خادم دین
                  کے کئی رنگ تھے ۔ مصنف ، مقرر، داعی، مصلح، واعظ ،مکالمہ کار ،
                  دانش ور، سیاست کار اور مزید براں دکھی انسانوں کا خادم ۔ ان کے
                  کام کو دیکھ کر معلوم ہوتا ہے کہ بس لکھنے کی میز سے اٹھے تو
                  داعی کی کرسی پر بیٹھ گئے۔ وہاں سے اٹھے تو ملک وقوم یا انسانوں
                  کی خدمت کے کسی کام میں جت گئے ۔ اس قوم کی کتنی بد قسمتی ہے کہ
                  اس نے ایک ایسے انسان کو زندگی سے محروم کردیا جو خود اس کی صلاح
                  و فلاح کی ضمانت تھا۔ جس کی زندگی خود اس کی زندگی تھی۔قومیں
                  شاہراہ حیات پر اعلیٰ درجے کے انسانوں کے بل پر منزلیں مارتی ہیں
                  اور اگر خدا انھیں ان سے محروم کردے تو اس کا مطلب یہ ہے کہ وہ
                  مرحلۂ ابتلا میں ہیں۔ہم ابتلا میں ہیں، یہ بات تو ہر عنوان سے
                  واضح ہے ۔ فاروق خان کی شہادت سے معلوم ہوتا ہے کہ یہ ابتلا ختم
                  ہونے کے آثار بھی مٹ رہے ہیں۔
                  <br />
                  جب فاروق خان کے قتل کی خبر ملی تو دل میں بلا تردد اور بلا توقف
                  یہ خیال آیا کہ وہ شہید ہیں۔ اس لیے کہ ان کی زندگی دین کے نام
                  تھی ۔ اس لیے کہ ان کی موت دین کی خاطر تھی۔
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ایک شہید"
              />

              <div className="content">
                <h2>ایک شہید</h2>
                موت ایک اٹل حقیقت ہے ۔ یہ جملہ بہت با معنی ہے ۔ یہ زندگی کی سب
                سے بڑی حقیقت ہے ۔ کوئی بڑی حقیقت جب الفاظ کا روپ دھار لیتی ہے
                اور یہ الفاظ جب زبان پر رسمی جملے کی صورت میں جاری ہو جاتے ہیں
                تو اپنی تاثیر سے محروم ہو جاتے ہیں ۔ اس جملے کے ساتھ بھی یہی ہوا
                ہے ۔ لیکن بعض لوگوں کی زندگی سے معلوم ہوتا ہے کہ انھوں نے اس بات
                کو سنا، دیکھا،سمجھا، مانا، اپنایا اور پوری زندگی کا نقشہ اس
                حقیقت کے مطابق مرتب کر ڈالا۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
