import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Ghamidisbkiguftagu() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-farooq-khan-ki-shahadat-par-ghamidi-sb-ki-guftagu">
                ڈاکٹر فاروق خان کی شہادت پرغامدی صاحب کی گفتگو
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <div className="urdu article-body">
                  <p>
                    مکالمہ: عمران یوسف
                    <br />
                    ترتیب: منظور الحسن
                  </p>

                  <p></p>

                  <p></p>

                  <blockquote>
                    <p>
                      <center></center>
                    </p>
                    <p>
                      [یہ جناب جاوید احمد غامدی کی گفتگوہے جو انھوں نے ڈاکٹر
                      محمد فاروق خان کی شہادت کے موقع پرwww.al-mawrid.tv کے
                      نمائندے
                      <br />
                      جناب عمران یوسف کو انٹرویو دیتے ہوئے کی۔ گفتگو کے تسلسل کو
                      ملحوظ رکھتے ہوئے سوالات کو حذف کر دیا گیا ہے۔ مرتب]
                    </p>

                    <p></p>
                  </blockquote>

                  <p>
                    ڈاکٹر محمد فاروق خان میرے عزیز بھائی تھے، میرے دوست تھے ،
                    ہمدم دیرینہ تھے ۔ لوگ ان کے علم و فضل کا ذکر کریں گے، ان کی
                    تصنیفات کا ذکر کریں گے، ان کی خطابت کا ذکر کریں گے، ان کی
                    دینی خدمات کا ذکر کریں گے، انھوں نے اپنے صوبے میں تعلیم کے
                    لیے جو خدمات انجام دیں ،ان کا ذکر کریں گے اس میں شبہ نہیں کہ
                    ان سب باتوں میں وہ ہمارے ممدوح تھے لیکن جس چیز نے مجھے ان کی
                    محبت میں ہمیشہ گرفتار کیے رکھا ،وہ ان کی انسانیت تھی ۔ وہ
                    کمال انسانیت کا بہترین نمونہ تھے۔ ان کو جس شخص نے بھی قریب
                    سے دیکھا، وہ یہ جانتا ہے کہ ان کا حلم ، ان کی متانت، ان کی
                    انسان دوستی، ان کے چہرے پر ہر لحظہ کھلتا ہوا تبسم، یہ سب ان
                    کی شخصیت کا ناگزیر حصہ بن چکا تھا۔ میری ان کے ساتھ کم و بیش
                    بیس سال کی رفاقت تھی۔ اس عرصے کے دوران میں مجھے ایک دفعہ بھی
                    یاد نہیں ہے کہ کبھی ان سے کوئی شکایت پیدا ہوئی ہویا ان کے
                    رویے میں کوئی چیز انسانیت کے اعلیٰ مدارج کے خلاف محسوس کی
                    ہو۔ وہ ہمیشہ اپنے معیارات کو قائم رکھتے تھے ۔گفتگو میں،
                    معاملات میں ، اخلاقیات میں وہ بڑی بلند پایہ شخصیت تھے او
                    رمیں سمجھتا ہوں کہ ان کا اصلی حسن یہی تھا ۔ وہ ہمیشہ امن کے
                    پیغام بر رہے اور عمر بھر امن و سلامتی ہی کا درس دیتے رہے۔
                    <br />
                    ہمارا فکر جیسا کچھ بھی ہے، اس کو اپنے علاقے میں عام کرنے میں
                    ان کا غیر معمولی کردار رہا۔ انھوں نے اس کی شرح میں کتابیں
                    لکھیں، انھوں نے اس کی وضاحت کے لیے تقریریں کیں ، وہ
                    سیمیناروں میں گئے ،وہ مجالس میں گئے اور پورے زور کے ساتھ،
                    پورے اخلاص کے ساتھ اور پوری عزیمت کے ساتھ اس کی دعوت کو عام
                    کرتے رہے۔ وہ دعوت حق کے شہید ہیں ۔ انھوں نے اسی راہ میں اپنی
                    جان قربان کی ہے ۔ اس موقع پر جبکہ وہ ہمیں چھوڑ کر چلے گئے
                    ہیں، آپ جانتے ہیں کہ میرے جیسے شخص کے جذبات کیا ہو سکتے ہیں۔
                    سچ تو یہ ہے کہ وہ اپنے ایک بھائی سے محروم ہو گیا ہے، اپنے
                    ایک ساتھی سے محروم ہو گیا ہے، اپنے ایک دوست سے محروم ہو گیا
                    ہے.....شہادت کاجو عظیم مرتبہ انھیں ملا ہے ، میں جب اس کا
                    تصور کرتا ہوں تو بس یہی کہتا ہوں کہ :
                  </p>

                  <p>
                    <b></b>
                  </p>

                  <blockquote>
                    <p>
                      <center></center>
                    </p>
                    <p>
                      <b>حسرت آتی ہے کہ افسوس یہ میں کیوں نہ ہوا</b>
                    </p>

                    <p></p>
                    <p>
                      <b> </b>
                    </p>
                  </blockquote>

                  <p>
                    قدرت جب اپنی محبوب شخصیات کو تخلیق کرتی ہے تو بعض غیر معمولی
                    چیزیں ان کے اندر ودیعت کر دیتی ہے۔ حلم، متانت، شایستگی،
                    تہذیب ، نرم خوئی، یہی ان کا کمال تھا۔ اللہ تعالیٰ نے انھیں
                    بنایا ہی ایسا تھا۔ یہ چیز ان کے خمیر میں ڈال دی گئی تھی ۔
                    حقیقت تو یہ ہے کہ میرے ذہن میں جب ان کی تصویر آتی ہے ، ان کے
                    تبسم کے ساتھ آتی ہے ۔ ان کو کبھی غصہ نہیں آتا تھا ۔ وہ
                    ’’المورد‘‘ کے بورڈ آف ڈائریکٹرز کے رکن رہے ۔اس کے اجلاسوں
                    میں ظاہر ہے کہ اختلافات بھی ہو جاتے تھے اوردوسروں سے شکایت
                    کا موقع بھی پیدا ہو جاتا تھا، لیکن وہ ہمیشہ صلح جو رہتے
                    تھے۔ان کے قلم سے، ان کی زبان سے کبھی کسی کو دشنام سننے کا
                    موقع نہیں ملا۔ دوسروں کے ساتھ تعلق خاطر میں ان کے ہاں نشیب و
                    فراز نہیں آتے تھے ۔ تعلقات کی جو سطح ایک مرتبہ طے ہو گئی ،وہ
                    اس میں کوئی کمی نہیں آنے دیتے تھے، ہمیشہ اس کی پاس داری کرتے
                    تھے ۔ ان کی نگاہ مخاطب کے اخلاص پر ہوتی تھی اور اس کو ہمیشہ
                    ملحوظ رکھتے تھے۔ اپنے ہوں یا غیر ، وہ ہمیشہ بڑی تہذیب اور
                    شایستگی کے ساتھ بات کرتے تھے ۔ ان کی طبیعت میںیہ نرمی فطری
                    طور پر تھی ، پھر جس فکر کے وہ علمبردار بن کر کھڑے ہوئے ،وہ
                    مبنی ہی اس چیز پر ہے کہ نہ ماننے والوں کے ساتھ ہمارا رشتہ
                    دعوت کا ہے؛ ہمارا ہتھیار صرف استدلال ہے؛ ہمیں اپنی بات دلیل
                    کے ساتھ بیان کرنی ہے۔ جب آپ اس مقام پر کھڑے ہو جاتے ہیں تو
                    یہ ممکن ہی نہیں ہے کہ آپ کے اندر سختی در آئے ،آپ کسی کے ساتھ
                    کوئی زیادتی کریں یا آپ کسی کے اخلاص پر حملہ کریں۔ آپ اپنی
                    دلیل پیش کریں گے اور خود بھی اس مقام پر کھڑے ہوں گے کہ ہو
                    سکتا ہے کہ ہماری بات میں غلطی ہو اور دوسرا اگراس کو واضح
                    کردے گا تو ہم اس کو قبول کر لیں گے ۔ اس معاملے میں ڈاکٹر
                    صاحب کے طرز عمل کو دیکھ کر اکثریہ محسوس ہوا کہ گویاوہ اپنے
                    پیغمبرصلی اللہ علیہ وسلم کے اسوہ کو سامنے رکھ کر عمل کر رہے
                    ہیں۔خدا نے اپنے آخری پیغمبر کی فطرت یہی بنائی تھی ، آپ نخل
                    فطرت کے بہترین ثمر تھے۔ آپ کی کریم النفسی پر اللہ تعالیٰ نے
                    قرآن میں جگہ جگہ آپ کو خراج تحسین پیش کیا ہے۔ آپ کے لیے رؤفٌ
                    رحیمٌکے الفاظ استعمال کیے ہیں اورفرمایا ہے کہ ’ولَو کُنْتَ
                    فَظًّا غَلِیْظَ القَلْبِ لَانْفَضُّوْا حَوْلِکَ‘ یعنی اے
                    پیغمبر اگر آپ درشت خو اور سنگ دل ہوتے تو یہ سب لوگ تمھارے
                    پاس سے منتشر ہو جاتے۔
                    <br />
                    ڈاکٹر فاروق خان آج سے بیس سال پہلے میرے پاس تشریف لائے تھے ۔
                    ان کی اہلیہ ’’اشراق‘‘ کی قاری تھیں ۔ انھوں نے میرے نام ایک
                    خط لکھا اور کہا کہ میرے شوہر نے ’’پاکستان کا مستقبل‘‘ کے
                    عنوان سے ایک کتاب لکھی ہے۔ ان کے سامنے مذہبی لحاظ سے کچھ
                    الجھنیں ہیں ، جن پر گفتگو کے لیے وہ آپ کے پاس آنا چاہتے ہیں
                    ۔ چنانچہ انھی کا خط لے کر و ہ میرے پاس تشریف لائے اور کئی دن
                    تک قیام پذیر رہے۔ اس دوران میں میرے اور ان کے درمیان بہت سے
                    موضوعات پر گفتگو ہوئی ۔ اس میں اختلاف بھی ہوا اور اتفاق بھی۔
                    بحث و نظر اور اتفاق و اختلاف کا یہ سلسلہ ان کی شہادت تک جاری
                    رہا۔ فی الجملہ میں یہ کہ سکتا ہوں کہ انھوں نے بڑی حد تک میرے
                    فکر کو سمجھ لیا تھا اوروہ اسی کے علم بردار تھے۔ بعض تعبیرات
                    میں اختلاف ہو سکتا ہے، کیونکہ وہ خود ایک صاحب فکر آدمی تھے ۔
                    جس طرح مجھے بھی اپنے اساتذہ سے اختلاف ہو جاتا ہے، انھیں بھی
                    مجھ سے بعض چیزوں میں اختلاف رہتا تھا۔ پھر بہت سی چیزیں انھوں
                    نے اس زمانے میں لکھیں،جب ابھی میں نے اپنی کتاب ’’ میزان‘‘ کو
                    مکمل نہیں کیا تھا۔ا س لیے ہو سکتا ہے کہ بعض معاملات میں
                    لوگوں کو میرے اور ان کے زاویۂ نظرمیں فرق نظر آئے۔تاہم ،
                    حقیقت یہ ہے کہ بحیثیت مجموعی وہ اِسی فکر کو لے کر اٹھے، اِسی
                    کے لیے لوگوں کے سامنے شہادت دیتے رہے اور اِسی کے لیے انھوں
                    نے اپنی جان اللہ کی راہ میں پیش کر دی۔ ان کے جانے سے میں
                    سمجھتا ہوں کہ میری قوم ایک بڑے آدمی سے محروم ہو گئی ہے۔ یہ
                    کسی قوم کی بڑی محرومی ہوتی ہے کہ وہ اس طرح کی شخصیات کو کھو
                    بیٹھے۔ عربی زبان کا ایک مصرع ہے کہ:
                  </p>

                  <blockquote>
                    <p>
                      <center></center>
                    </p>
                    <p>
                      <b>أَضَاعُونِی وَأَیَّ فَتًی أَضَاعُوا!</b>
                      <br />
                      ’’انھوں نے مجھے کھو دیا، دیکھو کیسے نوجوان کو کھو دیا!‘‘
                    </p>

                    <p></p>
                  </blockquote>

                  <p>
                    حقیقت یہ ہے کہ بڑا نقصان ہوا ہے ، لیکن اس قوم کو شاید اس کا
                    احساس ہی نہیں ہے۔ اس کی وجہ یہ ہے کہ یہ ابھی اپنے بلوغ کے اس
                    مقام تک نہیں پہنچی کہ اپنی اصل خیر خواہ شخصیتوں کو پہچان سکے
                    ۔<br />
                    ڈاکٹر محمد فاروق خان کی جدوجہد اصل میں انفرادی جدوجہد تھی۔
                    یہ میری جو کچھ تھوڑی بہت کاوش ہے یا مجھ سے پہلے بعض بزرگوں
                    کی کاوشیں ہیں،یہ بھی انفرادی ہیں ۔ اس جدوجہدکو جب تک ایک
                    موومنٹ، ایک تحریک کی صورت نہیں دی جاتی ، اس وقت تک اسلام کو
                    اور مسلمانوں کو دنیا کے سامنے دعوت کے صحیح مقام پر کھڑا نہیں
                    کیا جا سکتا۔ لہٰذااس وقت اس امر کی ضرورت ہے کہ عالم اسلام
                    میں تجدید و احیا کی ایک بڑی تحریک برپا کی جائے۔ Reformation
                    of Muslim religious thought کی تحریک۔ مسلمانوں کے مذہبی فکر
                    کی تشکیل جدیدکی تحریک ۔مسلمانوں کے مذہبی فکر میں ایسا تعفن
                    پیدا ہو چکا ہے اور اس کے اندر ایسا جمود در آیا ہے کہ جب تک
                    اس کو ختم نہیں کر دیا جائے گا اور جب تک اس کو توڑ نہیں
                    دیاجائے گا اور جب تک مسلمانوں کے مذہبی فکر کو قرن اول کی
                    تعبیر اسلام کی روشنی میں جانچ کر، پرکھ کر دوبارہ ایک نئی
                    صورت میں تشکیل نہیں دیا جائے گا، اس وقت تک ہم درپیش صورت حال
                    کا مقابلہ کر نہیں سکتے۔
                    <br />
                    ڈاکٹر صاحب کا جانا بلاشبہ، ایک بڑا نقصان ہے، لیکن یہ خدا کے
                    کام ہیں ۔ سیدنا مسیح علیہ السلام نے فرمایا تھا کہ: کوئی شخص
                    بھی ناگزیر نہیں ہے ۔ اللہ کے لیے کیا مشکل ہے کہ وہ پتھروں کے
                    نیچے سے ابراہیم پیدا کر دے۔ دین اُس کا ہے ، دعوت اُس کی ہے،
                    پیغام اُس کا ہے۔ ہم تو بس خدام ہیں۔ ہم میں سے کوئی بھی
                    ناگزیر نہیں ہے۔ ہم چلے جائیں گے تو اللہ تعالیٰ اپنے دین کے
                    دوسرے خدام پیدا کر دے گا۔ ہمیں جو موقع ملا ہے، اس میں ہمارا
                    کام فقط یہ ہے کہ دین کی خدمت کا فریضہ انجام دیتے رہیں۔ اللہ
                    کا دین ہم پر نہیں کھڑا ،اس کا دین اس کی اپنی حکمت پر قائم ہے
                    اور وہ اس کے اسباب پیدا کرتا رہتا ہے ۔ہم دین کے خادم ہیں ،
                    دین کے محسن نہیں ہیں ۔ ڈاکٹر فاروق خان بھی دین کے خدام میں
                    سے ایک خادم تھے ۔ اللہ سے دعا ہے کہ وہ ان کی خدمت کو قبول
                    فرمائے۔
                    <br />
                    جہاں تک ان کی شہادت کی ذمہ داری قبول کرنے والوں کا تعلق ہے
                    تو ان کی خدمت میں میں صرف یہی عرض کروں گا کہ وہ اپنے آپ کو
                    داروغہ نہ سمجھیں ، اپنے آپ کو داعی بنائیں۔ دوسروں کی بات بھی
                    تحمل کے ساتھ سنیں ۔ استدلال کے ہتھیار کے ساتھ سامنے آئیں ۔
                    یہ ظلم جو انھوں نے کیا ہے اور اس طرح کے مظالم جو اس سے پہلے
                    وہ کرتے رہے ہیں ، انھیں دیکھ کر مجھے اندیشہ ہے کہ خدا کی
                    بارگاہ میں کہیں ان سے بھی وہی سوال نہ کیا جائے:
                  </p>

                  <blockquote>
                    <p>
                      <b>
                        وَاِذَا الْمَوْءُ دَۃُ سُءِلَتْ بِاَیِّ ذَنْبٍ قُتِلَتْ؟
                        (التکویر۸۱:۸۔۹)
                      </b>
                      <br />
                      ’’اور جب اُس سے جو زندہ گاڑ دی گئی، پوچھا جائے گا کہ وہ کس
                      گناہ پر ماری گئی؟‘‘
                    </p>
                  </blockquote>

                  <p></p>

                  <p></p>
                </div>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر فاروق خان کی شہادت پرغامدی صاحب کی گفتگو"
              />

              <div className="content">
                <h2>ڈاکٹر فاروق خان کی شہادت پرغامدی صاحب کی گفتگو</h2>
                یہ جناب جاوید احمد غامدی کی گفتگوہے جو انھوں نے ڈاکٹر محمد فاروق
                خان کی شہادت کے موقع پرwww.al-mawrid.tv کے نمائندے جناب عمران
                یوسف کو انٹرویو دیتے ہوئے کی۔ گفتگو کے تسلسل کو ملحوظ رکھتے ہوئے
                سوالات کو حذف کر دیا گیا ہے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
