import React from "react";
import Contactimg from "../../images/ui/contactus-icon.jpg";

export default function Contactus() {
  return (
    <div>
      <div class="page-banner">
        <div class="container text-center">
          <h1>CONTACT US</h1>
        </div>
      </div>

      <div class="container white-box">
        <article class="row">
          <div class="col-md-4">
            <div class="widget">
              <img
                class="img-responsive"
                src={Contactimg}
                alt="Contact us | Dr. Muhammad Farooq Khan"
                style={{ marginTop: "60px", marginBottom: "50px" }}
              />
            </div>
          </div>
          <div class="col-md-8">
            <h1 class="title">Contact Us</h1>
            <hr />
            <hr />
            <div class="row">
              <div class="col-md-11" style={{ textAlign: "center" }}>
                Please write to us at
                <br />
                <a href="mailto:webmaster@drfarooqkhan.org">
                  webmaster@drfarooqkhan.org
                </a>
              </div>
            </div>
            <br />
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
