import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Fughaneneemshabibenawaeraznahipart1() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-1">
                فغانِ نیم شبی بے نوائے راز نہیں (حصہ اول)
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>انتخاب: خورشید احمد ندیم</p>

                <p>مرتب: عقیل احمد انجم</p>

                <blockquote>
                  <p>[ڈاکٹر محمد فاروق خان شہید کی تصانیف سے چند اقتباسات]</p>
                </blockquote>

                <blockquote>
                  <p>
                    (ڈاکٹر فاروق خان شہید نے جن مو ضوعات پر قلم اٹھایا ،اُن میں
                    سر فہرت وہ تہذیبی،سماجی اور سیاسی مسائل ہیں،جن کا معاصر
                    مسلمان معاشروں کو سامنا ہے۔ان مو ضوعات پر کلام کرتے وقت،ان
                    کا یہ امتیاز نمایاں رہا کہ وہ فکرِ اسلامی کی روح کو سامنے
                    رکھتے ہوئے،ان مسائل کا ایسا حل تجویز کرتے تھے جو زمینی حقائق
                    سے ہم آ ہنگ ہو نے کے سبب ،قابلِ عمل ہوتاتھا۔بایں ہمہ وہ اپنی
                    بات کو اتنی سادگی کے ساتھ بیان کرنے پر قادر تھے کہ دقیق
                    معاملات بھی ایک عام آ دمی کے لیے قابلِ فہم ہو جاتے۔ اس پہ
                    مستزاد مسلمانوں کے لیے ان کی خیر خواہی اور محبت تھی جو ان کی
                    ہر سطر سے نمایاں ہو تی تھی۔ان کی تنقید اصلاح کے متمنی ایک
                    ایسے شخص کی رائے تھی جواپنے بھائیوں کے زیاں پر دل برداشتہ
                    اور انہیں ہر طرح کے نقصان سے بچا نے کے لیے بے تاب ہے۔ سرسری
                    نظر کے ساتھ ان کی تصانیف سے کیا گیا یہ انتخاب ڈاکٹر فاروق
                    صاحب کے اسلوبِ تحریر کی ان خوبیوں پر بطور شہادت پیش کیا جا
                    سکتا ہے۔اللہ تعالیٰ شہید کی ان کو ششوں کو قبول فرمائے اور ان
                    کی تحریریں اُسی احساس کے ساتھ پڑھی جاتی رہیں جس کے تحت یہ
                    لکھی گئیں۔)
                  </p>
                </blockquote>

                <blockquote>
                  <p>
                    <b>
                      اگر ہو ذوق تو خلوت میں پڑھ زبورِ عجم
                      <br />
                      فغانِ نیم شبی بے نوائے راز نہیں
                    </b>
                  </p>
                </blockquote>

                <p>
                  جب ایک فرد کے لیے حالات ناسازگار ہوں تو دل چھوٹا نہ کیا جائے،
                  اس بات پر یقین رکھا جائے کہ پروردگار تمام حالات کو دیکھ رہا ہے
                  اور وہ جب چاہے گا حالات بدل دے گا۔ چنانچہ ٹھنڈے دل و دماغ سے
                  کام لیا جائے۔ مایوسی اور گھبراہٹ سے بچا جائے۔ حالات کا اچھی
                  طرح جائزہ لے کر، تدبیر کے ساتھ، بہترین پر امن حکمت عملی بنا کر
                  اس پر عمل کیا جائے۔ اور اس عمل پر ثابت قدم رہا جائے۔
                  <br />
                  اسی طرح جب ایک مسلمان قوم و ملک کے لیے حالات ناسازگار ہوں،
                  مشکلات زیادہ ہوں اور ہر طرف سے خطرے امڈے چلے آرہے ہوں، تو صبر
                  کا تقاضا ہے کہ ٹھنڈے دل و دماغ کے ساتھ انتظار کیا جائے، مستقبل
                  کے لیے منصوبہ بندی اور تیاری کی جائے، اشتعال، فوری ردعمل اور
                  الٹے سیدھے اقدامات سے گریز کیا جائے، پوری پوری دنیوی تدبیر کی
                  جائے۔ یہ طرز عمل اختیار کیا جائے کہ ہمیں بہترین دنیوی حکمت
                  عملی کے مطابق کام کرنا ہے اور اللہ سب کچھ دیکھ رہا ہے۔ تیاری
                  کے اس مرحلے میں ثابت قدمی ہی صبر ہے۔
                  <br />
                  اس کے برعکس ایک فرد یا ایک قوم کی طرف سے فوری ردعمل، جلد بازی،
                  معروضی تجزیے کے فقدان، تیاری کے بغیر اقدام، اور حکمت عملی میں
                  عدم استقلال کو بے صبری کہا جائے گا۔
                  <br />
                  پچھلے دو سو برس کے دوران میں مسلمان قوم کی سب سے بڑی کمزوری
                  اور خامی بے صبری ہی رہی ہے اور آج بھی اس امت کے رہنما اس کو بے
                  صبری ہی کا سبق پڑھا رہے ہیں۔ بلکہ ان کی نظر میں صبر کی تلقین
                  کرنے والے دراصل بزدل ہیں۔
                  <br />
                  تمام پیغمبروں اور ان کے ساتھیوں کی داستان دراصل صبر ہی کی
                  داستان ہے۔ وہ اپنے کام میں لگے رہے۔ انھوں نے نتائج کے حصول کے
                  لیے جلدی نہیں کی۔ حضرت نوحؑ اور ان کے متبعین سینکڑوں برس تک
                  اپنی دعوت دیتے رہے، اور مخالفین کے ظلم سہتے رہے، مگر انھوں نے
                  جوابی اقدام کبھی نہیں کیا۔ صرف ایک پیغمبر حضرت یونسؑ سے جانب
                  حق جب بے صبری کا ظہور ہوا تو ان کی داستان قرآن مجید نے ہمیشہ
                  کے لیے محفوظ کردی۔ ارشاد ہے:
                </p>

                <blockquote>
                  <p>
                    <b>
                      فَاصْبِرْ لِحُکْمِ رَبِّکَ وَلَا تَکُنْ کَصَاحِبِ
                      الْحُوْتِ اِذْ نَادٰی وَھُوَ مَکْظُوْمٌ. لَوْلَآ اَنْ
                      تَدٰرَکَہٗ نِعْمَۃٌ مِّنْ رَّبِّہٖ لَنُبِذَ بِالْعَرَآءِ
                      وَھُوَ مَذْمُوْمٌ.(سورۂ قلم ۶۸: ۴۸۔۴۹)
                    </b>
                  </p>

                  <p>
                    ’’اس لیے تم اپنے پروردگار کے حکم کے انتظار میں صبر کرتے رہو
                    اور اُس مچھلی والے (یونسؑ ) کی طرح نہ ہو جاؤ، جب اُس نے
                    (مچھلی کے پیٹ میں) پکارا اور وہ غم سے بھرا ہوا تھا۔ اگر اُس
                    کے پروردگار کی مہربانی اُس کے شامل حال نہ ہو جاتی تو (اپنی
                    اس جلدی کے نتیجے میں) وہ اُسی چٹیل میدان میں اِس طرح پھینک
                    دیا جاتا کہ وہ لائق مذمت ہوتا۔‘‘
                  </p>
                </blockquote>

                <p>
                  تمام پیغمبروں کی طرح حضورﷺ نے بھی ہمیشہ صبر ہی سے کام لیا۔ مکی
                  دور کے تیرہ سال میں آپؐ نے اپنے اوپر اور اپنے صحابہؓ کے اوپر
                  کیے جانے والے کسی ظلم کا جواب نہیں دیا۔ بلکہ ہر زیادتی کے
                  مقابلے میں آپؐ اپنے ساتھیوں کو صبرو استقامت ہی کی تلقین کرتے
                  رہے۔ اگرچہ اس وقت یعنی مکی دور میں بھی مسلمانوں کے اندر ایمانی
                  قوت اور صبر کا مادہ اتنا زیادہ تھا کہ خود قرآن مجید کے مطابق
                  مسلمان اپنے سے دس گنا بڑے لشکر کا مقابلہ کر سکتے تھے۔ دور
                  مدینہ میں جب ایک اسلامی ریاست وجود میں آگئی، تب کہیں جا کر
                  مسلمانوں کو جنگ کی اجازت دی گئی۔
                  <br />
                  جنگ بدر میں دشمن کی تعداد اور اسلحہ مسلمانوں سے تین گنا زیادہ
                  تھا۔ لیکن مسلمانوں نے حضورؐ کی سرکردگی میں ٹھنڈے دل و دماغ سے
                  جنگ کی منصوبہ بندی کی۔ جوش کے مقابلے میں ہوش سے کام لیا اور
                  بہترین جنگی حکمت عملی اپنائی۔ اس کے برعکس دشمن کے ہاں نااتفاقی
                  تھی۔ وہ ایک لیڈر تلے متحد نہیں تھے۔ ان کے ہاں جنگ کے مخالفین
                  بھی تھے۔ ان کے اقدامات بے ترتیبی اور جلد بازی کا مظہر تھے۔
                  چنانچہ اللہ کی رحمت سے مسلمان فتح یاب رہے۔
                  <br />
                  جنگ احد میں مسلمان نسبتاً بہتر پوزیشن میں تھے۔ حضورؐ نے بہترین
                  منصوبہ بندی کی تھی۔ اسی لیے ابتداء میں مسلمانوں کو فتح ملی۔
                  لیکن قرآن مجید کے الفاظ میں جب کچھ مسلمانوں نے بے صبری دکھائی،
                  آپس میں اختلاف کرنے لگے، اور محض مال غنیمت کی محبت کی وجہ سے
                  انھوں نے اپنے کمانڈر کے حکم کی خلاف ورزی تک کر ڈالی، تو ان میں
                  پھوٹ پڑ گئی۔ یہی وجہ ہے کہ پروردگار نے ان کی فتح کو شکست میں
                  تبدیل کر دیا۔ (سورۂ آل عمران ۳: ۱۴۴۔۱۵۲)
                  <br />
                  اس کے بعد جنگ خندق (غزوۂ احزاب) میں دشمن کے لشکر کی تعداد دس
                  ہزار سے زیادہ تھی۔ وہ متحد تھے اور ان کے پاس اسلحے کی تعداد
                  مسلمانوں سے بہت زیادہ تھی۔ چنانچہ حضورؐ نے دفاعی حکمت عملی
                  اپنائی۔ اگرچہ اس وقت نوجوان پر جوش صحابۂ کرامؓ کا اصرار یہی
                  تھا کہ باہر نکل کر مقابلہ کیا جائے، مگر ہوش مندی کا تقاضا یہ
                  تھا کہ صبر کیا جائے، جذبات کو قابو میں رکھا جائے، مسلمانوں کو
                  بچایا جائے اور یہ مقصد حاصل کرنے کے لیے دو بدو جنگ سے گریز اور
                  اجتناب کیا جائے۔ چنانچہ حضورؐ نے حضرت سلمان فارسیؓ کے مشورے پر
                  جدید ترین حکمت عملی سے کام لیتے ہوئے مدینے کے گرد خندق کھودی
                  اور یوں یہ جنگ نہ ہو نے دی۔
                  <br />
                  چھ ہجری میں قریش مکہ نے انتہائی زیادتی سے کام لیتے ہوئے
                  مسلمانوں کو عمرہ کرنے سے روک دیا۔ تاہم اس کے ساتھ ہی انھوں نے
                  صلح کی پیش کش بھی کر دی۔ صلح کی شرائط بظاہر مسلمانوں کے بالکل
                  خلاف تھیں، خصوصاً جس شرط میں کہا گیا تھا کہ اگر مسلمانوں میں
                  سے کوئی اپنے دین کو چھوڑ کر دشمن سے جا ملے، تو اس کو واپس نہیں
                  کیا جائے گا، لیکن اگر مشرکین میں سے کوئی شخص اسلام قبول کر کے
                  مدینہ جائے گا، تو مسلمانوں پر اس فرد کی واپسی لازم ہو گی۔
                  حضورؐ نے دشمن کی ان ناجائز شرائط پر ان سے صلح کر لی، اس لیے کہ
                  حضورؐ جانتے تھے کہ اس صلح کے نتیجے میں اسلام کی دعوت کا راستہ
                  کھل جائے گا۔ گویا حضورؐ نے دشمن سے امن کا وقفہ خرید لیا۔ یہی
                  وجہ ہے کہ قرآن مجید نے بظاہر دب کر کی جانے والی اس صلح کو کھلی
                  فتح اور بڑی کامیابی قرار دیا۔
                  <br />
                  جب دشمن کی بدعہدی کے نتیجے میں صلح حدیبیہ ختم ہو گئی تو حضورؐ
                  نے انتہائی صبر کے ساتھ تیاری کا مرحلہ جاری رکھا۔ سفارت کاری کے
                  ذریعے دشمن کو بالکل تنہا کر دیا۔ پھر ایک عظیم فوج لے کر مکہ کو
                  پرامن طور پر فتح کر لیا اور سب سے بڑی بات یہ کہ نہ صرف دشمنوں
                  کو معاف کر دیا بلکہ انہی کو وہاں کی قیادت بھی سونپ دی۔ غور کیا
                  جائے تو ان واقعات میں موجودہ دور کے مسلمانوں کے لیے صبر و حکمت
                  کا عظیم سبق پوشیدہ ہے۔
                  <br />
                  اس کے فوراً بعد جب جنگ حنین کا معرکہ پیش آیا تو کچھ مسلمانوں
                  کے دل میں تکبر پیدا ہوا کہ آج ہمیں کون ہرا سکتا ہے۔ چنانچہ
                  پروردگار نے ان کے غرور پر اظہار ناراضی کرتے ہوئے انھیں ابتدائی
                  شکست سے دوچار کر دیا۔
                  <br />
                  ہمیں یہ بات اچھی طرح سمجھ لینی چاہیے کہ جب پروردگار دنیوی
                  تدبیر اور صبر کے معاملے میں صحابۂ کرامؓ کی کوتاہی کو بھی
                  برداشت نہیں کرتا تو پھر بھلا وہ ہماری کوتاہی کو کیسے برداشت
                  کرے گا۔ چنانچہ آج امت مسلمہ کی سب سے بڑی ضرورت صبر ہے۔ (جہاد و
                  قتال ۔ چند اہم مباحث ۲۲۔۲۴)
                  <br />
                  * اکثر اوقات ایسا ہوتا ہے کہ ایک مسلمان ملک کے حکمرانوں کے
                  اندر خرابیاں ہوتی ہیں۔ وہ دین کی ہدایات کا خیال نہیں کرتے۔ اُن
                  کی کمزوری، غفلت، یا اُن کے طرز عمل کی وجہ سے ملک کے اندر رشوت،
                  بددیانتی، بے انصافی، بے حیائی اور بدامنی بڑے پیمانے پر موجود
                  ہوتی ہے۔ ایسا بھی ہوتا ہے کہ حکمران خود قانون کا کوئی خیال
                  نہیں کرتے۔ ایسے موقع پر ہر حساس اور درد مند انسان کے دل میں یہ
                  خیال پیدا ہو تا ہے کہ اس صورت حال کی اصلاح ہونی چاہیے۔ بعض
                  اوقات یہ احساس اس حد تک بڑھ جاتا ہے کہ انسان کا جی چاہتا ہے کہ
                  وہ ایک مسلح گروہ تشکیل دے دے، جو بزور اور طاقت کے ساتھ حکومت
                  کی ان برائیوں کا سدباب کرے۔ چنانچہ یہ سوال پیدا ہوتا ہے کہ کیا
                  ایسا ممکن ہے کہ ملک میں اچھائیوں کے فروغ اور حکمرانوں کی
                  برائیوں کا سدباب کرنے کے لیے اسلحہ اٹھایا جائے اور اپنے
                  حکمرانوں کے خلاف جنگ کی جائے؟ آج کے زمانے میں یہ سوال بہت اہم
                  بن گیا ہے، اس لیے کہ کئی مسلمان ملکوں میں ایسی مسلح تنظیمیں
                  وجود میں آگئی ہیں، جو اپنے مقاصد مسلح جدوجہد کے ذریعے حاصل
                  کرنے کی کوشش کر رہی ہیں۔
                  <br />
                  اس معاملے میں اسلام کا جواب بالکل واضح ہے۔ وہ یہ کہ ایک مسلمان
                  ریاست کے اندر، مسلمان حکمران کے خلاف کسی مسلح کارروائی کی
                  اجازت نہیں۔ اصلاح کی کوشش ضرور ہونی چاہیے، لیکن یہ کوشش
                  خالصتاً پر امن ہونی چاہیے، اور عدم تشدد کے اصول پر ہونی چاہے۔
                  ارشاد ہے:
                </p>

                <blockquote>
                  <p>
                    <b>
                      یٰٓاَیُّھَا الَّذِیْنَ اٰمَنُوْٓا، اَطِیْعُوا اللّٰہَ
                      وَاَطِیْعُوا الرَّسُوْلَ وَاُولِی الْاَمْرِ مِنْکُمْ،
                      فاِنْ تَنَازَعْتُمْ فِیْ شَیْءٍ فَرُدُّوْہُ اِلَی اللّٰہِ
                      وَالرَّسُوْلِ، اِنْ کُنْتُمْ تُؤْمِنُوْنَ بِاللّٰہِ
                      وَالْیَوْمِ الْاٰخِرِ. ذٰلِکَ خَیْرٌ وَّاَحْسَنُ
                      تَاْوِیْلًا. (سورۂ نساء۴: ۵۹)
                    </b>
                    <br />
                    ’’اے ایمان والو، اللہ کی اطاعت کرو، اُس کے رسول کی اطاعت
                    کرو، اور اپنوں میں سے اپنے حاکموں کی اطاعت کرو۔ پھر اگر کسی
                    معاملے میں تمہارے درمیان اختلاف رائے ہو، تو اُسے اللہ اور
                    رسول کی طرف پھیر دو، اگر تم اللہ پر اور قیامت کے دن پر ایمان
                    رکھتے ہو۔ یہ اچھا طریقہ ہے، اور انجام کے لحاظ سے بھی یہی
                    بہتر ہے۔‘‘
                  </p>
                </blockquote>

                <p>
                  یہ حکم اُس وقت دیا گیا جب قرآن نازل ہو رہا تھا، اور حضورﷺ خود
                  مسلمانوں کے درمیان موجود تھے۔ چنانچہ جب حضورؐ کے مقرر کردہ
                  حاکموں سے عوام کا کوئی اختلاف ہوتا، تو یہ معاملہ حضورؐ کے
                  سامنے لایا جاتا، اور حضورؐ اُس کا فیصلہ فرما دیتے۔ اُس وقت یہ
                  سوال پیدا ہوا کہ جب حضورؐ اس دنیا میں تشریف فرمانہ ہوں، تو کیا
                  کیا جائے۔ حضورؐ نے اپنے ارشادات کے ذریعے یہ جواب ارشاد فرمایا
                  کہ مسلمان عوام کے لیے صحیح طریقہ یہ ہے کہ وہ اپنی ریاست اور
                  حکومت سے وابستہ رہیں۔ اگر حکمرانوں کے اندر کوئی غلط یا ناگوار
                  بات موجود ہو، اور وہ لوگوں کواُن کا حق نہ دے رہے ہوں، تو تب
                  بھی اُن کی عمومی اطاعت کی جائے۔ حضورؐ نے ارشاد فرمایا:
                </p>

                <blockquote>
                  <p>
                    ’’تم پر لازم ہے کہ تم اپنے حکمرانوں کی بات سنو اور ان پر عمل
                    کرو۔ چاہے تم تنگی میں ہو یا آسانی میں، اور چاہے کہ ماننے کا
                    یہ عمل رضا و رغبت کے ساتھ ہو یا بے دلی کے ساتھ، اور اس کے
                    باوجود کہ تمہارا حق تمہیں نہ پہنچے۔‘‘(صحیح مسلم، حدیث نمبر
                    ۴۷۵۴)
                  </p>
                </blockquote>

                <p>اسی طرح حضورؐ نے ارشاد فرمایا:</p>

                <blockquote>
                  <p>
                    ’’جسے حکمران کی کوئی بات ناگوار گزرے، اُس شخص کو صبر کرنا
                    چاہیے، کیونکہ جو شخص ایک بالشت کے برابر بھی حکومت کی اطاعت
                    سے نکلا، اور اسی حالت میں مر گیا، اُس کی موت جاہلیت پر
                    ہوئی۔‘‘(صحیح بخاری، حدیث نمبر ۷۰۵۳)
                  </p>
                </blockquote>

                <p>اسی طرح حضورؐ نے مزید ارشاد فرمایا:</p>

                <blockquote>
                  <p>
                    ’’جس نے اپنے حکمران کی طرف سے کوئی ناپسندیدہ بات دیکھی، اُس
                    شخص کو چاہیے کہ صبر کرے، کیونکہ جو شخص ایک بالشت کے برابر
                    بھی مسلمانوں کی اجتماعی ریاست سے الگ ہوا، اور اسی حالت میں
                    مر گیا، اُس کی موت جاہلیت پر ہوئی۔‘‘ (صحیح بخاری، حدیث نمبر
                    ۷۰۵۴)
                  </p>
                </blockquote>

                <p>
                  درج بالا روایات سے یہ بات بالکل واضح ہوجاتی ہے کہ اگر حکمرانوں
                  کے اندر خرابیاں موجود ہوں تو ہم صبر کے ساتھ اُن کی اصلاح کی
                  کوشش کرتے رہیں گے، تاہم ہم اپنے حکمرانوں کے احکام کو مانیں گے۔
                  <br />
                  یہاں یہ سوال پیدا ہوا کہ حکمران کی ذاتی خرابیاں، اور حکومتی
                  نظام کی خرابیاں تو اپنی جگہ پر، لیکن اگر کوئی حکمران کسی
                  باشندے کو گناہ کا کوئی ایسا کام کرنے کا حکم دے، تو ایسی حالت
                  میں کیا کیا جائے۔ مثلاً اگر کوئی حاکم کسی باشندے کو شراب پینے
                  کا حکم دے، تو ایسا شخص کیا کرے۔ اس کا جواب حضورؐ نے یوں دیا:
                </p>

                <blockquote>
                  <p>
                    ’’ہر مسلمان پر لازم ہے کہ ہر حال میں اپنے حکمران کی بات سنے
                    او ر مانے، خواہ یہ حکم اُس کو پسند ہو یا نہ پسند۔ سوائے اس
                    کے کہ اُس شخص کو کسی گناہ کا کام کرنے کا حکم دیا جائے۔ پھر
                    اگر اُس شخص کو گناہ کا کام کرنے کا حکم دیا گیا تو وہ نہ سنے
                    گا اور نہ مانے گا۔‘‘ (صحیح مسلم، حدیث نمبر ۴۷۶۳)
                  </p>
                </blockquote>

                <p>
                  گویا درج بالا حدیث کے مطابق اگر کسی مسلمان کو اپنے حاکم کی طرف
                  سے گناہ کاکوئی کام کرنے کو کہا جائے تو ایسے شخص کے لیے یہ
                  ضروری ہے کہ وہ ایسے کسی حکم کو نہ مانے۔ اگر ایسے حکم کو نہ
                  ماننے کے نتیجے میں اس شخص پر حاکم کی طرف سے ظلم ہوتا ہے تو
                  ایسا شخص اس ظلم کو برداشت کرے۔ اس کا اجرو ثواب اُسے قیامت کے
                  دن ملے گا۔ (واضح رہے کہ آج کے زمانے میں کسی بھی مسلمان یا غیر
                  مسلم حکمران کی طرف سے کسی بھی مسلمان کو گناہ کے کسی کام پر
                  مجبور نہیں کیا جا رہا)۔
                  <br />
                  یہاں ایک اور سوال پیدا ہوتا ہے۔ وہ یہ کہ اگر کوئی حکمران اس حد
                  تک خراب ہو جائے کہ وہ اپنی رعایا کو گناہ کا کام کرنے کا حکم
                  دینے لگے، تو کیا ایسے حکمران کے خلاف اسلحہ اٹھایا جا سکتا ہے؟
                  حضورؐ نے اس کا یہ جواب دیا کہ جب تک حکمران نماز سے انکار نہ
                  کرے، اور اسلام کو چھوڑ کر کھلم کھلا کفر اختیار نہ کرے، تب تک
                  اُس کے خلاف اسلحہ اٹھانے کی اجازت نہیں۔ عبادہ بن صامت سے روایت
                  ہے:
                </p>

                <blockquote>
                  <p>
                    ’’نبیؐ نے ہمیں بیعت کے لیے بلایا تو ہم نے آپؐ سے بیعت کی۔
                    اُس میں جن باتوں کا وعدہ لیا گیا تھا، وہ یہ تھیں کہ ہم اپنے
                    حکمران کی بات سنیں گے اور مانیں گے۔ چاہے یہ رضاو رغبت کے
                    ساتھ ہو یا بے دلی کے ساتھ، اور چاہے ہم تنگی میں ہوں یا آسانی
                    میں، اور اس کے باوجود کہ ہمارا حق ہمیں نہ پہنچے۔ ہم سے یہ
                    وعدہ بھی لیا گیا کہ ہم اپنے حکمرانوں سے اقتدار کے معاملے میں
                    کوئی جھگڑا نہ کریں گے۔ آپؐ نے فرمایا: ’’تم اپنے حکمرانوں سے
                    جھگڑا صرف اُس صور ت میں کر سکتے ہو، جب تم اُن کی طرف سے کوئی
                    کھلم کھلا کفر دیکھو، اور تمہارے پاس اس معاملے میں اللہ کی
                    واضح دلیل موجود ہو‘‘۔‘‘ (صحیح مسلم، حدیث نمبر ۴۷۷۱)
                  </p>
                </blockquote>

                <p>
                  اسی طرح حضورؐ نے مستقبل کے مسلمان حکمرانوں کے بارے میں ارشاد
                  فرمایا:
                </p>

                <blockquote>
                  <p>
                    ’’تم پر ایسے لوگ حکومت کریں گے جن کی بعض باتیں تمہیں اچھی
                    لگیں گی، اور بعض باتیں بری لگیں گی۔ پھر جس شخص نے اُن کی بری
                    باتوں کو ناپسند کیا، وہ بری الذمہ ہوا۔ اور جس نے اُن غلط
                    باتوں کا انکار کیا، وہ بھی محفوظ رہا۔ مگر جو شخص اُن غلط
                    باتوں پر راضی ہوا اور پیچھے چل پڑا، تو اُس سے (قیامت کے دن)
                    پوچھا جائے گا۔ صحابہؓ نے پوچھا: جب یہ صورت ہو (یعنی ہمارے
                    حکمران ہمیں گناہ کرنے کا حکم دیں) تو کیا ہم اُن سے جنگ نہ
                    کریں؟ آپؐ نے فرمایا: نہیں، جب تک وہ نماز پڑھتے ہوں (تب تک
                    تمہیں جنگ کا اختیار نہیں ہے)۔‘‘(صحیح مسلم، حدیث نمبر ۴۸۰۱)
                  </p>
                </blockquote>

                <p>
                  یہاں ضمنی طور پر ایک اور سوال بھی پیدا ہوتا ہے۔ وہ یہ کہ جو
                  حکمران لوگوں کی مرضی سے منتخب شدہ ہوتا ہے، لیکن اس کے باوجود
                  اُس میں بڑی خرابیاں ہوتی ہیں، تو ایسے حکمران کی کیا حیثیت ہے؟
                  حضورؐ نے اس بارے میں ارشاد فرمایا کہ ایسے حکمران کے خلاف بغاوت
                  کرنا بہت بڑا جرم ہے۔ ارشاد ہے:
                </p>

                <blockquote>
                  <p>
                    ’’جب تم کسی شخص کی حکمرانی پر جمع ہو (یعنی لوگوں کی اکثریت
                    اُس کے حق میں ہو)، اور کوئی شخص تمہاری جمعیت کو پارہ پارہ
                    کرنے، یا تمہاری حکومت کے معاملے میں تفرقہ پیدا کرنے کے لیے
                    اٹھے، تو اُسے قتل کر دو۔‘‘
                  </p>
                </blockquote>

                <p>
                  یہاں ایک اور سوال یہ پیدا ہوتاہے کہ اگر حکمران کے خلاف مسلح
                  بغاوت منع ہے، تو حکمران اور حکومت کی اصلاح کے لیے کیا کیا
                  جائے؟ اس کا جواب حضورؐ نے یہ دیا کہ ایسے حکمران کے سامنے سچی
                  بات کہی جائے۔ ظاہرہے کہ سچی بات سننے کے نتیجے میں یا وہ اُس
                  بات کو مان لے گا، یا اُس پر آنکھیں بند کر لے گا، اور یا پھر
                  سچی بات کہنے والے شخص پر ظلم کرنا شروع کر دے گا۔ ایسے ظلم پر
                  صبر اور ثابت قدمی کا رویہ اختیار کرنا جہاد کا بہت بڑا درجہ ہے۔
                  ارشاد فرمایا:
                </p>

                <blockquote>
                  <p>
                    <b>
                      اَفْضَلُ الْجِھَادِ مَنْ قَالَ کَلِمَۃً حَقٌ عِنْدَ
                      سُلْطَانٍ جَاءِر.
                    </b>
                    <br />
                    ’’سب سے بڑا جہاد یہ ہے کہ ظالم حکمران کے سامنے سچی بات کہی
                    جائے۔‘‘(جہاد و قتال ۔ چند اہم مباحث ۵۴۔۵۸)
                  </p>
                </blockquote>

                <p>
                  * دین اپنی حقیقت میں دو ہی چیزوں کا نام ہے۔ ایک قرآن مجید اور
                  دوسرا سنت۔ حضور ﷺ کی وفات کے بعد ریاستی اور اجتماعی معاملات
                  میں دینی احکام معلوم کرنے کے لیے ایک سادہ اور واضح طریق کار
                  اپنایا گیا۔ وہ یہ کہ ہر معاملے میں پہلے قران مجید اور اس کے
                  بعد حضورؐ کے ارشادات سے رہنمائی حاصل کی جاتی۔ اگر کسی معاملے
                  میں ان سرچشموں سے ہدایت نہ ملتی، تو اپنی عقل و فہم سے کام لے
                  کر جمہوری طریقے سے فیصلہ کیا جاتا۔ یہی فطری طریقہ تھا اور اسی
                  کو جاری رہنا چاہیے تھا۔ اگر یہ طریقہ کار جاری رہتا اور نئی نئی
                  اصطلاحات وضع کرنے کے بجائے قرآن و حدیث کی اصطلاحات ہی کے ذریعے
                  دین کو واضح کیا جاتا، تو دین کو سمجھنا اور سمجھانا بہت آسان
                  ہوتا۔
                  <br />
                  حضورؐ کی وفات کے تقریباً ایک سو برس بعد مسلمانوں پر فقہ کے علم
                  کا غلبہ ہوا۔ اس علم کی ابتدا بڑی نیک نیتی سے ہوئی۔ اُس وقت
                  اسلام دنیا کے بہت سے کونوں میں پھیل چکا تھا اور نئے لوگ بڑی
                  تیزی سے دائرہ اسلام میں داخل ہو رہے تھے، اس لیے کچھ علماء کو
                  یہ خوف لاحق ہوا کہ یہ نئے لوگ جلدی قرآن و حدیث سے واقف نہ ہو
                  سکیں گے، لہٰذا ان کے لیے دین کی ضروریات کو ایک قانونی شکل میں
                  بیان کر دیا جائے۔ چنانچہ اس طرح دین کی قانونی ترتیب
                  (codification) کا کام شروع ہوا۔ جب ایک دفعہ یہ کام شروع ہوا تو
                  پھر یہ سلسلہ کہیں پر نہ رکا۔ ہر مکتب فکر نے نئی نئی اصطلاحات
                  وضع کرنی شروع کیں۔ جس کا نتیجہ یہ ہوا کہ اس علم کو انتہائی
                  پیچیدہ بنا دیا گیا۔ ہر مکتب فکر نے ہر اصطلاح کی اپنی تعریف
                  متعین کر دی۔ اس کا نتیجہ یہ ہوا کہ ایک ہی اصطلاح سے ایک گروہ
                  کے فقہا کچھ اور مراد لیتے تھے اور دوسرے گروہ کے فقہا کچھ اور۔
                  جب مختلف مکاتب فکر کے علماء کا آپس میں مکالمہ ہوتا تو یہ
                  پیچیدگی اپنی آخری انتہا کو پہنچ جاتی کیونکہ دونوں طرف سے ایک
                  ہی اصطلاح مختلف معنوں میں استعمال کی جاتی تھی۔ جب ایک عرصے تک
                  یہ معاملہ جاری رہا تو فقہا کو یہ ڈر ہوا کہ اس سے بڑی خرابیاں
                  پیدا ہوں گی۔ چنانچہ یہ سوچ پروان چڑھی کہ اب مزید بحث و اجتہاد
                  کا دروازہ بند کر دیا جائے۔ چنانچہ ایسا ہی کیا گیا۔ اس کے نتیجے
                  میں جمود آیا، ہر گروہ اپنی اپنی فقہی آرا پر سختی سے جم گیا اور
                  مسلمان معاشرے میں مذہبی معاملات میں انتہا پسندی اور جمود نے
                  جنم لیا۔
                  <br />
                  ہمارے لیے آج کے حالات میں یہ ضروری ہے کہ ہم دین کو سمجھنے کے
                  معاملے میں صحابہ کرامؓ کا طرز عمل اختیار کریں۔ وہ یہ کہ ہر چیز
                  میں ہمارے لیے اصل ہدایت کا سرچشمہ قرآن و سنت اور حدیث ہیں۔ اس
                  کے بعد اہل علم نے اپنے اپنے زمانوں میں اپنے حالات کے مطابق جو
                  رائے ظاہر کی ہے، وہ سب ہمارے لیے بہت قابل قدر ہیں۔ تاہم اُن
                  میں سے کسی بھی بات کی پیروی ہم پر لازم نہیں ہے۔ (جہاد و قتال
                  ۔چند اہم مباحث ۹۹۔۱۰۰)
                  <br />
                  * خود کش حملوں کی تاریخ بہت پرانی ہے۔ خوارج نے درحقیقت خود کش
                  حملوں ہی کے ذریعے حضرت علیؓ، حضرت امیر معاویہؓ، اور حضرت عمرو
                  بن العاصؓ کو شہید کرنے کا فیصلہ کیا تھا۔ حسن بن صباح، جس نے
                  خراسان کے پہاڑوں میں اپنی حکومت بنا کر قلعہ الموت کو اُس کا
                  مرکز بنا دیا تھا، کا ایک بڑا ہتھیار خود کش حملے ہی تھا۔ اس طرح
                  اُس نے اپنے وقت کے بڑے بڑے بادشاہوں کو لرزہ براندام کر دیا
                  تھا۔ حتیٰ کہ ہلاکو خان نے آکر اُس کے قلعے کی اینٹ سے اینٹ بجا
                  دی۔
                  <br />
                  حالیہ زمانے میں، دوسری جنگ عظیم میں، جاپانیوں نے بھی خودکش
                  حملوں سے کام لیا تھا۔ ایک جاپانی پائلٹ بارود سے بھرے ہوئے
                  ہوائی جہاز کو اڑاتے ہوئے برطانیہ کے کسی بحری جہاز کی چمنی کے
                  اندر گھس جاتا، اور یوں وہ اپنے آپ کو ختم کرتے ہوئے دشمن کا
                  سمندری جہاز اور دشمن کے سینکڑوں سپاہیوں کو غرق کر دیتا۔ اگرچہ
                  اس سے برطانیہ کا بڑا نقصان ہوا، لیکن اصل نقصان جاپان کا ہوا۔
                  کیونکہ جنگ کے اس طریقے سے جاپان کے پاس پائلٹ ختم ہو گئے، اور
                  یوں اُس کے پاس برطانوی فوج کے ہوائی حملوں کو روکنے کا کوئی
                  ذریعہ نہ رہا۔
                  <br />
                  سن اسی کی دہائی میں سری لنکا میں تامل گوریلوں نے بھی خودکش
                  حملوں سے کام لیا۔ تامل گوریلوں کی طرف سے سری لنکا کی حکومت کے
                  خلاف چار سو سے زیادہ خودکش حملے ہوئے، جن میں سینکڑوں فوجی اور
                  سویلین مارے گئے۔ لیکن آخری فتح سری لنکا کی ہوئی، اور تامل
                  گوریلوں کی مکمل شکست ہوئی۔ گویا خودکش حملوں نے تامل گوریلوں کو
                  کوئی فائدہ نہیں پہنچایا۔
                  <br />
                  ایک زمانے میں عراق کے اندر بہت سے خودکش حملے کیے گئے۔ مگر وہاں
                  بھی اب یہ سلسلہ بہت کم ہو چکا ہے۔ عراق کی جمہوری حکمت دن بدن
                  مضبوط ہو رہی ہے۔ اُس نے اپنی مضبوطی کے ذریعے امریکیوں پر ثابت
                  کر دیا کہ اب عراق میں امن کی بحالی کے لیے امریکی فوج کی کوئی
                  ضرورت نہیں۔ چنانچہ امریکیوں نے یہ وعدہ کر لیا ہے کہ وہ 2010ء
                  کے آخر تک عراق سے مکمل طور پر نکل جائیں گے۔ عراق میں القاعدہ
                  کی حکمت عملی سو فیصد ناکام ہو چکی ہے۔ اُس نے وہاں سینکڑوں
                  خودکش حملے کروائے، جس میں ہزاروں بے گناہ لوگ قتل ہو گئے۔ لیکن
                  ان خود کش حملوں کا کوئی فائدہ القاعدہ کو نہیں پہنچا، اور وہ یہ
                  جنگ ہار گئی ہے۔
                  <br />
                  پوری دنیا کی تاریخ میں خود کش حملوں کا جائزہ لینے سے معلوم
                  ہوتاہے کہ خودکش حملوں سے دشمن کے بجائے خود اپنے آپ کو زیادہ
                  نقصان پہنچتا ہے۔ وہ یوں کہ خود کش حملوں کے لیے صرف وہی شخص
                  اپنے آپ کو پیش کرتا ہے جو انتہائی پرعزم، باصلاحیت اور قربانی
                  کے جذبے سے سرشار ہو۔ جب کہ ان حملوں میں مرنے والے مخالف قوم کے
                  افراد عموماً عام افراد ہوتے ہیں جو اتفاق سے اجل کا لقمہ بن
                  جاتے ہیں۔ جب قوم کے باصلاحیت اور پرعزم نوجوان ان حملوں میں کام
                  آجائیں تو پیچھے اوسط درجے کے لوگ ہی بچتے ہیں۔ جاپان نے بھی
                  دوسری جنگ عظیم میں اپنے پائلٹوں کو دشمن کے بحری جہازوں سے ٹکرا
                  کر ایسا ہی غلط فیصلہ کیا تھا، جس کے نتیجے میں اُس کے پاس
                  پائلٹوں کی شدید قلت ہو گئی تھی۔ چنانچہ یہ کوئی صحیح حکمت عملی
                  نہیں۔
                  <br />
                  خودکش حملوں سے نمٹنے کے لیے مخالف طاقت بھی آہستہ آہستہ حکمت
                  عملی سیکھ لیتی ہیں۔ مثلاً اسرائیل نے یہ سٹریٹیجی اختیار کی کہ
                  خودکش حملہ آوروں کے سارے اہل خانہ کو جیل میں ڈال دیا، پھر ان
                  کو جلاوطن کر کے بے یارو مددگار چھوڑ دیا اور ان کے گھروں پر
                  بلڈوزر چلا دیے۔ اگرچہ یہ بہت ظالمانہ کارروائی تھی۔ تاہم اس کا
                  نتیجہ یہ ہوا کہ خودکش حملوں کی تعداد کم ہوتے ہوتے نہ ہونے کے
                  برابر رہ گئی۔ کیونکہ خودکش حملہ آور سوچتے تھے کہ ہمارے بعد
                  ہمارے اہل خانہ اور پیاروں کو ناقابل بیان اذیت کا سامنا کرنا
                  پڑے گا۔
                  <br />
                  خودکش حملے مخالف طاقت کے اندر ایک ایسی نفسیات پیدا کرتے ہیں،
                  جن میں ضد، انتقام اور نفرت شامل ہوتی ہے۔ بسا اوقات خود کش حملے
                  دشمن کی صفوں میں اتحاد پیدا کرنے کا باعث بنتے ہیں۔ مثلاً جب
                  بھارت کے اندر خودکش حملے ہوئے تو سارا بھارت ان حملوں کے خلاف
                  متحد ہوگیا اور سب لوگوں نے مطالبہ کیا کہ ان خودکش حملہ آوروں
                  کے مطالبات کے سامنے سر نہ جھکایا جائے۔ یوں ان خودکش حملوں سے
                  بھارت کو بحیثیت ریاست بڑی تقویت حاصل ہوئی۔
                  <br />
                  خودکش حملوں سے مظلوم قوم کی جدوجہد کو سخت نقصان پہنچتا ہے اور
                  دنیا کے نزدیک ظالم و مظلوم ایک ہی صف میں کھڑے نظر آتے ہیں۔
                  بلکہ بعض اوقات تو ظالم قوم پروپیگنڈے کے زور پر مظلوم قوم کو
                  جارحیت پسند قرار دینے میں کامیاب ہو جاتی ہے۔ آج تک جتنے بھی
                  خودکش حملے ہوئے ہیں، اُن میں فوجی اور غیر فوجی تنصیبات کا خیال
                  عموماً نہیں رکھا گیا۔ چنانچہ جب ریستورانوں، مارکیٹوں اور بسوں
                  میں خودکش حملوں سے سویلین مرجاتے ہیں، تو انسانیت کا مجموعی
                  ضمیر ایسی کارروائیوں کو پسند نہیں کرتا اور ان کی مذمت کرتاہے۔
                  جب فلسطینی خودکش حملہ آوروں نے پبلک مقامات پر دھماکے کیے، تو
                  اس کے نتیجے میں ظالم اسرائیل کو تو کوئی خاص نقصان نہیں پہنچا،
                  لیکن دنیا کی رائے عامہ کی نظر میں فلسطینیوں کی جائز آزادی کی
                  جدوجہد پر اخلاقی اعتبار سے داغ لگ گیا۔
                  <br />
                  جب ایک دفعہ خودکش حملوں کو جائز قرار دیا جائے، خواہ وہ کتنے ہی
                  نیک مقصد کے لیے کیوں نہ ہو، تو پھر یہ سلسلہ کہیں پر بھی جا کر
                  نہیں رکتا۔ پھر اس کے نتیجے میں ایک گناہ گار کے ساتھ سو بے گناہ
                  مارے جاتے ہیں۔ پھر ہر گروہ دوسرے گروہ کو ظالم اور خارج از
                  دائرہ اسلام قرار دیتے ہوئے اُس کے خلاف خودکش حملوں کو جائز
                  سمجھ لیتا ہے۔ اسی وجہ سے عراق میں اہل سنت اور اہل تشیع نے ایک
                  دوسرے کے خلاف خودکش حملے کیے، جس کے نتیجے میں ہزاروں لوگ جاں
                  بحق ہو چکے ہیں۔ پاکستان میں بھی یہی کچھ ہوا۔ مختلف مذہبی
                  گروہوں نے ایک دوسرے پر خود کش حملے کیے، جس کے نتیجے میں دونوں
                  طرف کے سینکڑوں لوگ جاں بحق ہوئے۔ اگرچہ ہر حملے کی ابتدا میں یہ
                  دعویٰ کیا گیا کہ ان حملوں میں بیرونی ہاتھ ملوث ہے، لیکن
                  تحقیقات کے نتیجے میں ہمیشہ یہ بات سامنے آئی کہ درحقیقت ہر
                  خودکش حملہ ایک مذہبی یا سیاسی گروہ نے اپنے مخالف مذہبی یاسیاسی
                  گروہ کے خلاف کیا تھا۔ اس کا مطلب یہ ہے کہ خودکش حملے ایک ایسا
                  پنڈورا باکس ہیں جس کو کھولنے کے نتیجے میں پھر کوئی بھی محفوظ
                  نہیں رہ سکتا۔
                  <br />
                  خود کش حملوں کے جواز میں یہ بھی کہا جاتا ہے کہ ’’یہ دراصل ظلم
                  کا ردعمل ہیں، چونکہ مظلوم قوم کو دیوار سے لگا دیا گیا ہے، اس
                  لیے ان لوگوں کے پاس خودکش حملوں کے سوا کوئی راستہ نہیں بچا۔
                  چنانچہ یہ خودکش حملے جائز ہیں‘‘۔ یہ دلیل صحیح نہیں ہے۔ ظلم
                  خواہ کتنا بھی بڑھ جائے، ہمیں اس کے مقابلے کے لیے اللہ نے خودکش
                  حملوں کی اجازت نہیں دی۔ اللہ کا دین تو اس دنیا میں آیا ہی اس
                  لیے ہے کہ ہمارے اقدامات اور ردعمل کو قابو میں رکھے، اور ہمیں
                  ہر چیز کے حدود اور آداب سکھائے۔ فوری ردعمل ایک طبعی قانون
                  (Physical Law) ہے۔ اس کے مقابلے میں انسان کے لیے روحانی قانون
                  (Spiritual Law) ردعمل کا نہیں بلکہ صبر کا قانون ہے۔ یہ بات بھی
                  غلط ہے کہ مظلوم اقوام مثلاً فلسطینیوں، عراقیوں، افغانیوں یا
                  کشمیریوں کے پاس کوئی اور راستہ بچا ہی نہیں۔ درحقیقت مزاحمت کا
                  سب سے بڑا ہتھیار عدم تشدد پر مبنی مظلومانہ جمہوری جدوجہد
                  اورآپس میں اتحاد و اتفاق ہے۔ ان دونوں تدابیر سے مظلوم مسلمان
                  اقوام نے کبھی فائدہ اٹھایا ہی نہیں۔ مثلاً فلسطینی اور کشمیری
                  آپس میں بیسیوں تنظیموں میں بٹے ہوئے ہیں اور انھوں نے آج تک ایک
                  متحد اور ایک متفقہ قائد پر اتفاق نہیں کیا۔ اسی طرح اگر آج
                  طالبان مسلح مزاحمت چھوڑ کر جمہوری جدوجہد کا راستہ اختیار کریں
                  تو بہت جلد امریکیوں کو یہاں سے نکالا جا سکتا ہے۔
                  <br />
                  یہ بات ہر مسلمان کو معلوم ہے کہ خودکشی جائز نہیں ہے۔ تاہم بعض
                  لوگ یہ دلیل دیتے ہیں کہ ’’جنگ موتہ کے دوران میں، جب کہ دشمن کی
                  تعداد بہت زیادہ تھی اور مسلمان لشکرکی تعداد کم تھی، مسلمان
                  فوجیوں نے موت پر بیعت کی تھی۔ اس سے یہ معلوم ہوتا ہے کہ دشمن
                  کے خلاف خودکش حملہ جائز ہے‘‘۔ یہ دلیل صحیح نہیں ہے۔ جنگ موتہ
                  میں مسلمان لشکر، ریاست مدینہ کے حکم پر لڑرہا تھا۔ موت پر بیعت
                  کا مطلب یہ تھا کہ ہم آخری دم تک لڑیں گے، لیکن میدان جنگ سے
                  بھاگیں گے نہیں۔ یہ بات قرآن مجید کے اس ارشاد کے عین مطابق ہے
                  جس میں مسلمان لشکر کو میدان جنگ سے پیٹھ پھیر کر بھاگنے سے منع
                  کیا گیا ہے۔ ظاہر ہے کہ اس ہدایت اور اس واقعے کا خودکش حملوں سے
                  کوئی تعلق نہیں بنتا۔ جنگ موتہ میں کسی مسلمان نے دشمن کے لشکر
                  پر خودکش حملہ نہیں کیا۔ اس جنگ میں صرف گیارہ مسلمان شہید ہوئے۔
                  عملاً یہ ہوا کہ مسلمان لشکر کے عزم و ہمت کے نتیجے میں دشمن کا
                  لشکر بے حوصلہ ہو گیا اور مسلمان لشکر کے سردار حضرت خالدؓ بن
                  ولید کو یہ موقع مل گیا کہ وہ رات کی تاریکی میں اپنے لشکر کو
                  محفوظ مقام پر منتقل کر دیں۔ اس کے بعد دشمن کے لشکر کو مسلمانوں
                  کا پیچھا کرنے کا حوصلہ نہ ہوا۔ درج بالا تجزیے کی روشنی میں یہ
                  بات بالکل واضح ہو جاتی ہے کہ جنگ موتہ کے واقعات اور آج کل کے
                  خود کش حملوں کا آپس میں کوئی موازنہ یا تعلق نہیں ڈھونڈا جا
                  سکتا۔
                  <br />
                  اس ساری بحث سے یہ بات واضح ہوتی ہے کہ خودکش حملوں کا موجودہ
                  سلسلہ امت مسلمہ کے وسیع تر مفاد میں نہیں ہے اور یہ تدبیر اصولی
                  اعتبار سے بھی غلط ہے۔ (جہاد و قتال ۔ چند اہم مباحث ۱۱۸۔۱۲۱)
                  <br />
                  * حضرت محمد ﷺکی وفات کے بعد صحابہ کرامؓ نے جو جنگیں لڑیں اور
                  ملک فتح کیے۔ ان کی نوعیت کیا تھی؟ اس کا جواب یہ ہے کہ حضورؐ نے
                  اپنی وفات سے تقریباً چار سال پہلے جزیرہ نمائے عرب کے ارد گرد
                  کے تمام حکمرانوں کو خطوط کے ذریعے سے اسلام کی دعوت دی۔ اور اس
                  کے ساتھ اپنے سفیر بھیجے تاکہ اگر وہ اسلام کو سمجھنا چاہیں تو
                  یہ سفیر ان کو تفصیل سے اسلام کے متعلق بتا سکیں۔ بادشاہوں کو خط
                  لکھنے کی وجہ یہ تھی کہ اس زمانے میں ملوکیت کا عام دور دورہ
                  تھا۔ رعایا یہ تصور بھی نہیں کر سکتے تھے کہ وہ کسی ایسے مذہب کو
                  قبول کر لیں جس کا مخالف ان کا بادشاہ ہو۔ چنانچہ وہاں کی رعایا
                  تک اسلام کی دعوت پہنچانے کا واحد طریقہ یہ تھا کہ وہاں کے
                  بادشاہوں کو اس کی طرف متوجہ کیا جائے۔
                  <br />
                  ان خطوط کے تین طرح کے براہ راست اثرات ہوئے۔ ایک یہ کہ مخاطب نے
                  اسلام قبول کر لیا۔ مثلاً نجاشی جو حبش کا بادشاہ تھا اس نے
                  اسلام قبول کر لیا۔ دوسرا یہ کہ حکمران نے اسلام قبول نہ کیا مگر
                  اس پیغام پر غور کرنے کی ہامی بھر لی اور سفیر سے اچھا سلوک کیا۔
                  اس کی مثال مصر اور بحرین کے بادشاہوں کی ہے۔ تیسرا یہ کہ نہ صرف
                  پیغام کو حقارت کی نظر سے دیکھا بلکہ سفیر سے بھی برا سلوک کیا۔
                  مثلاً شاہ فارس۔ ظاہر ہے کہ ان ممالک کے اندر عرب سے تجارتی
                  قافلے بھی جاتے رہتے تھے۔ اس لیے اسلام کا نام اور پیغام اس
                  ذریعہ سے بھی پہنچتا رہا۔ نیز چونکہ حکمرانوں کے ذرائع وسائل تو
                  بہت زیادہ ہوتے ہیں اس لیے وہ اس پیغام سے اچھی طرح متعارف بھی
                  ہوتے رہے۔
                  <br />
                  جب حضورؐ کی وفات ہو گئی۔ تو اس وقت حالات میں کچھ بنیادی
                  تبدیلیاں آنے لگیں۔ ایک یہ کہ ارد گرد کے حکمرانوں پر تو اتمام
                  حجت ہو چکا تھا مگر ان کے عوام کے لیے دین کا پیغام پہنچانے اور
                  اگر وہ ایمان لانا چاہیں تو ان کے ایمان لانے کی کوئی صورت نظر
                  نہیں آرہی تھی۔ دوسرا یہ کہ بعض ہمسایہ ممالک نے مسلمانوں کو
                  نیست و نابود کرنے کے لیے لشکر جمع کرنے شروع کر دیے تھے۔ تیسرا
                  یہ کہ لوٹ مار کرنے والے کئی قبائلی گروہ اسلامی حکومت میں
                  بدامنی پھیلاتے اور پھر ہمسایہ ممالک میں پناہ لے لیتے۔ یہ تمام
                  حالات اس امر کے متقاضی تھے کہ ان کا نوٹس لیا جائے۔ اور اس نئی
                  ریاست کو خطرات سے بچایا جائے۔ چنانچہ پالیسی یہ بنی کہ جن ممالک
                  سے کوئی خطرہ نہیں تھا۔ ان سے کوئی تعرض نہیں کیا گیا مثلاً حبش،
                  جن کے ساتھ معاہدے ہو سکتے تھے ان سے معاہدے کیے گئے۔ مثلاً
                  بحرین اور مصر اور جو ممالک کھلم کھلا دشمنی پر اتر آئے ان سے
                  جنگ کی جائے۔
                  <br />
                  اس طریقہ سے مسلمانوں کے قدم جہاں جہاں پہنچے۔ وہاں اسلام بھی
                  بہت سرعت کے ساتھ پھیلا۔ اس کی وجہ یہ نہیں تھی کہ لوگوں کو بزور
                  مسلمان بنایا گیا۔ بلکہ اصل وجہ یہ ہے کہ ان لوگوں کے اپنے سابقہ
                  حکمرانوں اور نئے حکمرانوں کے اخلاق و کردار میں اتنا واضح فرق
                  تھا کہ ان کے لیے اسلام قبول کرنا خوشی کا باعث بن گیا۔ تاریخ
                  شاہد ہے کہ اس پورے دور میں کسی ایک فرد کو بھی جبراً مسلمان
                  نہیں بنایا گیا۔ (جدید ذہن کے شبہات اور اسلام کا جواب ۴۶۔۸۴)
                  <br />
                  * عام طورپر یہ سمجھاجاتا ہے کہ اسلام میں جانداروں کی تصویروں
                  کی مکمل ممانعت ہے۔ یہ بات صحیح نہیں ہے۔ بلکہ اصل حقیقت یہ ہے
                  کہ شرک اور بے حیائی سے پاک ہر تصویر جائز ہے۔ خواہ وہ ہاتھ سے
                  بنی ہو، کیمرے سے لی گئی ہو یا سینما اور ٹی وی کے لیے ہو۔ اس کی
                  دلیل یہ ہے کہ اگر تصویر حرام ہوتی تو لازماً قرآن مجید میں یہ
                  حکم نازل ہوتا۔ قرآن مجید کے نزول کے وقت تصویریں موجود تھیں۔
                  چونکہ قرآن مجید ہی ہمارے لیے معیار ہے اور ہر حلال اور حرام کی
                  بنیاد قرآن مجید میں موجود ہے۔ اس لیے یہ ضروری تھا کہ تصویر کا
                  حکم صاف اور واضح الفاظ میں قرآن مجید میں آتا۔[اور قرآن مجید
                  میں کوئی ایسا حکم مو جود نہیں۔]
                  <br />
                  اس اصول کی روشنی میں جب ہم احادیث کے اس تمام ذخیرے کا مطالعہ
                  کرتے ہیں جو تصویر کے ضمن میں آتی ہیں۔ تو یہ حقیقت سامنے آتی ہے
                  کہ حضورؐ کے زمانے میں سارا معاشرہ شرک سے آلودہ تھا۔ قریش تو
                  خیر تھے ہی مشرکین، عیسائیوں کی عبادت گاہوں میں بھی حضرت عیسیٰ
                  ؑ اورحضرت مریمؑ کے بت اور تصویریں لگی ہوئی تھیں۔ خانہ کعبہ میں
                  گھوڑوں کی شکل میں ملائکہ کی تصویریں لگی ہوئی تھیں۔ جن کی عبادت
                  کی جاتی تھی۔ حضورؐ نے خود اس بات کو نہایت وضاحت سے بیان کر دیا
                  تھا۔ ہوا یوں کہ جب چند صحابیات (ابن ام حبیبہؓ اور ام سلمہؓ)
                  مکی دور میں قریش کے ظلم و ستم سے تنگ آکر حبشہ ہجرت کر گئیں۔ تو
                  وہاں انھوں نے عیسائیوں کی عبادت گاہیں دیکھیں جن میں تعظیم و
                  عبادت کی تصویریں تھیں۔ جب وہ وہاں سے واپس آئیں تو ایک موقع پر
                  انھوں نے اس کا ذکر آپؐ سے کیا۔ اس پر آپؐ نے فرمایا کہ ان لوگوں
                  کا حال یہ تھا کہ جب ان میں سے کوئی صالح شخص فوت ہو جاتا تو اس
                  کے مرنے کے بعد وہ اس کی قبر پر عبادت گاہ بناتے اور اس میں
                  عبادت کے لیے اس نیک فرد اور دوسرے لوگوں کی تصویریں رکھتے۔ یہ
                  لوگ قیامت کے دن اللہ کے نزدیک بدترین مخلوق قرار پائیں گے (صحیح
                  بخاری، کتاب الصلواۃ اور صحیح مسلم، کتاب المساجد)
                  <br />
                  ان تصویروں میں اصل جرم شرک تھا۔ جس کے بارے میں اسلام کا رویہ
                  بہت سخت ہے۔ اسی طرح ایک دفعہ [ام المومنین]حضرت عائشہؓ (حضورؐ
                  کی بیوی) نے دروازے پر ایک پردہ لٹکایا جس پر پردار گھوڑوں کی
                  تصویریں تھیں۔ پردار گھوڑے فرشتوں کی علامت تھے، جنہیں مشرکین
                  عبادت کے لیے استعمال کرتے تھے۔ چنانچہ حضورؐ نے حضرت عائشہؓ کو
                  حکم دیا کہ ان پردوں کو اتار دیا جائے۔(صحیح بخاری و صحیح مسلم،
                  کتاب اللباس)
                  <br />
                  چونکہ عبادت کے مقصد کے لیے تصویریں بنانے والے یہ عقیدہ رکھتے
                  تھے کہ یہ صالح لوگ زندہ اور حاضر و ناظر ہیں۔ خدا کے ہاں ان کے
                  سفارشی ہیں۔ ان کی بات سن سکتے اور ان کی بگڑی بنا سکتے ہیں۔ اس
                  لیے قیامت کے دن ان مصوروں سے کہا جائے گا کہ تمہارے خیال میں یہ
                  تمام بت اور تصویریں حاضر و ناظر ہیں۔ تو اب تم ان کو زندہ کر کے
                  دکھاؤ۔ ان کے غلط عقیدے کی بنا پر یہ چیز بطور سزا ان سے کہی
                  جائے گی۔ اور ظاہر ہے کہ اس وقت اپنے شرک کے لیے ان کے پاس کوئی
                  ثبوت نہیں ہو گا۔ چنانچہ ان تمام روایات میں اصل جرم تصویر کشی
                  نہیں بلکہ شرک ہے۔ یہی وجہ ہے کہ ان روایات میں تصویر اور مصور
                  کا ذکر ’’ال‘‘ یعنی اس معرفہ کے ساتھ آیا ہے۔ (بخاری، کتاب
                  التصویر)
                  <br />
                  چونکہ اس زمانے میں ہر بت کی پوجا کی جاتی تھی۔ ہر ابھری ہوئی
                  قبر کو پوجا جاتا تھا اور ہر ایسی تصویر کی تعظیم کی جاتی تھی۔
                  اس لیے حضورؐ نے حضرت علیؓ کو شرک کے یہ تمام مظاہر مٹانے کا حکم
                  دیا۔ چنانچہ حضرت علیؓ نے یہ کام کیا۔ (صحیح مسلم، کتاب الجنائز)
                  <br />
                  یہی وجہ ہے کہ مختلف اوقات میں جہاں جہاں شرک کا خطرہ نہیں تھا۔
                  وہاں آپؐ نے تصویر کی اجازت دی مثلاً ابو طلحہ انصاریؓ صحابی کی
                  یہ روایت کہ جس کپڑے پر تصویر کڑھی ہو (اور وہ شرک سے آلودہ نہ
                  ہو) اس کا پردہ لٹکانے کی اجازت ہے (بخاری کتاب اللباس) اسی طرح
                  جب حضرت عائشہؓ نے تصویر دار کپڑے کو پھاڑ کر اس سے گدا بنا لیا۔
                  (اور اس طرح اس کے شرک کا اظہار ختم کر دیا) تو حضورؐ نے اسے
                  بچھانے سے منع نہ کیا (مسلم کتاب اللباس) اسی طرح سالمؓ بن
                  عبداللہ (صحابی رسول) سے یہ روایت ہے کہ اس تصویر کی ممانعت ہے
                  جو (تعظیم و عبادت کے طور پر) نمایاں مقام پر نصب کی گئی ہو۔ اس
                  تصویر کی ممانعت نہیں جو کسی عام جگہ میں موجود ہو (مسند احمد)
                  <br />
                  درج بالا بحث سے یہ بات کھل کر واضح ہوتی ہے کہ اگر احادیث کا
                  قرآن مجید کی واضح ہدایت کی روشنی میں مطالعہ کیا جائے تو ان کا
                  صحیح مقام متعین ہو جاتاہے۔ ان کی صحیح اور تسلی بخش وضاحت بھی
                  سامنے آجاتی ہے اور مختلف روایات میں بظاہر جو تضاد نظر آتا ہے۔
                  اس کی بھی اطمینان بخش تشریح ہو جاتی ہے۔ چونکہ تصویر اتنی فطری
                  چیز ہے اس لیے اسے ناجائز سمجھنے والوں کو بھی اس معاملے میں بے
                  شمار مستثنیات دینی پڑی ہیں۔ مثلاً شناختی کارڈ اور پاسپورٹ کی
                  تصویر، کارٹون، دینی اور سیاسی جلسوں کی تصویر تعلیمی مقاصد کے
                  لیے تصویر وغیرہ وغیرہ بلکہ صحیح صورت حال تو یہ ہے کہ اس کے
                  ناجائز ہونے کا فتویٰ لگانے والوں نے بھی اسے عملاً ہر مباح کام
                  کے لیے جائز کر لیا ہے۔(جدید ذہن کے شبہات اور اسلام کا جواب
                  ۱۱۰۔ ۱۱۳)
                  <br />
                  * آج کے حالات میں اسلامی تہذیب اور مغربی تہذیب کے پہلو بہ پہلو
                  جینے کا سوال انتہائی اہمیت اختیار کر گیا ہے۔ جہاں یہ بات واضح
                  ہے کہ اسلام اور مغرب کے درمیان بنیادی فرق موجود ہے۔ دونوں کے
                  مقاصد زندگی اور اقدار زندگی میں فرق ہے، وہاں یہ بھی ایک حقیقت
                  ہے کہ کئی معاملات میں دونوں تہذیبوں کے درمیان محض غلط فہمیوں
                  کی ایک دیوار کھڑی ہے۔ خصوصاً ان معاملات میں جن کا تعلق ان
                  دونوں تہذیبوں کے پہلو بہ پہلو جینے سے ہے؛ اور اگر یہ غلط
                  فہمیاں نہ رہیں تو دونوں تہذیبوں کے درمیان ہم آہنگی اور پر امن
                  بقائے باہمی کی فضا جنم لے سکتی ہے۔
                  <br />
                  اس وقت مسلم دنیا میں مغرب کے متعلق تین قسم کے رویے موجود ہیں۔
                  ایک رویہ انتہا پسندانہ ذہنیت کا ہے۔ جو مغرب کو مجموعہ شر قرار
                  دیتا ہے۔ جس کے خیال میں سارا مغرب خلاف اسلام ہے۔ لہٰذا دونوں
                  کے درمیان ابدی دشمنی ہے۔ مسلم دنیا کے اندر یہ ذہنیت اقلیت میں
                  ہے۔ مگر اس کی مضبوط بنیادیں موجود ہیں۔ اسی ذہنیت کو مغرب عام
                  طور پر بنیاد پرستی کے نام سے پکارتا ہے۔
                  <br />
                  دوسرا رویہ مرعوبیت کا ہے۔ اس ذہنیت کے خیال میں اصل معیار مغرب
                  ہی ہے۔ مغربی اقدار ہی عظیم ترین ہیں۔ اس ذہنیت کے علم بردار
                  مسلم دنیا کے بالادست طبقات میں موجود ہیں۔ تاہم بلحاظ مجموعی یہ
                  طبقہ مسلم دنیا کے لیے اجنبی ہے۔ کیونکہ یہ ان کے اجتماعی ضمیر
                  کے خلاف ہے۔
                  <br />
                  تیسرا رویہ اعتدال کا ہے۔ اس ذہنیت کے خیال میں اگرچہ اسلام اور
                  مغرب کے درمیان بنیادی تضادات موجود ہیں؛ تاہم دونوں کے درمیان
                  غلط فہمیوں کی ایک ایسی دیوار بھی موجود ہے جسے دور کیا جا سکتا
                  ہے۔ اور اس طرح پرامن بقائے باہمی ممکن ہے۔ مسلم دنیا میں یہی
                  طبقہ بڑی اکثریت میں ہے۔
                  <br />
                  اس کے بالمقابل مغرب میں بھی اسلام اور مسلم دنیا کے متعلق تین
                  رویے موجود ہیں۔ایک رویہ اسلام دشمنی کا ہے۔ یہ طبقہ اسلام کو
                  مغرب دشمنی کا علم بردار گردانتا ہے۔ اس ذہنیت کے خیال میں مغرب
                  کی نجات صرف اس میں ہے کہ اسلام کو مکمل شکست سے دوچار کیا جائے۔
                  اور مسلم دنیا کے اندر صرف وہی قیادت ابھاری جائے جو آنکھیں بند
                  کر کے مغرب کی اقدار کی تقلید پر یقین رکھے۔ یہ طبقہ اسلام کے
                  خلاف کسی بھی پروپیگنڈا کا موقع ہاتھ سے نہیں جانے دیتا۔
                  <br />
                  دوسرا طبقہ اسلام دشمن تو نہیں، لیکن یہ مسلمانوں کو بلحاظ
                  مجموعی تاریک خیال، ترقی دشمن، جمہوریت دشمن اور پس ماندہ سمجھتا
                  ہے۔ یہ طبقہ اسلام کے متعلق گومگو کا شکار ہے۔ مغرب کا سیاسی
                  طبقہ یہی ذہنیت رکھتاہے۔ اس لیے مسلم دنیا کے متعلق اس طبقے کی
                  ترجیحات اور حکمت عملی تبدیل ہوتی رہتی ہے۔ اس طبقہ کے خیال میں
                  مسلمان حکومتوں کو اپنے پنجے کے اندر رکھنا ضروری ہے تاکہ وہ
                  مغرب کے لیے خطرہ نہ بننے پائیں۔
                  <br />
                  تیسرا طبقہ مسلم دنیا کے لیے ہمدردانہ ذہنیت رکھتا ہے۔ اس طبقہ
                  کے خیال میں مسلمانوں کو غلط سمجھا گیا ہے۔ وہ اتنے برے نہیں
                  جتنا کہ ذرائع ابلاغ انہیں پیش کرتا ہے۔ اس ضمن میں وہ مغرب کو
                  اپنے تضادات کی طرف بھی توجہ دلاتا ہے۔ مسلم دنیا کے ساتھ روا
                  رکھے جانے والے امتیازی سلوک کو افسوس کی نظر سے دیکھتا ہے۔ اور
                  سمجھتا ہے کہ اگر مغرب، مسلم دنیا کی طرف دوستی کا حقیقی ہاتھ
                  بڑھائے تو مسلمانوں کی طرف سے اسے مثبت جواب ملے گا۔(جدید ذہن کے
                  شبہات اور اسلام کا جواب ۱۳۱۔۱۳۳)
                  <br />
                  * تمام مغربی فلاحی مملکتیں ہر معاملے میں دوہرا معیار رکھتی
                  ہیں۔ اپنے ملکوں کے اندر وہ تمام معاملات میں سچائی، امانت،
                  دیانت اور محنت سے کام لیتی ہیں۔خود فلاحی مملکتیں آپس میں بھی
                  ایک دوسرے سے معاہدے کرتے وقت تمام اخلاقی اصولوں کا خیال رکھتی
                  ہیں، لیکن تیسری دنیا کے غیرترقی یافتہ ممالک سے سودے کرتے وقت
                  ان تمام اصولوں کو بالائے طاق رکھ لیاجاتا ہے۔ یہ بات کسی ثبوت
                  کی محتاج نہیں کہ ساری ملٹی نیشنل کارپوریشنز، تیسری دنیا کے ذمہ
                  دار ترین افراد کو رشوت کے ذریعے سے اپنا ہم نوا بناتی ہیں اور
                  سودوں، سمجھوتوں اور ٹھیکوں میں لاکھوں، کروڑوں ڈالر بطور کمیشن
                  ادا کرتی ہیں۔ اس سے بھی آگے بڑھ کر حقیقت یہ ہے کہ بعض ایسے
                  اصول، جن پر ساری دنیا کے اہل علم متفق ہیں، یعنی جمہوریت، اگر
                  کسی ملک میں جمہوریت کے ذریعے سے ایسے گروپوں کے برسراقتدار آنے
                  کا خدشہ ہو، جن کے عمرانی نظریات مغرب سے مطابقت نہ رکھتے ہوں،
                  تو وہاں بالواسطہ یا بلاواسطہ، آمریت اور ظلم و بربریت کی حوصلہ
                  افزائی کی جاتی ہے۔ یہ بات خصوصی طور پر عالم اسلام پر صادق آتی
                  ہے۔ جب برما میں انتخابات کے بعد فوجی حکمرانوں نے انتقال اقتدار
                  سے انکار کیا، تو تمام مغربی حکومتوں اور ذرائع ابلاغ نے اس کی
                  مذمت کی اور ابھی تک یہ معاملہ حکومتوں اور ذرائع ابلاغ کی حد تک
                  زندہ ہے، مگر دوسری طرف جب الجزائر میں، اسلامک سالویشن فرنٹ، نے
                  جمہوری انتخابات جیت لیے اور وہاں بھی فوج کی طرف سے انتقال
                  اقتدار سے انکار کیا گیا، تو مغربی حکومتوں اور ذرائع ابلاغ نے
                  بالکل چپ سادھ لی اور اس کی مذمت میں ایک دو نحیف و نزار آوازوں
                  کے علاوہ اور کوئی آواز نہیں اٹھی، یعنی بالواسطہ سب نے فوجی
                  اقدام کی حمایت کی۔
                  <br />
                  سوال یہ پیدا ہوتا ہے کہ مغرب ایسا کیوں کر رہا ہے؟ اور اس میں
                  اس کے کون سے ’’اسٹریٹجک‘‘ مفادات پوشیدہ ہیں، جن کی بنیاد پر وہ
                  اپنے ہی اصولوں کی دھجیاں اڑا کر بدترین منافقت کا ثبوت دے رہا
                  ہے۔ یہ ایک بڑا اہم سوال ہے، اس لیے کہ اسی سوال کے جواب پر مشرق
                  و مغرب کے آیندہ تعلقات کا انحصار ہے۔
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="فغانِ نیم شبی بے نوائے راز نہیں (حصہ اول)"
              />

              <div className="content">
                <h2>فغانِ نیم شبی بے نوائے راز نہیں (حصہ اول)</h2>
                جب ایک فرد کے لیے حالات ناسازگار ہوں تو دل چھوٹا نہ کیا جائے، اس
                بات پر یقین رکھا جائے کہ پروردگار تمام حالات کو دیکھ رہا ہے اور
                وہ جب چاہے گا حالات بدل دے گا۔ چنانچہ ٹھنڈے دل و دماغ سے کام لیا
                جائے۔ مایوسی اور گھبراہٹ سے بچا جائے۔ حالات کا اچھی طرح جائزہ لے
                کر، تدبیر کے ساتھ، بہترین پر امن حکمت عملی بنا کر اس پر عمل کیا
                جائے۔ اور اس عمل پر ثابت قدم رہا جائے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
