import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Thewarrior() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row">
          <div className="col-md-4" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="The Warrior who became a Martyr"
              />

              <div className="content">
                <h2>The Warrior who became a Martyr</h2>
                He lived like an undaunted warrior and died the death of a
                martyr. For the past few years, he gallantly stared death in its
                eyes as he continued to vehemently condemn terrorism and
                militancy. His armory was nothing but arguments drawn from the
                sources of Islam. Death threats could not swerve him from his
                stance and he would seldom pay heed to voices beckoning him to
                exercise care and caution. And then, the inevitable happened. He
                was shot at when he was busy seeing his patients at his clinic
                in Mardan on October the 2nd and could not survive the bullet
                wounds. His face still wore that typical beam – his vintage
                hallmark familiar to all those who knew him – when he lay
                enshrouded before his burial.
              </div>
            </div>
            <AsideLink />{" "}
          </div>
          <div className="col-md-8">
            <h1 className="title">The Warrior who became a Martyr</h1>
            <hr />
            <div
              className="fb-like"
              data-href="/the-warrior-who-became-a-martyr"
              data-width="300"
              data-action="like"
              data-show-faces="true"
              data-share="true"
            ></div>
            <hr />
            <div className="row">
              <div className="col-md-11">
                <p>
                  He lived like an undaunted warrior and died the death of a
                  martyr. For the past few years, he gallantly stared death in
                  its eyes as he continued to vehemently condemn terrorism and
                  militancy. His armory was nothing but arguments drawn from the
                  sources of Islam. Death threats could not swerve him from his
                  stance and he would seldom pay heed to voices beckoning him to
                  exercise care and caution. And then, the inevitable happened.
                  He was shot at when he was busy seeing his patients at his
                  clinic in Mardan on October the 2<sup>nd</sup> and could not
                  survive the bullet wounds. His face still wore that typical
                  beam – his vintage hallmark familiar to all those who knew him
                  – when he lay enshrouded before his burial.
                </p>

                <p>
                  Dr Muhammad Farooq Khan had a multi-faceted personality and
                  had immense reserves of energy. Truly, a man larger than life,
                  he stood like a rock for all what he believed. He was an
                  Islamic scholar, intellectual and a political activist; he
                  hosted many TV programs and also made several appearances as a
                  guest speaker on private and public TV channels; he was a
                  fiery speaker and also authored almost a dozen books on the
                  Islamic thought; but, above all, he was a humane and a humble
                  person: he personally provided for many destitute families and
                  took active part in welfare work; as a psychiatrist, he served
                  the medical profession with exemplary zeal and dedication.
                </p>

                <p>
                  Born in 1954 in a village of the Khaybar Pakhtoonkhwa
                  province, Dr Farooq graduated from Khyber Medical College in
                  1979. He did a diploma in Psychiatry in 1984 from the
                  University of Vienna. He remained an active member of the
                  Jamā‘at Islamī. In 1991, he came under the tutelage of Javed
                  Ahmad Ghāmidī, and ever since then remained a strong
                  proponent of the latter’s views. He also remained for several
                  years a member of the Board of Governors of Al-Mawrid, a
                  foundation for Islamic research and education. He was
                  currently the Vice Chancellor of the Islamic University in
                  Swat. Some of his important books include: “Translation and
                  Commentary of the Holy Qur’ān” (Urdu), “What is Islam?”
                  (Urdu), “Pakistan and the 21<sup>st</sup> Century” (Urdu),
                  “Islam and Some Important Contemporary Issues” (Urdu and
                  English), “Islam and Woman” (Urdu and English), “Muslim Ummah:
                  Way to Success”, “Jihād and Qitāl: Some Important Issues”
                  (Urdu), “Kashmir Issue: Perspective, Current Situation and its
                  Solution” (Urdu). His website www.drfarooqkhan.com sheds light
                  on his life and works.
                </p>

                <p>
                  Dr Farooq was a firm believer in open dialogue and debate with
                  all those who held contesting opinions. Unfortunately, his
                  adversaries resorted to foul instead of fair play. When they
                  could not counter his solid criticism on terrorism and
                  suicide-bombings, they unleashed a barrage of death-threats on
                  him and when they could not silence him with these threats,
                  they silenced him with their guns – a tragic yet enviable end
                  to this warrior who is now a martyr. Fare thee well!
                </p>
              </div>
            </div>
            <div className="meta">
              <div className="time">
                <span
                  className="glyphicon glyphicon-time"
                  aria-hidden="true"
                ></span>
                July 25, 2015 9:31pm
              </div>
            </div>
          </div>
        </article>
      </div>
      <hr />
    </div>
  );
}
