import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Drfarooqkhanshaheed() {
  return (
    <div style={{ display: "block" }}>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-farooq-khan-shaheed">ڈاکٹر فاروق خان شہید</a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>ڈاکٹر ممتاز احمد*</p>

                <p>
                  ایسے لوگ بہت کم ہوتے ہیں جن سے آپ زندگی میں شاید دو تین بار ہی
                  ملے ہوں، مگر ایسا محسوس ہوتاہے کہ آپ انھیں ایک مدّت سے جانتے
                  ہیں۔ نہ صرف جانتے ہیں، بلکہ اُن سے محبت کا ایک ایسا تعلق قائم
                  ہو جاتا ہے جو زندگی بھر اور اُن کی موت کے بعد بھی قائم رہتا
                  ہے۔ ڈاکٹر فاروق شہید ایسے ہی لوگوں میں سے تھے۔ میں اُن کے نام
                  سے تو واقف پہلے سے تھا، لیکن اُن سے پہلی ملاقات کئی سال ہوئے
                  اُن کے دولت کدہ پر مردان میں ہوئی۔ میں پشاور میں تھا اور میں
                  نے برادرِ عزیز سلیم صافی کی وساطت سے اُن سے ملاقات کی خواہش کا
                  اظہار کیا۔ میں نے ان کی خدمت میں عرض کیا کہ سلیم صافی کے ساتھ
                  شام کو مردان حاضر ہو جاؤں گا۔ ڈاکٹر فاروق صاحب نے فرمایا :آپ
                  کو آنے میں دقّت ہو گی ،میں گاڑی بھجوا دیتا ہوں۔
                  <br />
                  گرمیوں کے دن تھے۔ ہم لوگ پشاور سے چلے اورمغرب کے بعد پہنچے۔
                  ڈاکٹر صاحب نے اپنے خوبصورت اور خو ش ذوقی سے آراستہ لان میں
                  بیٹھنے کا انتظام کر رکھا تھا۔نہایت محبت کے ساتھ گلے لگا کر
                  استقبال کیا اور ایسا لگا کہ وہ کسی اجنبی سے نہیں، بلکہ مدّت سے
                  بچھڑے ہوئے کسی دوست سے معانقہ کررہے ہوں۔
                  <br />
                  روشن چہرہ، کشادہ پیشانی، آنکھوں میں سوچ کی گہرائی اور ایک ایسی
                  چمک جیسے کسی نئی دنیا کا سراغ پا لیا ہو۔ ہونٹوں پر سدا بہار
                  مسکراہٹ، سفید شلوار قمیص میں ملبوس ڈاکٹر صاحب پہلی ہی ملاقات
                  میں،بلکہ پہلی ہی نظر میں بھا گئے۔ ابتدائی تعارفی کلمات اور
                  مشروبات کے بعد ہم سب کھانے کے کمرے میں چلے گئے جہاں انھوں نے
                  خاصا اہتمام کر رکھا تھا۔ کھانے کے بعد ہم دوبارہ لان میں آ گئے
                  اور بہت دیر تک باتیں کرتے رہے۔ شاید ہی کوئی موضوع ایسا ہو جس
                  پرہم نے گفتگو نہ کی ہو امریکا، پاکستان، دین، سیاست، جماعت
                  اسلامی، جاوید احمد غامدی صاحب، صوبہ سرحد کی سیاست، دینی فکر کے
                  نئے رجحانات۔
                  <br />
                  اس روز کی ملاقات اور گفتگو کے بعد میرے ذہن پر ڈاکٹر صاحب کی
                  شخصیت اور فکر کا جو نقش بنا — اور وہ نقش ابھی تک باقی ہے — اُس
                  کے کچھ پہلو ایسے تھے جو ان کے علاوہ کم ہی دوسروں میں نظر آئے۔
                  <br />
                  ایک تو خود ان کی شخصیت تھی۔ مجھے ایسا لگا کہ اگر اس ملاقات میں
                  وہ ایک لفظ بھی نہ بولتے اور صرف سامنے بیٹھے رہتے تب بھی میرے
                  سفر کا یہ انعام کافی تھا۔ وقار کے ساتھ انکسار اور حِلم کا ایسا
                  امتزاج میں نے بہت کم لوگوں میں دیکھا ہے۔
                  <br />
                  دوسری بات جس نے مجھے بے حد متاثر کیا، وہ یہ تھی کہ ساری ملاقات
                  کے دوران میں مجھ سمیت حاضرینِ مجلس کے ساتھ اُن کا برتاؤ برابر
                  کے احترام کا تھا اور اس میں وہ لوگ بھی شامل تھے جو اپنے آپ کو
                  اُن کا شاگرد کہتے تھے۔پشتون معاشرے میں ویسے بھی معاشرتی اونچ
                  نیچ کا تصوّر اُس وقت ختم ہو جاتا ہے جب تعلق کی نوعیت میزبان
                  اور مہمان کی ہوتی ہے، لیکن ڈاکٹر فاروق خان شہید کی شخصیت کا یہ
                  پہلو مجھے اپنے تجربے کی حد تک بہت نمایاں اور بھلا لگا۔
                  <br />
                  تیسری چیز جس کا تاثر ابھی تک قائم ہے، وہ ان کی گفتگو کا انداز
                  تھا — اور یہ بات بعد میں اُن کی تحریروں اور پبلک تقریروں میں
                  بھی بہت نمایاں نظر آئی۔ اُن کی گفتگوسمجھنے اور سمجھانے کے
                  انداز میں اور اپنے سارے جذبات کے باوجود ایسے دھیمے لہجے میں
                  ہوتی تھی جس میں مخاطب کے احترام کا پورا پورا لحاظ ہوتا تھا۔ نہ
                  کوئی ادعا تھا، نہ اپنے علم اور مطالعہ کا غرور۔ اپنے خیالات کے
                  اظہار میں الفاظ کا محتاط انتخاب اورپھر اعتدال اور توازن کے
                  ساتھ اپنی رائے کا بیان۔ مجھے یاد ہے کہ انھوں نے اپنی ساری
                  گفتگو میں کسی ایک شخص کے بارے میں بھی کوئی ایسی بات نہیں کہی
                  جس سے سوءِ ادب کا پہلو نکلتا ہویا جس سے کسی کی مذمّت اور تذلیل
                  ہوتی ہو۔ اپنے مخالفین یا اپنے نظریات کے مخالفین کے لیے بھی اُن
                  کے دل میں خیرخواہی اور اصلاح کا جذبہ تھا اور وہ یہ سمجھتے تھے
                  کہ جو لوگ اسلام کا نام لے کر شدت پسندی کے راستے پر چل نکلے
                  ہیں، اُن کو دلیل کے ساتھ اور دین اور ملّت کی خیرخواہی کا واسطہ
                  دے کر راہِ راست پر لایا جا سکتا ہے اور اس مقصدکے لیے بعد میں
                  وہ ایسے سرگرم ہوئے کہ مخالفین اُن کی کوششوں کی کامیابی کے
                  امکانات سے خوف زدہ ہو کر، اُن کی جان لینے پر آمادہ ہو گئے۔
                  <br />
                  اسی ملاقات میں، میں نے ڈاکٹر صاحب شہید سے جماعتِ اسلامی سے اُن
                  کی وابستگی اور پھر علیحدگی کے بارے میں بھی کچھ سوالات کیے۔
                  انھوں نے جماعتِ اسلامی کے ساتھ اپنے تعلق کا تفصیل سے ذکر کیا۔
                  اس روداد میں جماعت کے کئی اکابرین کا تذکرہ بھی آیا اور جماعت
                  کی بعض پالیسیوں سے اختلافات کی نوعیت بھی انھوں نے بیان کی،
                  لیکن اس ساری گفتگو میں ایک بار بھی انھوں نے جماعت کے اپنے سابق
                  ساتھیوں کے بارے میں کوئی ایسا کلمہ نہیں کہا جس سے اُن کی اہانت
                  یا بے ادبی ہوتی ہو۔
                  <br />
                  ڈاکٹر فاروق شہید سے دوسری ملاقات کا موقع اس وقت ملا جب ۲۰۰۷ء
                  میں ،میں نے اقبال انٹرنیشنل انسٹی ٹیوٹ کے زیرِ اہتمام ’’اسلام
                  اور جدیدیت‘‘ پر ایک بین الاقوامی کانفرنس کا اہتمام کیا۔ اس
                  کانفرنس میں پاکستان کے علاوہ امریکہ،برطانیہ، بھارت اور جنوبی
                  افریقہ کے دانش ور اور پروفیسر حضرات بھی مدعّو تھے۔ مجھ سے
                  زیادہ میرے بیٹے جنید احمد کی خواہش تھی کہ ڈاکٹر فاروق صاحب
                  کوبھی ایک سیشن میں مقرر کے طور پر مدعو کیا جائے۔ میں کانفرنس
                  کی تیاریوں کے دوران میں امریکہ میں تھا، چنانچہ جنید نے خود ہی
                  پہلے ای میل اور پھر فون پر میری جانب سے اُن سے رابطہ قائم کیا
                  اور انھیں کانفرنس میں شرکت کے لیے آمادہ کر لیا۔ میں نے پاکستان
                  پہنچ کر انھیں فون کیا اور کانفرنس میں شرکت پر آمادگی کے لیے
                  اُن کا شکریہ ادا کیا۔ کہنے لگے کہ عزیزی جنید نے جس محبت سے
                  دعوت دی تھی میں بھلا کیسے انکار کر سکتا تھا۔ اُن کی شہادت کے
                  بعد جنید نے مجھے بتایا کہ اِس کانفرنس کے بعد بھی وہ مسلسل فون
                  اور ای میل کے ذریعے سے اُن سے رابطے میں رہا اور وہ دعاؤں اور
                  شفقت کے ساتھ بلاتاخیر اس کی ای میل کا جواب بھی دیتے رہے اور
                  فون پر اس کی انٹ شنٹ باتیں سن کر اُس کی حوصلہ افزائی بھی کرتے
                  رہے۔ اس سال کے آغاز میں، جب وہ ایک اور کانفرنس کے سلسلے میں
                  میری دعوت پر تشریف لائے تو میں نے اُن سے کہا کہ جنید کی جینز
                  میں میری بہت سی خامیوں کے ساتھ ایک اچھی بات بھی منتقل ہوئی ہے
                  اور وہ ہے آپ کی محبت۔ میری یہ بات سن کر بہت محظوظ ہوئے اور
                  جنید کے لیے بہت دُعائیں کیں۔
                  <br />
                  اس کانفرنس کے جس سیشن میں اُن کی تقریر تھی ، اُس میں کچھ
                  Panelists ایسے بھی تھے جن کے اسلام کے بارے میں خیالات عام
                  معنوں میں ’’اجماع‘‘ سے کچھ ہٹ کر تھے۔ ڈاکٹر فاروق صاحب نے
                  نہایت مدلل اور Articulate انداز میں اسلام اور جدیدیت کے تعلق
                  یا تصادم سے پیدا ہونے والے مسائل پر اپنی بات پیش کی۔ لیڈز
                  یونیورسٹی کے پروفیسر سلمان سیّد نے سیشن کے بعد اپنے مخصوص
                  Understatement کے انداز میں مجھ سے کہاکہ یہ آپ کے ڈاکٹر فاروق
                  صاحب گہری سوچ والے آدمی لگتے ہیں۔ اس سارے سیشن میں مجھے صرف
                  اُن کی باتوں میں ایک نئی اور آزاد سوچ نظر آئی۔
                  <br />
                  غالباً اُسی سال امریکہ کی ہارورڈ یونیورسٹی میں اسلامک اسٹڈیز
                  کے پروفیسر ڈاکٹر فرید اسحاق جن کا تعلق جنوبی افریقہ سے ہے،
                  میری دعوت پر توسیعی لیکچرز کے سلسلے میں تشریف لائے۔ فرید
                  افریقی ملکوں میں تیزی سے پھیلتی ہوئی موذی بیماری AIDS کی روک
                  تھام اور AIDS کے مسلمان مریضوں کی دیکھ بھال اور علاج کے ایک
                  ادارے Positive Muslims کے لیے خاصے سرگرم تھے۔ پاکستان آئے تو
                  انھوں نے خواہش ظاہر کی کہ وہ علماء اور اسلامی علوم کے ماہرین
                  سے مل کر مسلمان معاشروں میں AIDS کی روک تھام اور AIDS کے
                  مریضوں کے لیے ایک ہمدردانہ رویّے کا شعور پیدا کرنے کے لیے
                  ملاقاتیں کرنا چاہتے ہیں۔ میں نے اس سلسلے میں اسلام آباد، لاہور
                  اور کراچی میں کئی مشہور اور صاحبِ علم علما سے اُن کا رابطہ
                  کرایا۔ جنید نے فون کر کے ڈاکٹر فاروق صاحب سے فرید کاتعارف
                  کرایا اور اُن سے ملاقات کی درخواست کی۔ میں نے جنید سے کہا کہ
                  تم نے مجھ سے کہا ہوتا، میں خود فون کرکے اُن سے درخواست کرتا۔
                  جنید نے کہا: ابو آپ کی ضرورت ہی نہیں پڑی، انھوں نے ڈاکٹر فرید
                  اسحاق سے نہ صرف ملاقات کا وقت دے دیا ہے، بلکہ انھیں اپنے گھر
                  مردان آنے کی دعوت بھی دے دی ہے اور فرید رات بھی اُنھی کے ہاں
                  قیام کریں گے۔ یہ تھے ہمارے ڈاکٹر فاروق خان۔ ایک طالب علم کے
                  فون پر اور نہایت مختصر نوٹس پر، اُنھوں نے نہ صرف ملاقات کے لیے
                  وقت نکالا، بلکہ تھوڑی دیر بعد فون کر کے بھی کہا کہ ڈاکٹر فرید
                  اسحاق اسلام آباد سے مردان آئیں گے اور اُسی روز شام کو واپسی ہو
                  گی تو تھک جائیں گے، لہٰذا میں نے اُن کے رات کے قیام کا بھی
                  انتظام کر لیا ہے۔
                  <br />
                  فرید اسحاق، ڈاکٹر فاروق سے پہلے اسلام آباد، لاہور اور کراچی کے
                  کئی علما سے مل چکے تھے۔ جب مردان سے واپس آئے تو ڈاکٹر فاروق
                  صاحب کے گرویدہ ہوچکے تھے۔ نہ صرف اُن کی مہمان نوازی اور حسنِ
                  اخلاق کے گرویدہ، بلکہ اُن کے علم اور اُن کی ذہانت کے بھی۔ فرید
                  اسحاق نے کہا کہ ڈاکٹر فاروق صاحب اور دیگر علما کے درمیان جو
                  واضح فرق اُنھیں نظر آیا، وہ یہ تھا کہ ڈاکٹر صاحب فقہی مخمصوں
                  میں پڑنے کے بجائے براہِ راست قرآنِ پاک سے رہنمائی لیتے تھے اور
                  بڑی بصیرت کے ساتھ قرآنی تعلیمات کا تعلق آج کے مسائل سے جوڑتے
                  تھے۔ فرید اسحاق خود قرآن کے اسکالرہیں اور قرآنِ پاک کی تعلیمات
                  پر دو نہایت اہم کتابوں کے مصنف ہیں، اور بالعموم دوسروں کی
                  تعریف کرنے میں کچھ زیادہ سخی بھی نہیں ہیں۔ لیکن ڈاکٹر فاروق
                  صاحب شہید کے بارے میں اُنھوں نے میرے اور جنید کے سامنے یہ کہا
                  کہ مردان کی ملاقات اُن کے دورۂ پاکستان کا حاصل تھی۔ فرید اسحاق
                  بار بار جس بات کا ذکر کر رہے تھے، وہ یہ تھی کہ پاکستان بھر کے
                  اسلامی اسکالرز اور علما میں ڈاکٹر فاروق صاحب واحد آدمی تھے
                  جوAIDS کے مریضوں کے لیے ہمدردی کا جذبہ رکھتے تھے۔ فرید کے بقول
                  اِس میں کچھ دخل تو اُن کی پیشہ ورانہ تربیت کا بھی تھا لیکن اصل
                  محرک اُن کا گہرا اسلامی جذبہ تھا جو کمزور اور دُکھی انسانوں سے
                  محبت کرنا اور اُن کی د ل داری کرنا سکھاتا تھا۔
                  <br />
                  دسمبر۲۰۰۷ء میں عزیزی خورشید احمد ندیم کی درخواست اور ہم سب کی
                  خواہش پر، وہ میری بھانجی اور بھانجے کے نکاح کی تقریب میں
                  راولپنڈی تشریف لائے۔ ڈاکٹر صاحب نے دونوں کے نکاح پڑھائے اور
                  نکاح کی تقریب کے بعد ہمارے سب اہلِ خاندان کی موجودگی میں اسلام
                  میں خاندانی زندگی کی اہمیت اور میاں بیوی کے باہمی تعلقات پر
                  ایک نہایت دل نشیں تقریر بھی کی۔
                  <br />
                  اُن سے آخری ملاقات جون ۲۰۱۰ء میں ہوئی جب وہ ایک بار پھر میری
                  درخواست پر اقبال انسٹی ٹیوٹ کی بین الاقوامی کانفرنس میں تشریف
                  لائے جو سوات میں نظام عدل اور نفاذِ شریعت کے موضوع پر تھی۔ بہت
                  محبت سے ملے اور اس بات پر نہایت خوشی کا اظہار کیا کہ میں اب
                  امریکہ سے آ گیا ہوں۔ میں نے کانفرنس میں شرکت پرشکریہ ادا کیا
                  تو کہا کہ آپ بُلائیں اور میں نہ آؤں، یہ کیسے ہو سکتا ہے؟ حسبِ
                  معمول، کانفرنس میں اُن کی تقریر اپنے تجزیے اور جذبے، دونوں
                  اعتبار سے ممتاز تھی۔ اُنھوں نے سوات اور دیر کے سماجی، مذہبی
                  اور سیاسی پس منظر میں اُن عوامل پر روشنی ڈالی جن کے نتیجے میں
                  ’’نفاذِ شریعت‘‘ کی تحریک نے زور پکڑا اور پھر بعد میں یہ تحریک
                  شدت پسندی کے راستے پر چل پڑی۔تجزیے کے ساتھ ساتھ اُنھوں نے
                  مسئلہ کے حل کے لیے مثبت تجاویز بھی پیش کیں۔ اُن کی اِس تقریر
                  کا انگریزی ترجمہ، ان کی ایک خوبصورت تصویر کے ساتھ، ہمارے اقبال
                  انسٹی ٹیوٹ کی کتاب ’’Toward Revisiting the Deabate on Shariah
                  ‘‘میں شامل ہے۔
                  <br />
                  کوئی ڈیڑھ ماہ پہلے میں نے عزیزی ڈاکٹر حسن الامین سے کہا کہ
                  ہمارے اقبال انسٹی ٹیوٹ نے Grassroots کی سطح پر Outreach (عام
                  آدمی تک رسائی ) کا جو پروگرام شروع کیا ہے، جس کے تحت ہم چھوٹے
                  چھوٹے شہروں میں سیمینارز منعقد کررہے ہیں، اس پروگرام کے لیے
                  ہمیں مقررین کا ایک پینل( Panel )طے کرلینا چاہیے۔ ہمارا خیال
                  تھا کہ اِس Panel میں صرف وہی حضرات شامل ہوں جو نہایت عام فہم
                  اور سمجھنے سمجھانے کے انداز میں ایک عام آدمی کو سامنے رکھ کر
                  بڑے موضوعات (Big issues) پر گفتگو کر سکیں۔ جو اونچے منبر پر
                  براجمان ہو کر اپنی علمیت کا رُعب ڈالنے اور سامعین کو جاہل سمجھ
                  کر، اُن کو ڈانٹ ڈپٹ کر وعظ کرنے کی بجائے محبت اور خیرخواہی کے
                  جذبے کے ساتھ اُن سے ’’گفتگو‘‘ اور مکالمہ کریں اور اسلام اور
                  ملک و ملت کو پیش آنے والے بڑے بڑے مسائل پر اُن کو شریک(engage
                  )کریں۔ حسن الامین اور میری،دونوں کی متفقہ رائے میں ڈاکٹر فاروق
                  خان شہید اِس Panel میں سرِفہرست تھے۔ میں نے عزیزی حسن الامین
                  سے کہا کہ آپ ڈاکٹر صاحب سے بات کریں اور اُن سے وعدہ لیں کہ وہ
                  ہمارے اس پروگرام میں مستقلاً شریک ہوں گے اور ہاں، ڈاکٹر صاحب
                  سے یہ بھی پوچھیے کہ ہم ویسی ہی شان دار دعوت کے لیے اُن کے دولت
                  کدہ پر کب حاضر ہوں، جیسی دعوت اُنھوں نے مجھ سے پہلی ملاقات پر
                  کی تھی۔حسن الامین نے بتایا کہ ڈاکٹر صاحب نے پہلی درخواست منظور
                  کر لی اور دوسری کے بارے میں فرمایا کہ ممتاز صاحب جب بھی آنا
                  چاہیں، مجھے خوشی ہوگی اور دعوت بھی ویسی ہی ہوگی۔
                  <br />
                  لیکن آدمی کی سب خواہشیں کب پوری ہوتی ہیں؟
                  <br />
                  ایک رسمی جملہ ہے کہ فلاں کی موت سے جو خلاء پیدا ہو گیا ہے،
                  اُسے پُر کرنا مشکل ہے۔ میں پورے یقین اور صدقِ دل کے ساتھ
                  سمجھتا ہوں کہ یہ جملہ اُن کے بارے میں استعمال کروں تو یہ رسمی
                  جُملہ نہیں ہو گا۔
                  <br />
                  اللہ تعالیٰ اُن کی شہادت کو قبول فرمائیں اور پاکستان میں اسلام
                  اورملکی سلامتی، امن اور ترقی کے وہ راستے روشن ہوں جن کے لیے
                  ڈاکٹر فاروق خان شہید خواہش مند اور کوشاں تھے۔ آمین
                </p>

                <p>
                  * ڈائریکٹر، اقبال انٹرنیشنل انسٹی ٹیوٹ فار ریسرچ اینڈ ڈائیلاگ،
                  انٹرنیشنل اسلامی یونیورسٹی،اسلام آباد۔
                </p>
              </div>

              <hr />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر فاروق خان شہید"
              />

              <div className="content">
                <h2>ڈاکٹر فاروق خان شہید</h2>
                ایسے لوگ بہت کم ہوتے ہیں جن سے آپ زندگی میں شاید دو تین بار ہی
                ملے ہوں، مگر ایسا محسوس ہوتاہے کہ آپ انھیں ایک مدّت سے جانتے
                ہیں۔ نہ صرف جانتے ہیں، بلکہ اُن سے محبت کا ایک ایسا تعلق قائم ہو
                جاتا ہے جو زندگی بھر اور اُن کی موت کے بعد بھی قائم رہتا
                ہے۔ڈاکٹر فاروق شہید ایسے ہی لوگوں میں سے تھے۔ میں اُن کے نام سے
                تو واقف پہلے سے تھا، لیکن اُن سے پہلی ملاقات کئی سال ہوئے اُن کے
                دولت کدہ پر مردان میں ہوئی۔ میں پشاور میں تھا اور میں نے برادرِ
                عزیز سلیم صافی کی وساطت سے اُن سے ملاقات کی خواہش کا اظہار کیا۔
                میں نے ان کی خدمت میں عرض کیا کہ سلیم صافی کے ساتھ شام کو مردان
                حاضر ہو جاؤں گا۔ ڈاکٹر فاروق صاحب نے فرمایا :آپ کو آنے میں دقّت
                ہو گی ،میں گاڑی بھجوا دیتا ہوں۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
