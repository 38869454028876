import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Rahewafa() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/rah-e-wafa-per-chalne-wale">راہِ وفا پر چلنے والے</a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>ساجد حمید</p>

                <p>
                  ماہِ شوال کے آخری ایام میں ، ہمارے بھائی ڈاکٹر محمدفاروق خان
                  مرحوم نے جامِ شہادت نوش کیا، اور دارِ فانی سے رخصت ہو گئے۔۱؂
                  راہِ حق پر چلنے والوں کو کبھی کبھی یہ جام نوش کرنا پڑتا ہے، اس
                  لیے کہ اس دنیا کے اسٹیج پر خیر و شر کی باہم ستیزہ کاری ازل سے
                  جاری ہے۔چراغِ مصطفوی سے شرارِ بولہبی مسلسل برسرپیکار ہے۔ جب
                  بھی یہ چراغ کسی نے روشن کیا ہے، لوگوں نے سرتوڑ کوششوں سے اسے
                  بجھانے کی کوشش کی ہے اس راہ کی سنتِ متواترہ یہی ہے:
                </p>

                <blockquote>
                  <p>
                    <b>
                      راہِ وفا میں ہر سو کانٹے، دھوپ زیادہ سائے کم
                      <br />
                      لیکن اس پر چلنے والے خوش تھے بہت، پچھتائے کم
                      <br />
                      دھیمی دھیمی چال میں ہم کو راہ گزر طے کرنی ہے
                      <br />
                      تیز روی پر ناز تھا جن کو منزل پر وہ آئے کم
                      <br />
                      قول و عمل کی بات نہ پوچھو، ان دونوں میں فرق بہت ہے
                      <br />
                      قول و عمل میں ایک ہوئے جو، اس دنیا نے پائے کم۲؂
                    </b>
                  </p>
                </blockquote>

                <p>
                  ڈاکٹر صاحب کی شہادت کے اس موقع پر میں چاہتا ہوں کہ جدید و قدیم
                  فکر کے حاملین کی خدمت میں چند معروضات عرض کروں۔دینِ متین کے
                  احکام میں سے ایک جامع اور بڑا حکم دین کی مدد کرنے کا ہے، جسے
                  اصطلاح میں نصرتِ دین کہتے ہیں۔جب دین پر کوئی افتاد آجائے تو
                  اہل ایمان سے قرآن ان کی استعداد کے لحاظ سے دین کی حمایت کا
                  مطالبہ کرتا ہے۔سورہ صف میں اس عمل کو اللہ کی مدد کہا گیا ہے
                  ۔۳؂ دین کی یہ نصرت کسی بھی صورت میں مطلوب ہوسکتی ہے۔ جیسے
                  پیغمبر اسلام صلی اللہ علیہ وسلم کے زمانے میں نصرتِ دین کی ایک
                  سب سے نمایاں صورت یہ تھی کہ پیغمبر کا ساتھ دیا جائے تاکہ خدا
                  کا منصوبۂ رسالت روبہ عمل ہو جائے۔رسول کے اس ساتھ دینے کو دو
                  نام دیے گئے:
                </p>

                <p>
                  ۱۔ ساتھ دینا ،یعنی صحابی ہونا
                  <br />
                  ۲۔ مدد کرنا ،یعنی انصاری ہونا۔
                  <br />
                  تمام صحابہ اسی ساتھ دینے کی وجہ سے صحابی کہلائے اور سلطان نصیر
                  کی رعایت سے مدینہ کے صحابہ انصار بھی کہلائے رضی اللہ عنہم
                  ورضوا عنہ۔ ہمیں اس زمانے میں رسول کا ساتھ دینا تو میسر نہیں ،
                  لیکن ہم دین کے خد مت گار تو ہوسکتے ہیں، اس لیے کہ یہ پکار تو
                  آج بھی اسی طرح موجود ہے کہ ’یَاأَیُّہَا الَّذِینَ آمَنُوا
                  کُونُوا أَنصَارَ اللّٰہِِ...‘
                  <br />
                  انگریز کی آمد سے ایک دفعہ پھر فلسفہ و فکر کے میدان میں دین پر
                  حملہ ہوا ۔اس کے عقائد اور قوانیں معرض اعتراض میں آگئے۔اس صورت
                  میں ایسے اذہان کی ضرورت ہوئی جو اس میدان میں اتر کر دین کا
                  دفاع کریں۔ دور اول میں رسول نے پکارا تھا کہ کون میرا ساتھ دے
                  گا؟آج دین پکارر ہا ہے کہ کون ہے جومجھے سمجھ کر دوسروں کو
                  سمجھائے گا!پچھلی دو صدیوں میں جس کے حساس کانوں نے اس پکار کو
                  سنا ،اُسی نے اس پر لبیک کہی، اور میدان میں کود پڑا۔یہ کودنے
                  والے اکیلے بھی تھے اور اکٹھے بھی۔ کیفیت وہی تھی جو کسی حماسی
                  نے ایک جنگجو قبیلے کی توصیف میں بیان کی ہے کہ:
                </p>

                <blockquote>
                  <p>
                    <b>
                      اذا لقام بنصر ی معشر خشن
                      <br />
                      عند الحفیظۃ ان ذو لوثۃ لانا
                      <br />
                      قوم اذا الشر ابدی ناجزیہ لہم
                      <br />
                      طاروا الیہ زرفات ووحدانا
                    </b>
                  </p>
                </blockquote>

                <blockquote>
                  <p>
                    ’’تو تب اس ناموس کی حفاظت کے موقع پر ایک سخت جان لشکر میری
                    مدد کے لیے اٹھ کھڑا ہوتا،جبکہ بزدل جھک پڑتے ،یہ وہ لوگ ہیں
                    کہ جب شر ان پر غراتا ہے تو یہ ٹولیوں میں اور اکیلے اکیلے اس
                    پر پل پڑتے ہیں۔‘‘
                  </p>
                </blockquote>

                <p>
                  اس طرح سے مغربی فکر کے غلط اعتراضات کا جواب دینے کے لیے جب لوگ
                  میدان میں اترے توجو ہتھیار ان کے ہاتھ میں تھا انھوں نے استعمال
                  کیا۔اس لیے کسی کا ہاتھ صحیح پڑا اور کسی کا غلط۔لیکن ان غلطیوں
                  کے ساتھ یہ حقیقت ہے کہ سب کی مساعی وجہد کا نشانہ و ہدف ایک تھا
                  کہ نئے فکر کی کاٹ سے اسلام کو بچایا جائے۔جو لوگ علمی میدان میں
                  خدمت دین کے لیے اترے انھیں نئی فکر کا جواب دینے کے لیے ، بعض
                  ایسی باتیں کرنا پڑیں جو نئی تھیں ، آیات کی قدیم تفسیر ، احادیث
                  کی پرانی شرح ،فقہ میں اسلاف کے فتووں سے ہٹ کر باتیں سامنے آنے
                  لگیں ۔ جس سے پرانے مسلکی، کلامی ، تفسیری تصورات وغیرہ ٹوٹنے
                  لگے ۔ان چیزوں کی وجہ سے اس میدان میں اترنے والوں کو ان لوگوں
                  نے برا سمجھنا شروع کردیا، جنھوں نے نئے فکری حملے میں جواب دینے
                  کے بجائے یہ روش اختیار کی کہ مغرب کفر لے کر آیا ہے اس لیے کان
                  منہ لپیٹ کر اپنے ایمان کو بچاؤ۔ چنانچہ کفر، زندیقی اور مغرب
                  زدگی کے فتوے، میدان میں اترنے والوں پر لگنے لگے اور ان کے خلاف
                  تنقیدی مضامین ، کتابیں اور ہجویہ نظمیں منظر عام پر آنے لگیں۔
                  <br />
                  ان نئی باتوں میں بلاشبہ کچھ صحیح اور کچھ غلط تھیں، لیکن اس
                  اندھی مخالفت کے بجائے ہونا یہ چاہیے تھا کہ میدان میں اترنے
                  والوں کی مدد کی جاتی اور انھیں ہمدردی ،حکمت، موعظت اور مجادلہ
                  احسن کے ذریعے سے سمجھا کر ان کی غلطی دور کی جاتی تاکہ وہ بہتر
                  طور پر مغربی فکر کا مقابلہ کرتے۔لیکن افسوس ایسا نہ ہو سکا۔
                  لیکن اب یہ ہو گیا ہے کہ اس میدان میں اترنے والا ہر شخص برا
                  ہے۔جو شخص نئے دور کے لحاظ سے بات کرتا ہے، اسے ماڈرن، مغرب زدہ،
                  ماڈریٹ، لبرل کے القابات سے نواز کر عوام میں ناپسندیدہ بنادیا
                  جاتا ہے۔
                  <br />
                  ایسی ناپسندیدگی کا نشانہ وہ مخلص لوگ بھی بن رہے ہیں، جن کی
                  خدمات کا اعتراف آج کا روایتی مذہبی تو شاید نہ کرے ،مگر کل کا
                  شخص ضرور کرے گا۔ اس کی ایک اچھی مثال مودودی صاحب مرحوم ہیں ،
                  جن کی جم کر مخالفت کی گئی ، لیکن اب ان کے مخالفین انھی کی
                  کتابوں سے اکتسابِ علم کرتے ہیں۔
                  <br />
                  ایسے لوگوں کے خلاف ناپسندیدگی کی فضا ، نفرت اور دشمنی میں بدل
                  گئی ہے، لہذا کچھ لوگ اصحابِ کہف کی طرح روپوشی، کچھ شہدا کی طرح
                  جام شہادت نوش کرنے ،اور کچھ ہجرت کرنے پرمجبور کردیے جاتے
                  ہیں۔ذرا غور کریں تو اس عمل میں ہوتا کیا ہے ، ہوتا صرف یہ ہے کہ
                  کچھ اسلامی بھائی اپنے ایک اسلامی بھائی کو مارڈالتے ہیں، جو ان
                  کے طریقے سے ہٹ کر اسلام کی خدمت کررہا ہوتا ہے۔اس کا جرم صرف یہ
                  ہوتا ہے کہ وہ دوسروں سے مختلف ہوتا ہے، تو کیا تاریخ میں ایسا
                  کبھی نہیں ہوا۔ حنفی، باقی امتیوں سے کچھ مختلف ہوئے ، مالکی
                  حنفیوں اور باقی امتیوں سے مختلف ہوئے ، پھر شافعی بھی ایسے ہی
                  ظاہر ہوئے ، ہمارے دور میں آکر پاک و ہند میں دیوبندی،
                  بریلوی،اہل حدیث، اور جماعت اسلامی وغیرہ بھی ایسے ہی امت کے بڑے
                  دھار ے سے مختلف نظر آنے والے لوگ ہیں ،جو پچھلی دو صدیوں میں
                  ظاہر ہوئے۔اس لیے یہ اگر جرم ہے کہ آدمی باقی امت سے مختلف ہو تو
                  یہ اس وقت کے تمام مکاتبِ فکر کا مشتر کہ جرم ہے۔لہذا میں(قدیم ،
                  جدید اور ماضی قریب کے قدیم)اب مکاتب فکر کی خدمت میں چند
                  سفارشات پیش کرنا چاہتا ہوں تاکہ دونوں گروہ اپنے اپنے رویے پر
                  نظر ثانی کریں :<br />
                  ۱۔ قدیم جدید کی صفوں میں آکر ان کے مسائل کو سمجھیں اور جدید کی
                  غلطی کی اصلاح حکمت ،محبت اور احترام سے کرنے کی کوشش کریں۔
                  جدیدقدیم کے اعتراضات کو توجہ سے سنیں ، اپنی غلطی ہو تو اسے
                  درست کریں ، ورنہ قدیم علما کو حکمت، محبت اور احترام کے ساتھ
                  اپنی بات سمجھائیں۔ یوں مل کر نئے چیلنج کا سامنا کریں۔کیونکہ مل
                  کر جب دشمن سے مقابلہ ہو گا تو زیادہ مؤثراور دیرپا ہوگا۔
                  <br />
                  ۲۔ کسی فریق کی غلطی کو پہلے ہی مرحلے پر کفرایمان کا مسئلہ نہ
                  بنائیں۔ اس لیے کہ غلطی کھانا ایمان کے منافی نہیں ہے۔ بندہ مومن
                  سے بھی غلطی ہو جاتی ہے۔بات کو سمجھنے اور سمجھانے میں غلطیاں ہو
                  جاتی ہیں۔ ایسی ہی غلطیاں دین کو سمجھتے سمجھاتے وقت بھی ہو جاتی
                  ہیں۔اسے برادرانہ سطح کا مسئلہ بنا کر صحابہ کے اسوہ پر چل کر حل
                  کیا جائے۔ ان میں سے کسی سے غلطی ہو جاتی تو وہ بات چیت کرتے،
                  یہاں تک کہ دوسرا کہتا کہ اللہ نے میرا سینہ اس بات کے لیے کھول
                  دیا اور میں نے اس کی بات مان لی۔ گناہ ہو جاتا تو ایک دوسرے کو
                  توبہ کے لیے کہتے ، کسی کو کفر ایمان کے فتوے نہ دیتے۔
                  <br />
                  ۳۔ دونوں فریقوں کو مستقل طور پر اس بات کے لیے ہوشیار رہنا
                  چاہیے کہ ہماری دین کے ساتھ دوستی بے وقوف کی دوستی نہ ہو۔ جو
                  دین کو فائدہ پہنچانے کے بجائے الٹا نقصان پہنچا دے۔ مثلا یہ ہو
                  سکتا ہے کہ ایک مسئلہ میں واقعی ہماری بات غلط ہو اور دوسرے کی
                  درست ہو۔ اگر ہم اپنی غلط بات پر اصرار کرتے رہیں گے تو اس سے
                  دین کو نقصان ہو گا، اس لیے کہ وہ دین کی بات ہی نہیں ہوگی ،
                  لیکن ہم اسے دین کے نام پر پیش کرتے رہیں گے اور مغرب اس کا مذاق
                  اڑا کر ہمارے مذہب کو ہدفِ تنقید بنائے رکھے گا ۔<br />
                  ۴۔ میدان جنگ میں خدعۃ جائز ہے۔لیکن مذہب کی اس جنگ میں دھوکا،
                  خدا کو پسند نہیں ہے۔دونوں گروہوں کو اس بات میں دیانتدار
                  رہناچاہیے کہ حق کو فورا تسلیم کیا جائے ۔جب قرآن و سنت کی مراد
                  اور ان کا کہا ہمارے سامنے روز روشن کی طرح واضح ہو جائے تو
                  ایمان کا تقاضا یہ ہے کہ ہم اس کا اقرار کریں۔ نہ محض اس وجہ سے
                  انکار کریں کہ یہ مولوی کی بات ہے اور نہ محض اس وجہ سے رد کردیں
                  کہ یہ مغرب زدہ آدمی کی رائے ہے۔ حق حق ہوتا ہے۔ خواہ کسی کی
                  زبان سے صادر ہو۔یہ راہ حق کی وفادار ی کا تقاضا ہے کہ اپنی
                  بنائی ہوئی رائے کے خلاف بھی بات ماننی پڑے تو مانی جائے۔
                  <br />
                  میں نے یہ چاروں نکات قرآن کے ایک حکم کی روشنی میں بیان کیے
                  ہیں،۴؂ اللہ کا حکم یہ ہے کہ نیکی اور تقویٰ کے کاموں میں تعاون
                  کیا جائے اور حق تلفی ، گناہ اور ظلم میں تعاون نہ کیا جائے۔
                  میرے خیال میں اس آیت کے حکم کا تقاضا یہ ہے کہ اگر ہمارا دشمن
                  حق پر ہو، اور ہمارا بھائی ناحق پر ،تو بھی ہمیں حق کا ساتھ دینا
                  ہے اور دشمن کے ساتھ کھڑے ہونا پڑے تو بھائی کے خلاف اس کے ساتھ
                  کھڑے ہونا ہوگا۔
                  <br />
                  آخر پر اپنے ساتھیوں سے عرض کرنا چاہتا ہوں کہ ہمارے مسلمان
                  بھائیوں نے جو صورت اب ہمارے لیے پیدا کردی ہے اس میں ہمارے
                  سامنے دین کی خدمت کے تین طرح کے اسوہ حسنہ ہیں:
                  <br />
                  ۱۔ اولوالعزم رسولوں کی طرح حق پر قائم رہتے ہوئے دعوت،
                  <br />
                  ۲۔ پھر انھی رسولوں طر ح مشکلات کی صورت میں ہجرت،
                  <br />
                  ۳۔ اصحاب کہف کی طرح ایمان بچانے کے لیے روپوشی
                  <br />
                  یہ تینوں اسوہ قابل تعریف ہیں ،ان کی اصل یہ ہے کہ حق کا ترک اور
                  دین کی حفاظت و نصرت سے فرار کسی صورت میں نہیں ہونا چاہیے ،اور
                  آگاہ رہنا چاہیے کہ راہِ حق کی روح وفادار ی ہے۔حدیث مبارکہ میں
                  یہ کہا گیا ہے کہ ایمان کی اصل یہ ہے کہ آدمی ہر طرح کے حالات
                  میں رضیت باللہ ربا،اور رضیت بالاسلام دینا پر قائم رہے۔ایسا
                  کرنے والا ہی درحقیقت قرآن کا نفس مطمئنہ ہے۔ جس کے لیے قرآن
                  مجید میں اللہ کی طرف سے بڑی دلنواز پکار ہے کہ:
                  <br />
                  آؤ میرے بندوں میں شامل ہو جاؤ، اور میری جنت میں داخل ہو جاؤ۔۵؂
                </p>

                <p>
                  ۱؂ ’’ہم بھی اللہ ہی کے، اور (ایک دن)ہم بھی اسی کی طرف لوٹ
                  جائیں گے۔‘‘ (البقرۃ ۲: ۱۵۶)
                  <br />
                  ۲؂ یہ میری ایک غزل کے چند اشعار ہیں۔
                  <br />
                  ٌ۳؂ الصف ۶۱: ۱۴۔
                  <br />
                  ۴؂ المائدہ ۵: ۲۔
                  <br />
                  ۵؂ الفجر ۸۹: ۳۰،۲۷۔
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="راہِ وفا پر چلنے والے"
              />

              <div className="content">
                <h2>راہِ وفا پر چلنے والے</h2>
                ماہِ شوال کے آخری ایام میں ، ہمارے بھائی ڈاکٹر محمدفاروق خان
                مرحوم نے جامِ شہادت نوش کیا، اور دارِ فانی سے رخصت ہو گئے۔۱؂
                راہِ حق پر چلنے والوں کو کبھی کبھی یہ جام نوش کرنا پڑتا ہے، اس
                لیے کہ اس دنیا کے اسٹیج پر خیر و شر کی باہم ستیزہ کاری ازل سے
                جاری ہے۔ چراغِ مصطفوی سے شرارِ بولہبی مسلسل برسرپیکار ہے۔ جب بھی
                یہ چراغ کسی نے روشن کیا ہے، لوگوں نے سرتوڑ کوششوں سے اسے بجھانے
                کی کوشش کی ہے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
