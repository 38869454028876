import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Dfkabdizindagi() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/farooq-ki-taveel-awr-abdi-zindagi">
                ’’فاروق‘‘ کی طویل اور ابدی زندگی
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>
                  محمد بلال رسول اللہ صلی اللہ علیہ وسلم نے فرمایا تھاکہ ایک دن
                  یہ دین اجنبی ہوجائے گا۔افسوس ہم پاکستانی اس اجنبیت کا شکار
                  ہوچکے ہیں۔اس سنگین صورت حال میں جب کوئی اللہ کا غیر معمولی
                  بندہ دین پر براہ راست، مسلکی تعصب سے بچتے ہوئے ، قرآن مجید کو
                  کتاب اور فرقان مان کر غورو فکر کرتا ہے اور اپنے نتائج فکر کو
                  معاشرے کے سامنے پیش کرتا ہے تو تنگ نظری کی کال کوٹھری میں رہنے
                  کے عادی اس روشنی کو برداشت نہیں کرپاتے اور اسے ختم کرنے کے
                  درپے ہوجاتے ہیں۔ ڈاکٹر محمد فاروق خان شہید بھی ایسی ہی روشنی
                  کو پھیلانے والوں میں شامل تھے۔انھیں اپنے انجام کا بھی اندازہ
                  تھا، مگر انھوں نے دین کی اجنبیت کو کم کرنے کی ٹھان رکھی تھی۔
                  سیانے کہتے ہیں کہ غصہ دلیل نہ ہونے کی علامت ہے۔ ڈاکٹر فاروق کے
                  مخالفین کے پاس بھی صرف غصہ تھا ۔کون نہیں جانتا کہ غصہ حرام
                  ہوتاہے۔چنانچہ غصے کے اظہار کا انجام وہی ہوا ،ایک حرام کام کا
                  ارتکاب ہوگیا۔مگر اسی واقعہ کا دوسرا پہلو بھی ہے اور وہ یہ کہ
                  ہر طرح کی مخالفت کے بادجودڈاکٹر فاروق ، ’’فاروق‘‘ بنے رہے ،وہ
                  حق وباطل میں فرق کرتے رہے اور قولی اور قلمی شہادت کی راہ پر
                  چلتے چلتے جانی شہادت دینے میں بھی کامیاب ہوگئے۔
                </p>

                <p>
                  ہم ’’فاروق‘‘ کے گھر والوں، دوستوں اور ساتھیوں کے سامنے دنیوی
                  اور انسانی پہلو سے افسوس کرتے ہیں، مگر افسوس سے کئی گنا زیادہ
                  مبارک باد پیش کرتے ہیں کہ ان کے شوہر ،بھائی ،باپ ، دوست اور
                  ساتھی کو شہید کا درجہ ملا۔ موت سے تو ہر شخص نے ہمکنا ر ہونا
                  ہے، مگر ایسی شہادت کی موت بہت کم لوگوں کو نصیب ہوتی ہے۔یہ موت
                  اصل میں زندگی ہے، وہ زندگی جس پر ہم جیسوں کی زندگی رشک کرتی
                  ہے۔ قاتل یہ سمجھتے ہوں گے کہ انھوں نے ’’فاروق‘‘ کو مار دیا
                  ،مگر شاید انھیں معلو م نہیں کہ پہلے اگر’’فاروق‘‘ کو ہزاروں
                  لوگوں نے پڑھنا تھا تو اب کروڑوں لوگ پڑھیں گے اور شہید
                  ’’فاروق‘‘ ذہنوں اور دلوں پر دیر او ردور تک اثر انداز ہوتا رہے
                  گا۔ قاتل اس پر ماتم کریں کہ ’’فاروق‘‘کو دنیا میں طویل ترین اور
                  انتہائی پُر اثر اور آخرت میں اعلیٰ ترین ابدی زندگی عطا ہوچکی
                  ہے:
                </p>

                <blockquote>
                  <p>
                    <b>زندہ ہو جاتے ہیں جو مرتے ہیں حق کے نام پر</b>
                  </p>

                  <p>
                    <b>اللہ اللہ موت کو کس نے مسیحا کر دیا</b>
                  </p>

                  <p>
                    <b>
                      <br />{" "}
                    </b>
                  </p>
                </blockquote>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="’’فاروق‘‘ کی طویل اور ابدی زندگی"
              />

              <div className="content">
                <h2>’’فاروق‘‘ کی طویل اور ابدی زندگی</h2>
                رسول اللہ صلی اللہ علیہ وسلم نے فرمایا تھا کہ ایک دن یہ دین
                اجنبی ہوجائے گا۔افسوس ہم پاکستانی اس اجنبیت کا شکار ہوچکے ہیں۔اس
                سنگین صورت حال میں جب کوئی اللہ کا غیر معمولی بندہ دین پر براہ
                راست، مسلکی تعصب سے بچتے ہوئے ، قرآن مجید کو کتاب اور فرقان مان
                کر غورو فکر کرتا ہے اور اپنے نتائج فکر کو معاشرے کے سامنے پیش
                کرتا ہے تو تنگ نظری کی کال کوٹھری میں رہنے کے عادی اس روشنی کو
                برداشت نہیں کرپاتے اور اسے ختم کرنے کے درپے ہوجاتے ہیں۔ ڈاکٹر
                محمد فاروق خان شہید بھی ایسی ہی روشنی کو پھیلانے والوں میں شامل
                تھے۔انھیں اپنے انجام کا بھی اندازہ تھا، مگر انھوں نے دین کی
                اجنبیت کو کم کرنے کی ٹھان رکھی تھی۔ سیانے کہتے ہیں کہ غصہ دلیل
                نہ ہونے کی علامت ہے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
