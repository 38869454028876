import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Fughaneneemshabibenawaeraznahipart2() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/fughan-e-neem-shabi-be-nawa-e-raz-nahi-part-2">
                فغانِ نیم شبی بے نوائے راز نہیں (حصہ دوم)
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>
                  اس وقت دنیا میں ایک ترقی یافتہ فرد کے مقابلے میں چھ پس ماندہ
                  افراد موجود ہیں۔ موجودہ سرمایہ دارانہ نظام اس طرح سے ترتیب دیا
                  گیا ہے کہ یہ چھ پس ماندہ افراد مسلسل محنت کریں اور اپنے آپ کو
                  پس ماندہ رکھیں؟ تو تب یہ ایک فرد ترقی یافتہ بھی رہے اور معاشی
                  اعتبار سے مطمئن اور خوش حال بھی۔ یہ چھ افراد خام مال بھی پیدا
                  کرتے ہیں اور ترقی یافتہ دنیا کی بنائی ہوئی چیزیں مہنگے داموں
                  خرید کر انہیں استعمال بھی کرتے ہیں اور اپنے دفاع کے لیے بھی،
                  انہیں مغرب ہی پر تکیہ کرنا پڑتا ہے، لہٰذا ایک اعتبار سے یہ سب
                  مغرب کے حلقہ غلامی میں شامل ہیں۔ اب اگر کسی وجہ سے ٹیکنالوجی
                  کی منتقلی عمل میں آئے، اور سائنس اور انڈسٹری پر مغرب کی اجارہ
                  داری ختم ہو، تو مغرب کو ڈر ہے کہ اس کا عظیم معیار زندگی برقرار
                  نہیں رہ سکے گا، لہٰذا وہ چاہتا ہے کہ یہاں ’سٹیٹس کو‘ (Status
                  quo) رہے اور یہ ممالک پس ماندہ ہی رہیں۔ مغرب غیر ترقی یافتہ
                  ممالک کو زیادہ سے زیادہ یہ رعایت دے سکتا ہے کہ وہ زراعت میں
                  تھوڑی سی ترقی کر یں یا کچھ ایسی انڈسٹریز لگا لیں، جن کا مغرب
                  کے لیے لگانا اب مزید سود مند نہیں، تاکہ ’’قوت لایموت‘‘ کا
                  سلسلہ بھی جاری ہو اور کہیں یہ لوگ مکمل طور پر بھوکے ننگے بن کر
                  بغاوت پر نہ اتر آئیں۔
                </p>

                <p>
                  اسی مقصد کو حاصل کرنے کے لیے مغرب نے ایک اور حکمت عملی سے بھی
                  کام لیا ہے۔ وہ یہ کہ مغرب نے تمام پس ماندہ ممالک میں اپنا ایک
                  ممنون احسان مراعات یافتہ طبقہ پیدا کر دیا ہے، جس کی وجہ سے
                  مغرب کو اب یہاں اپنے مفادات پر نظر رکھنے کی بھی ضرورت نہیں،
                  بلکہ یہ مراعات یافتہ طبقہ اس کے تمام مقاصد پورے کر رہا ہے۔ اس
                  مقصد کو حاصل کرنے کے لیے مغرب نے تیسری دنیا کے حکمرانوں کو دل
                  کھول کر قرضے دیے۔
                  <br />
                  یہ قرضے جان بوجھ کر ایسی مدوں کے لیے دیے گئے کہ اس کا سارا
                  فائدہ برسراقتدار طبقے کو پہنچے اور اس کے ذریعے سرمایہ داروں کی
                  ایک کلاس وجود میں آجائے اور عوام کو اس کا فائدہ نہ ہونے کے
                  برابر ہو۔ ان قرضوں کے ساتھ رشوت اور مغربی ممالک کے دوروں کا
                  ایک پروگرام بھی ترتیب دیا گیا، تاکہ یہ طبقہ مغرب سے مسحور و
                  مرعوب ہو کر ہمیشہ اس کے گن گائے، اور ہر معاملے میں رہنمائی کے
                  لیے ان کی طرف دیکھے۔ اس وجہ سے اس نئے طبقے کا معیار زندگی اپنے
                  ہی ملک کے عوام کے مقابلے میں اتنا اونچا ہو گیا کہ اب وہ اس میں
                  کسی کمی کا تصور بھی نہیں کر سکتے اور وہ اس نتیجے تک پہنچے ہیں
                  کہ اب اگر ان کو بحیثیت طبقہ اپنا معیار زندگی برقرار رکھناہے،
                  تو اس کے لیے یہ ضروری ہے کہ وہ اپنے عوام کو ترقی کے ثمرات نہ
                  پہنچنے دیں، ان کو ہمیشہ محروم اور اپنا دست نگر رکھیں۔ چنانچہ
                  مغرب کا ایجنٹ پس ماندہ ممالک کا سربرآوردہ طبقہ اپنے تاریخی فرض
                  کو بہ طریق احسن پورا کر رہا ہے۔ یہی وجہ ہے کہ سرمایہ دارانہ
                  نظام، جس کی کوکھ سے مغرب میں فلاحی مملکتوں کے بہترین نظام نے
                  جنم لیا، ہمارے یہاں فلاحی مملکتوں کی پیدایش ‘Genesis’ کے راستے
                  میں سب سے بڑی رکاوٹ ہے۔
                  <br />
                  مغرب نے اپنے اس جال کو مزید پھیلایا اور اس سربرآوردہ طبقے کو،
                  مستقل طور پر، حکمران رکھنے کے لیے یہاں ایک ایسے انداز جمہوریت
                  کو رواج دیا، جس میں حزب اختلاف اور حزب اقتدار دونوں اطراف سے
                  یہی طبقے سامنے آئیں۔ اپنے لیے تو انہوں نے عام طور پر متناسب
                  نمائندگی کا نظام پسند کیا، مگر یہاں حلقہ بندیوں پر مبنی ایک
                  ایسا نظام بنایا گیا، جس میں الیکشن لڑنے کے اخراجات اتنے زیادہ
                  ہو جاتے ہیں کہ بہت بڑے بڑے سرمایہ داروں اور جاگیرداروں کے
                  علاوہ کوئی فرد اس کا سوچ بھی نہیں سکتا۔ ظاہر ہے کہ یہ افراد جس
                  طرف سے بھی آئیں گے، اپنے ہی طبقے کے مفادات کا تحفظ کریں گے۔
                  بعض اوقات متوسط طبقے سے تعلق رکھنے والے کچھ افراد بھی اسمبلی
                  میں پہنچ جاتے ہیں مگر ان کے پیچھے سرمایہ داروں کی لابیاں کام
                  کرتی ہیں، لہٰذا یہ افراد پارلیمنٹ میں پہنچ کر وہی کچھ کرنے پر
                  مجبور ہوتے ہیں، جو ان کو آگے لانے والی لابیاں چاہتی ہیں۔
                  <br />
                  حلقہ بندیوں کے اس طریق کار سے سارے ملک میں سینکڑوں چھوٹے چھوٹے
                  ’’خدا‘‘ بن جاتے ہیں، جن کے سارے مفادات اس پورے نظام سے وابستہ
                  ہوتے ہیں۔ چنانچہ یہ تمام ’’خدا‘‘ لوگوں کے معمولی معمولی مسائل
                  حل کرتے ہوئے انہیں نظام کی تبدیلی کی بات سے غافل رکھتے ہیں،
                  لہٰذا ڈھانچہ اور نظام تبدیل ہوئے بغیر جمہوریت کا ایک ڈھونگ
                  جاری رہتا ہے اور دو بڑی پارٹیوں میں کسی کے بھی برسراقتدار آنے
                  کی صورت میں ان کے مغربی آقاؤں کو کوئی خطرہ درپیش نہیں ہوتا۔
                  بقول اقبال:
                </p>

                <blockquote>
                  <p>
                    <b>
                      ہے وہی ساز کہن مغرب کا جمہوری نظام
                      <br />
                      جس کے پردوں میں نہیں غیر از نوائے قیصری
                      <br />
                      دیو استبداد جمہوری قبا میں پائے کوب
                      <br />
                      تو سمجھتا ہے یہ آزادی کی ہے نیلم پری
                      <br />
                      مجلس آئین و اصلاح و رعایات و حقوق
                      <br />
                      طب مغرب میں مزے میٹھے، اثر خواب آوری
                      <br />
                      گرمئی گفتار اعضائے مجالس الاماں
                      <br />
                      یہ بھی اک سرمایہ داروں کی ہے جنگ زرگری
                    </b>
                  </p>
                </blockquote>

                <p>
                  چنانچہ مغرب کی اس حکمت عملی کے اعتبار سے پس ماندہ ممالک میں
                  تین گروپ بن جاتے ہیں۔ ایک مستقل حکمرانوں (Born Rulers) کا
                  گروپ، جس میں حزب اقتدار اور حزب اختلاف دونوں سے تعلق رکھنے
                  والے افراد شامل ہوتے ہیں۔ یہ گروپ مغرب کے عالمی مفادات کی بڑی
                  وفاداری سے حفاظت کرتا ہے۔ دوسرا عوام کا گروپ، جواپنی زندگی کے
                  چھوٹے چھوٹے مسائل کے لیے حکمرانوں کا دست نگر رہتا ہے۔ تھانہ،
                  تحصیل، کچہری، ہسپتال، بینک، ہر جگہ اونچے طبقے کی بات چلتی ہے،
                  لہٰذا جب وہ ایک عام فرد کا کوئی چھوٹا سا مسئلہ حل کرتے ہیں، تو
                  وہ ہمیشہ کے لیے ان کے احسان مند بن کر ان کے ووٹ بینک کا کام
                  دیتے ہیں۔ تیسرا طبقہ دانش وروں پر مشتمل ہوتا ہے، اس میں جو لوگ
                  حکمران طبقے کی خرید و فروخت سے بچ جاتے ہیں اور جنہیں اپنے
                  حقیقی کردار کا شعور ہوتا ہے، وہ اخبارات و رسائل کے ذریعے نظام
                  پر تنقید میں لگے رہتے ہیں۔ یہ کام بھی مغرب کے لیے ایک اعتبار
                  سے مفید ہے اس لیے کہ اس مشق کے ذریعے سے نظام کو کوئی نقصان
                  پہنچے بغیر، اس طبقے کے دل کی بھڑاس نکلتی رہتی ہے۔ مغرب میں جس
                  طرح دانش وروں نے عوام کی رہنمائی کی تھی، بدقسمتی سے یہاں بہت
                  سے عوامل کی وجہ سے ایسا اب تک ممکن نہیں ہوا۔
                  <br />
                  مغرب کی اس حکمت عملی کا نتیجہ یہ نکلا ہے کہ آج پس ماندہ اقوام
                  کی حکومتیں، یہاں کی سیاست، یہاں کی معیشت اور یہاں کا دفاع سب
                  کچھ مغرب کے ہاتھوں گروی ہے۔ یہ بھی ضروری نہیں کہ ان تمام طبقات
                  کو مغربی مفادات پورے کرنے کے اپنے کردار کا شعور ہو، بلکہ محض
                  یہ خوف کہ کہیں تبدیلی آکر سب کچھ جلا کر بھسم نہ کر دے اور ان
                  کا معیار زندگی گر نہ جائے، ان کو اس پورے نظام کو برقرار رکھنے
                  پر آمادہ کرتا ہے۔ اس طرح مشرق میں فلاحی جمہوری اور مضبوط
                  مملکتوں کا خواب شرمندۂ تعبیر نہیں ہونے پاتا۔
                  <br />
                  مغرب کے اس دوہرے معیار کی ایک تیسری وجہ بھی ہے، وہ یہ کہ مغرب
                  کے خیال خام میں صرف اسی معاشرے کے اصول ازلی اور ابدی ہیں اورجو
                  معاشرہ ان کے تصور زندگی پر نہیں چلتا، وہ لازماً وحشی اور گنوار
                  ہے۔ مغرب اپنے احساس برتری میں سمجھتا ہے کہ اس کا لباس، اس کی
                  خوراک، اس کا میوزک، اس کا اخلاق اور اس کا پورا طرز زندگی بلا
                  کم و کاست اپنایا جائے اور جو معاشرہ ایسا نہیں کرتا، اسے زندہ
                  رہنے کا کوئی حق نہیں۔ البتہ ایسے معاشروں سے وہ تعرض نہیں کرتا،
                  جو اس کے عالمی مفادات پورے کر رہے ہوں اور جن کے چھیڑنے سے اس
                  کے کچھ اور مقاصد کو نقصان پہنچنے کا اندیشہ ہو۔ چنانچہ اگر
                  مغربی فکر کے بنیادی پتھر، یعنی جمہوریت ہی کے ذریعے سے ایسی
                  ریاست بننے کا اندیشہ ہو، جو مغربی طرز زندگی کے لیے خطرہ ہو، تو
                  ہر ممکن حربے سے کام لے کر اس کا راستہ روکنے کی کوشش کی جاتی
                  ہے، کیونکہ مغرب کے خیال میں ہر وہ راستہ جو اس کا راستہ نہیں
                  ہے، مغرب کے اسی نوے کروڑ لوگوں کے لیے خطرے کا سگنل ہے، لہٰذا
                  باقی ساڑھے چار ارب انسانوں کے لیے وہی راستہ ٹھیک ہے، جس کے
                  ذریعے سے اسی کروڑ افراد کا طرز زندگی محفوظ رہے۔ چنانچہ ایران،
                  الجزائر، انتفاضہ اور خلیج کا قصہ اس وقت تک بار بار دھرایا جاتا
                  رہے گا، جب تک مغربی طرز زندگی کو جمہوریت، امن، فلاح، اور
                  اخلاقی برتری کے اعتبار سے ایک نئی تہذیب چیلنج نہ کرے۔ (اکیسویں
                  صدی اور پاکستان ۸۶۔۹۰)
                </p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="فغانِ نیم شبی بے نوائے راز نہیں (حصہ دوم)"
              />

              <div className="content">
                <h2>فغانِ نیم شبی بے نوائے راز نہیں (حصہ دوم)</h2>
                جب ایک فرد کے لیے حالات ناسازگار ہوں تو دل چھوٹا نہ کیا جائے، اس
                بات پر یقین رکھا جائے کہ پروردگار تمام حالات کو دیکھ رہا ہے اور
                وہ جب چاہے گا حالات بدل دے گا۔ چنانچہ ٹھنڈے دل و دماغ سے کام لیا
                جائے۔ مایوسی اور گھبراہٹ سے بچا جائے۔ حالات کا اچھی طرح جائزہ لے
                کر، تدبیر کے ساتھ، بہترین پر امن حکمت عملی بنا کر اس پر عمل کیا
                جائے۔ اور اس عمل پر ثابت قدم رہا جائے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
