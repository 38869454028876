import React from "react";
import AsideLink from "./AsideLink";
import ProfileImage from "../../../images/article_images/dr-farooq-khan-3.png";

export default function Dfkikitab() {
  return (
    <div>
      <div className="page-banner">
        <div className="container text-center">
          <h1>About</h1>
        </div>
      </div>

      <div className="container white-box">
        <article className="row urdu">
          <div className="col-md-8 pull-right text-right">
            <h1 className="title">
              <a href="/dr-farooq-khan-aur-un-ki-kitab-ikisvin-sadi-aur-pakistan">
                ڈاکٹر فاروق خان اور ان کی کتاب ’’اکیسویں صدی اور پاکستان‘‘
              </a>
            </h1>

            <div className="row">
              <div className="col-md-12 pull-right">
                <p>مجیب الرحمن شامی*</p>

                <blockquote>
                  <p>[جناب مجیب الرحمٰن شامی کا تبصرہ]</p>
                </blockquote>

                <p>
                  ڈاکٹر محمد فاروق خان کا دل بھی مسلمان ہے، اور دماغ بھی۔ وہ ایک
                  منفرد شخصیت کے مالک یوں ہیں کہ پیشے کے لحاظ سے نفسیاتی امراض
                  کے معالج ہیں، اور انتخابی سیاست کے اکھاڑے میں بھی لنگر لنگوٹ
                  کس کر اترے ہیں، اگرچہ کامیابی نے ان کے قدم نہیں چومے، تاہم
                  حوصلہ شکن ناکامی بھی ان کے حصے میں نہیں آئی۔ دینی اور قومی
                  موضوعات پرا ن کا مطالعہ تو وسیع تھا ہی، مشاہدے اور تجربے کی
                  دولت سے بھی مالا مال ہو چکے ہیں۔ قوم کی اجتماعی نفسیات پر ان
                  کی نظر گہری ہے، اور زیرنظر کتاب گواہی دیتی ہے کہ اس کے علاج پر
                  بھی ان کی توجہ سرسری نہیں ہے۔
                  <br />
                  ان کے خیالات سے اختلاف کرنے والے بہت ہوں گے لیکن اگر ہم نے
                  اکیسویں صدی میں ایک مضبوط اور توانا مسلمان معاشرے کے طور پر
                  قدم رکھناہے تو پھر ڈاکٹر صاحب کے نتائج فکر کی طرف متوجہ ہونا
                  ہو گا۔ بے روح مذہبیت سے نجات بھی اسی طرح ضروری ہے جس طرح مادر
                  پدر آزاد مغربیت سے۔ اسلام کو سیکولر مغرب کے آلات کار کا چیلنج
                  تو درپیش ہے ہی، جامد فقہی ذہن بھی اس کے راستے کی بہت بڑی رکاوٹ
                  ہے۔ یہ بات معلوم ہو جانی چاہیے اور عام ہو جانی چاہیے کہ کٹھ
                  ملائیت اور بداخلاق مغربیت دونوں جڑواں بہنیں ہیں۔ مسلمان معاشرے
                  اپنی تاریخی توانائی اور مستقبل کی تعمیرکا سامان اسی طرح ڈھونڈ
                  سکتے ہیں کہ ان دونوں کے خلاف جہاد کریں۔
                  <br />
                  ڈاکٹر فاروق صاحب کی کتاب کا لطف اسے پڑھ کر ہی محسوس کیا جا
                  سکتا ہے۔ انھوں نے معیشت، معاشرت، قانون، آئین، سیاسی نظام،
                  ٹیکسوں کے ڈھانچے، غرض، ہر اس بات پر بحث کی ہے جس کا تعلق ہماری
                  انفرادی اور اجتماعی زندگی سے ہے۔ ان کے ہاں اندھی جذباتیت بھی
                  نہیں ہے، اور وہ بے نور دانش سے بھی دور ہیں۔ وہ اعداد و شمار کے
                  حوالے سے بات کرتے، اور مسائل کی گرہیں کھولتے ہیں۔ یہ بات
                  بلاخوف تردید کہی جا سکتی ہے کہ اس طرح کی جامع کتاب آج تک کسی
                  پاکستانی صاحب علم کو لکھنے کی توفیق نہیں ہوئی۔ اسے ایک ایسی
                  چابی قرار دیا جا سکتا ہے۔ جس سے ہم مستقبل کے بند دروازوں کو
                  کھول سکتے ہیں۔
                  <br />
                  ڈاکٹر صاحب کی ہر بات کو ماننا ضروری نہیں ہے ان کا یہ مطالبہ
                  بھی نہیں ہے۔ لیکن ان کی ہر بات پر غور کرنا ضروری ہے۔ یہ کم سے
                  کم مطالبہ ہے جو ہر اس شخص سے کیا جانا چاہیے جو پاکستان کا درد
                  رکھتا ہے، اور اس کی حالت زار پر کڑھتا ہے۔ اس کے چہرے اور اس کے
                  مستقبل کو بدلنے کی آرزو رکھتا ہے۔
                </p>

                <p>* ایڈیٹر ’’پاکستان‘‘ اخبار۔</p>
              </div>

              <hr />
              <br />
            </div>
          </div>
          <div className="col-md-4 pull-left" style={{ marginTop: "80px" }}>
            <div className="widget">
              <img
                className="img-responsive"
                src={ProfileImage}
                alt="ڈاکٹر فاروق خان اور ان کی کتاب ’’اکیسویں صدی اور پاکستان‘‘"
              />

              <div className="content">
                <h2>
                  ڈاکٹر فاروق خان اور ان کی کتاب ’’اکیسویں صدی اور پاکستان‘‘
                </h2>
                ڈاکٹر محمد فاروق خان کا دل بھی مسلمان ہے، اور دماغ بھی۔ وہ ایک
                منفرد شخصیت کے مالک یوں ہیں کہ پیشے کے لحاظ سے نفسیاتی امراض کے
                معالج ہیں، اور انتخابی سیاست کے اکھاڑے میں بھی لنگر لنگوٹ کس کر
                اترے ہیں، اگرچہ کامیابی نے ان کے قدم نہیں چومے، تاہم حوصلہ شکن
                ناکامی بھی ان کے حصے میں نہیں آئی۔ دینی اور قومی موضوعات پرا ن
                کا مطالعہ تو وسیع تھا ہی، مشاہدے اور تجربے کی دولت سے بھی مالا
                مال ہو چکے ہیں۔ قوم کی اجتماعی نفسیات پر ان کی نظر گہری ہے، اور
                زیرنظر کتاب گواہی دیتی ہے کہ اس کے علاج پر بھی ان کی توجہ سرسری
                نہیں ہے۔
              </div>
            </div>
            <AsideLink />{" "}
          </div>
        </article>
        <hr />
      </div>
    </div>
  );
}
