import React from "react";
import footic from "../images/ui/Drfarooqkhan.jpg";

export default function footer() {
  return (
    <div className="" id="footer">
      <div className="container text-center">
        <img className="img-circle" src={footic} alt="Muhammad Farooq Khan" />
        <h2>DR. MUHAMMAD FAROOQ KHAN</h2>
        <hr />
        <p>
          <small>
            www.drfarooqkhan.org is an affiliate of
            <a href="http://www.al-mawrid.org">Al-Mawrid</a> Network
          </small>
        </p>
      </div>
    </div>
  );
}
